<template>
  <a-table v-if="input.newInstance" :columns="tableColumns" :data-source="tableData" :pagination="false">
    <template #editableCell="cell">
      <FieldWidget
        v-if="fieldsById[cell.fieldId] && fieldIdToIsVisible[cell.fieldId] === true"
        :field="fieldsById[cell.fieldId]"
        v-model="fieldIdToValue[cell.fieldId]"
        :validity="fieldIdToValidity[cell.fieldId]"
        :hideLabel="true"
        :hideDescription="true"
      />
    </template>
    <template #actions>
      <a-button @click="onSubmit" :disabled="!isValidationPassed" type="primary"> Create </a-button>
    </template>
  </a-table>
</template>

<script>
import { FieldWidget } from '@dataSystem/components/FieldWidget';

import { BlueprintFormLogicMixin } from '@dataSystem/mixins/BlueprintLogicMixins';

export default {
  mixins: [BlueprintFormLogicMixin],
  emits: ['submit'],
  data() {
    return {
      input: {
        newInstance: null,
      },
    };
  },
  components: {
    FieldWidget,
  },
  props: ['blueprintId'],
  watch: {
    isFormLogicInitDone() {
      if (this.isFormLogicInitDone) {
        this.initNewInstance();
      }
    },
  },
  computed: {
    tableColumns() {
      if (!this.fieldsById) {
        return [];
      }

      const instanceColumns = Object.values(this.fieldsById).map(field => {
        let title = field.label;
        if (field.isRequired) {
          title = `${field.label} (required)`;
        }
        const columnBase = {
          title,
          dataIndex: field._id,
          key: `create_${field._id}`,
        };
        return {
          ...columnBase,
          scopedSlots: {
            customRender: 'editableCell',
          },
        };
      });

      return [
        ...instanceColumns,
        {
          title: 'Actions',
          key: 'create_actions',
          scopedSlots: { customRender: 'actions' },
          width: '15%',
        },
      ];
    },
    tableData() {
      return [this.input.newInstance];
    },
  },
  methods: {
    initNewInstance() {
      this.input.newInstance = {
        ...Object.fromEntries(Object.values(this.fieldsById).map(field => [field._id, { fieldId: field._id, fieldValue: null }])),
        key: Math.random(),
      };
    },
    onSubmit() {
      this.$emit('submit', this.fieldIdToValue);
      this.initNewInstance();
      this.fieldIdToValue = {};
      this.fieldIdToCalculationDependencies = {};
      this.fieldIdToIsVisible = {};
      this.fieldIdToConditionalDependencies = {};
      this.fieldIdToValidity = {};
      this.init();
    },
  },
};
</script>
