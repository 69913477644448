<template>
  <div>
    <loading-spinner v-if="isLoading" />
    <template v-else>
      <a-card v-if="!invitation || invitation.isRevoked" style="width: 50%; margin: 25px auto; padding: 35px 50px">
        <p>Invitation link is invalid.</p>
        <a-button type="primary" style="display: block; margin-top: 10px" @click="navigateHome"> Go Home </a-button>
      </a-card>
      <a-card v-else-if="invitation.isExpired" style="width: 50%; margin: 25px auto; padding: 35px 50px">
        <p>Invitation link is expired.</p>
        <a-button type="primary" style="display: block; margin-top: 10px" @click="navigateHome"> Go Home </a-button>
      </a-card>
      <a-card v-else-if="invitation.isAccepted" style="width: 50%; margin: 25px auto; padding: 35px 50px">
        <p>Invitation is already accepted.</p>
        <a-button type="primary" style="display: block; margin-top: 10px" @click="navigateHome"> Go Home </a-button>
      </a-card>
      <a-card v-else-if="invitation.isRecipientUser" style="width: 50%; margin: 25px auto; padding: 35px 50px">
        <p>Accepted invitation.</p>
        <a-button type="primary" style="display: block; margin-top: 10px" @click="navigateHome"> Go Home </a-button>
      </a-card>
      <RegisterForm :invitation="invitation" :invitationToken="invitationToken" v-else />
    </template>
  </div>
</template>

<script>
import { invitationApi } from '@userManagement/api';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import RegisterForm from './components/RegisterForm.vue';

export default {
  name: 'RegisterComponent',
  props: ['invitationToken'],
  components: {
    RegisterForm,
  },
  data() {
    return {
      isLoading: false,
      invitation: null,
    };
  },
  created() {
    if (!this.invitationToken) {
      router.push('/');
    }
    this.fetchInvitation();
  },
  methods: {
    async fetchInvitation() {
      this.isLoading = true;
      const invitation = await invitationApi.getInvitation({
        invitationToken: this.invitationToken,
      });
      const currentDate = new Date();
      this.invitation = {
        ...invitation,
        isExpired: currentDate > invitation.expiryDate,
      };
      if (invitation.isRecipientUser) {
        await invitationApi.postInvitationAccept({
          invitationToken: this.invitationToken,
        });
      }
      this.isLoading = false;
    },
    navigateHome() {
      window.location = '/';
    },
  },
};
</script>

<style></style>
