<template>
  <a-drawer title="Edit Profile" width="500px" :open="visible" @close="emitClose">
    <div style="margin-top: 20px">
      <label>First name</label>
      <a-input v-model:value="input.firstName" />
    </div>
    <div style="margin-top: 20px">
      <label>Last name</label>
      <a-input v-model:value="input.lastName" />
    </div>
    <div style="margin-top: 20px">
      <label>Country</label>
      <a-input v-model:value="input.country" />
    </div>
    <div style="margin-top: 20px">
      <label>City</label>
      <a-input v-model:value="input.city" />
    </div>
    <div style="margin-top: 20px">
      <label>Address</label>
      <a-input v-model:value="input.address" />
    </div>
    <div style="margin-top: 20px">
      <label>LinkedIn</label>
      <a-input v-model:value="input.linkedin" />
    </div>
    <div style="margin-top: 20px">
      <label>Facebook</label>
      <a-input v-model:value="input.facebook" />
    </div>
    <div style="margin-top: 30px">
      <a-button @click="onClickSave" type="primary">Save</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { UserGetters, UserMutations } from '@userManagement/user.store';
import { userApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  props: ['visible'],
  emits: ['close'],
  data() {
    return {
      input: {
        firstName: null,
        lastName: null,
        country: null,
        city: null,
        address: null,
        linkedin: null,
        facebook: null,
      },
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.init();
      }
    },
    user() {
      this.init();
    },
  },
  computed: {
    user: UserGetters.getUser,
  },
  methods: {
    init() {
      if (!this.user) {
        return;
      }
      this.input.firstName = this.user.firstName;
      this.input.lastName = this.user.lastName;
      if (!this.user.profile) {
        return;
      }
      this.input.country = this.user.country;
      this.input.city = this.user.city;
      this.input.address = this.user.address;
      this.input.linkedin = this.user.linkedin;
      this.input.facebook = this.user.facebook;
    },
    async updateProfile() {
      if (!this.input.firstName || this.input.firstName.trim() === '') {
        message.warning('First name cannot be empty!');
        return;
      }
      if (!this.input.lastName || this.input.lastName.trim() === '') {
        message.warning('Last name cannot be empty!');
        return;
      }
      const updatedUser = await userApi.patchUserProfile({
        userId: this.user._id,
        ...this.input,
      });
      UserMutations.SET_USER(updatedUser);
      message.success('Saved!');
      this.emitClose();
    },
    emitClose() {
      this.$emit('close');
    },
    async onClickSave() {
      await this.updateProfile();
    },
  },
};
</script>

<style></style>
