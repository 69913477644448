<template>
  <a-card
    style="min-width: 800px; width: 50%; margin: 0 auto; margin-bottom: 15px"
    :class="isExpanded ? 'saas-field-element-card isExpanded' : 'saas-field-element-card'"
  >
    <template #title>
      <span style="width: 97.5%; display: block; cursor: pointer; padding: 16px 0" @click="expandOrCollapseCard">
        {{ fieldWidgetTypeDisplay }}
        <a-tag style="margin-left: 5px"> Field ID: {{ fieldInput?.successionIndex }} </a-tag>
        <a-tag style="margin-left: 5px; text-transform: capitalize">
          {{ fieldInput?.structure?.type }}
        </a-tag>
        <a-tag color="blue" v-if="isDisplayField" style="margin-left: 5px"> Display </a-tag>
      </span>
    </template>
    <template #extra>
      <a-button @click="expandOrCollapseCard" size="small">
        <CaretDownOutlined v-if="!isExpanded" />
        <CaretUpOutlined v-else />
      </a-button>
      <a-dropdown :trigger="['click']" style="margin-left: 5px">
        <a-button size="small">
          <EllipsisOutlined key="ellipsis" />
        </a-button>
        <template #overlay>
          <a-menu @click="onMenuItemClick">
            <a-menu-item v-if="!isDisplayField" key="display">
              <BookOutlined />
              Set as Display Field
            </a-menu-item>
            <a-menu-item key="duplicate">
              <CopyOutlined />
              Duplicate
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <popconfirm-button-delete @confirm-delete="onConfirmDelete" entityName="Field" size="small" buttonStyle="margin-left: 5px;" color="red" />
      <a-button size="small" class="handle" style="margin-left: 5px">
        <DragOutlined />
      </a-button>
    </template>
    <div style="margin-top: 15px">
      <div
        style="
          background-color: #fafafa;
          padding: 15px;
          margin-top: -7.5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          position: relative;
          border-radius: 5px;
        "
      >
        <p
          style="
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-weight: 500;
            font-size: 12px;
            margin-bottom: 0;
            background-color: #fafafa;
            width: 60px;
            position: absolute;
            left: 50%;
            top: -11px;
            transform: translateX(-50%);
            text-align: center;
            border-radius: 3px;
          "
        >
          Preview
        </p>
        <FieldWidget v-if="fieldInput" :field="fieldInput" :fields="fields" />
        <div v-if="(fieldInput?.group ?? '') !== ''" style="margin-top: 10px">Group: {{ fieldInput.group }}</div>
      </div>
    </div>
    <span v-if="!isExpanded" @click="expandOrCollapseCard" class="show-hide-options">
      <CaretDownOutlined />
      Show Options
    </span>
    <div v-if="isExpanded">
      <a-divider style="margin-bottom: 8px" />
      <FieldOptionsIndex :fieldId="fieldId" :blueprint="blueprint" />

      <span @click="expandOrCollapseCard" class="show-hide-options">
        <CaretUpOutlined />
        Hide Options
      </span>
    </div>
  </a-card>
</template>

<script>
import PopconfirmButtonDelete from '@/core/components/PopconfirmButtonDelete.vue';

import { FieldWidget } from '@dataSystem/components/FieldWidget';
import { BookOutlined, CaretDownOutlined, CaretUpOutlined, CopyOutlined, DragOutlined, EllipsisOutlined } from '@ant-design/icons-vue';
import { FieldOptionsIndex } from '@/apps/dataSystem/views/BlueprintFormBuilder/components/FieldOptions';
import { FormBuilderGetters, FormBuilderActions } from '../formBuilder.store';

export default {
  data() {
    return {
      hasInputChanged: false,
    };
  },
  props: ['fieldId', 'isExpanded', 'blueprint'],
  emits: ['expand', 'colapse'],
  components: {
    FieldOptionsIndex,
    FieldWidget,
    PopconfirmButtonDelete,
    BookOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CopyOutlined,
    DragOutlined,
    EllipsisOutlined,
  },
  computed: {
    fields: FormBuilderGetters.getFields,
    isDisplayField() {
      return FormBuilderGetters.getDisplayFieldId() === this.fieldInput?._id;
    },
    fieldInput() {
      return FormBuilderGetters.getFieldInput(this.fieldId);
    },
    fieldWidgetTypeDisplay() {
      if (!this.fieldInput || !this.fieldInput.widget || !this.fieldInput.widget.type) {
        return null;
      }

      const widgetType = this.fieldInput.widget.type;
      if (widgetType === 'simple_text') {
        return 'Static Text';
      }
      return widgetType.replaceAll('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
    },
  },
  methods: {
    expandOrCollapseCard() {
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    expand() {
      this.$emit('expand', this.fieldId);
    },
    collapse() {
      this.$emit('collapse', this.fieldId);
    },
    onConfirmDelete() {
      FormBuilderActions.deleteField(this.fieldId);
    },
    async onMenuItemClick({ key }) {
      if (key === 'display') {
        await FormBuilderActions.updateBlueprint({
          displayFieldId: this.fieldInput._id,
        });
      } else if (key === 'duplicate') {
        console.log('Duplicate field.');
      }
    },
  },
};
</script>

<style scoped>
.isExpanded {
  border: 1px solid #1890ff;
}
.show-hide-options {
  cursor: pointer;
  padding: 16px 0;
  display: block;
  text-align: center;
}
.show-hide-options:hover {
  font-weight: 500;
}
.saas-field-element-card :deep(.ant-card-head-title) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.saas-field-element-card :deep(.ant-card-body) {
  padding-bottom: 0 !important;
}
</style>
