<template>
  <draggable
    style="margin-bottom: 250px"
    :animation="150"
    :scroll-sensitivity="100"
    :scroll-speed="40"
    :force-fallback="true"
    drag-class="dragging-item"
    ghost-class="destination-item"
    v-if="localBlueprint.idListOfFields"
    v-model="localBlueprint.idListOfFields"
    item-key="id"
    group="blueprintFields"
    @start="drag = true"
    @end="
      drag = false;
      updateBlueprintFieldOrder();
    "
    handle=".handle"
  >
    <template #item="{ element }">
      <FieldElement
        class="canBeDragged"
        :isExpanded="fieldIdToIsExpanded[element]"
        :fieldId="element"
        @expand="onExpandField"
        @collapse="onCollapseField"
        :key="element"
        :blueprint="localBlueprint"
      />
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import FieldElement from './FieldElement.vue';
import { FormBuilderActions } from '../formBuilder.store';

export default {
  data() {
    return {
      fieldIdToIsExpanded: {},
      parsedIdListOfFields: null,
      localBlueprint: this.blueprint ?? null,
    };
  },
  props: ['blueprint'],
  mounted() {
    this.localBlueprint = this.blueprint ?? null;
    this.fieldIdToIsExpanded = Object.fromEntries(this.localBlueprint.idListOfFields.map(fieldId => [fieldId, false]));
  },
  components: {
    draggable,
    FieldElement,
  },
  watch: {
    'blueprint.idListOfFields': {
      handler(newValue, oldValue) {
        if (newValue?.idListOfFields?.length !== oldValue?.idListOfFields?.length) {
          this.fieldIdToIsExpanded = Object.fromEntries(this.localBlueprint.idListOfFields.map(fieldId => [fieldId, false]));
          this.updateBlueprintFieldOrder();
        }
      },
      deep: true,
    },
  },
  methods: {
    async updateBlueprintFieldOrder() {
      await FormBuilderActions.updateBlueprint({
        ...this.localBlueprint,
      });
    },
    onExpandField(fieldId) {
      // Iterate over entries and handle fields that are expanded
      Object.entries(this.fieldIdToIsExpanded).forEach(([currentFieldId, isExpanded]) => {
        if (isExpanded) {
          this.onFieldWillCollapse(currentFieldId);
          this.fieldIdToIsExpanded[currentFieldId] = false;
        }
      });

      // Expand the specified field
      this.fieldIdToIsExpanded[fieldId] = true;
    },
    onCollapseField(fieldId) {
      this.onFieldWillCollapse(fieldId);
      this.fieldIdToIsExpanded[fieldId] = false;
    },
    onFieldWillCollapse(fieldId) {
      FormBuilderActions.updateField(fieldId);
    },
  },
};
</script>
