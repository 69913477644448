<template>
  <div style="border-radius: 5px; padding: 5px; margin-bottom: 5px; min-height: 100px" :style="{ border: cellBorder }">
    <p class="mb-1">
      <span v-if="firstDepth" class="mr-3" style="font-weight: bold"> Column {{ arrIndex + 1 }} </span>
      <a-button type="primary" danger size="small" @click="emitRemoveHeaderColumn(arrIndex)"> Remove </a-button>
      <a-button class="ml-1" type="primary" size="small" @click="addCell"> Add cell </a-button>
    </p>
    <div class="mb-4">
      <div v-if="localHeaderColumn.attachedField" style="display: flex; align-items: center" class="mb-1">
        <DeleteOutlined style="cursor: pointer; margin-right: 5px" @click="removeAttachedField" title="Remove field" />
        <span>
          Attached field: <strong>{{ attachedField }}</strong>
        </span>
      </div>

      <a-input
        :class="{ backgroundGreen: dragOver }"
        style="text-align: center"
        v-model:value="localHeaderColumn.displayText"
        placeholder="Cell text"
        @drop="onDrop"
        @dragenter.prevent="dragOver = true"
        @dragleave="dragOver = false"
        @dragover.prevent
      />

      <div v-if="!localHeaderColumn.headerColumns.length && showFirstRow" class="mt-2">
        <label for="" class="mb-0">First row text:</label><br />
        <a-input v-model:value="localHeaderColumn.firstRowData" placeholder="First row text for this column" style="max-width: 300px" />
      </div>
    </div>

    <div class="customHeaderCellContainer">
      <div v-for="(cell, index) in headerColumn.headerColumns" :key="index">
        <TableColumnChildRecursive
          :headerColumn="cell"
          :selectedFields="selectedFields"
          :showFirstRow="showFirstRow"
          :firstDepth="false"
          :arrIndex="index"
          @updateHeaderColumn="updateHeaderColumn(cell, index)"
          @removeColumn="removeHeaderColumn"
        >
        </TableColumnChildRecursive>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';

export default {
  name: 'TableColumnChildRecursive',
  props: ['headerColumn', 'firstDepth', 'arrIndex', 'selectedFields', 'showFirstRow'],
  emits: ['updateHeaderColumn', 'removeColumn'],
  components: {
    DeleteOutlined,
  },
  data() {
    return {
      dragOver: false,
      localHeaderColumn: this.headerColumn ?? null,
    };
  },
  computed: {
    cellBorder() {
      return this.firstDepth ? '1px solid gray' : '1px dashed #cccccc';
    },
    attachedField() {
      return this.selectedFields.find(sf => sf.id === this.headerColumn.attachedField)?.label;
    },
  },
  mounted() {
    this.localHeaderColumn = this.headerColumn ?? null;
  },
  watch: {
    localHeaderColumn: {
      // update stored template.
      deep: true,
      handler(value) {
        this.$emit('updateHeaderColumn', value, this.arrIndex);
      },
    },
  },
  methods: {
    updateHeaderColumn(cell, index) {
      this.localHeaderColumn.headerColumns[index] = cell;
    },
    onDrop(event) {
      this.dragOver = false;
      const action = event.dataTransfer.getData('dragAction');
      const fieldId = event.dataTransfer.getData('selectedFieldId');

      if (action === 'attachFieldHeaderCell' && fieldId) {
        this.localHeaderColumn.attachedField = fieldId;
      }
    },
    removeAttachedField() {
      this.localHeaderColumn.attachedField = null;
    },
    emitRemoveHeaderColumn(index) {
      this.$emit('removeColumn', index);
    },
    addCell() {
      this.localHeaderColumn.headerColumns.push({
        displayText: '',
        attachedField: null,
        headerColumns: [],
        firstRowData: null,
      });
    },
    removeHeaderColumn(index) {
      this.localHeaderColumn.headerColumns.splice(index, 1);
    },
  },
};
</script>

<style>
.customHeaderCellContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > div {
    flex-grow: 1;
    margin: 0 1px;
  }
}
.backgroundGreen {
  background-color: #ceffe6 !important;
}
</style>
