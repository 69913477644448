<template>
  <a-spin v-if="loading" class="text-center d-block p-3" />
  <template v-else>
    <div class="row mb-3">
      <div class="col-12 col-md-1">
        <div class="mb-2">Active *:</div>
        <a-switch :checked="data.active" @change="value => changeSelectValue(value, 'active')" />
      </div>
      <div class="col-12 col-md-7">
        Command *
        <a-tooltip>
          <template #title>
            This command allow to run this event (ex.: Add calendar for year (2024)). Mandatory specify the year (2024) in the instance 'Command
            field', we will extract it automatically.
          </template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-input
          class="mt-2"
          :value="data.commandName ?? ''"
          @change="value => changeSelectValue(value, 'commandName')"
          :placeholder="'Add a command'"
          style="width: 100%"
        >
        </a-input>
      </div>
      <div class="col-12 col-md-4">
        Command field *
        <a-tooltip>
          <template #title>Select command field id</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="mt-2"
          :value="data.commandFieldId ?? undefined"
          @change="value => changeSelectValue(value, 'commandFieldId')"
          show-search
          :placeholder="mainBlueprint?.name + ' fields'"
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="field in mainBlueprintFields" :key="field._id"> {{ field.label }} ({{ field.structure.type }}) </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-3">
        Date field *
        <a-tooltip>
          <template #title>Select the date field id</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="mt-2"
          :value="data.dateFieldId ?? undefined"
          @change="value => changeSelectValue(value, 'dateFieldId')"
          show-search
          :placeholder="mainBlueprint?.name + ' fields'"
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="field in mainBlueprintFields" :key="field._id"> {{ field.label }} ({{ field.structure.type }}) </a-select-option>
        </a-select>
      </div>
      <div class="col-12 col-md-3">
        Weekend field *
        <a-tooltip>
          <template #title>Select the weekend field id</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="mt-2"
          :value="data.weekendFieldId ?? undefined"
          @change="value => changeSelectValue(value, 'weekendFieldId')"
          show-search
          :placeholder="mainBlueprint?.name + ' fields'"
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="field in mainBlueprintFields" :key="field._id"> {{ field.label }} ({{ field.structure.type }}) </a-select-option>
        </a-select>
      </div>
      <div class="col-12 col-md-3">
        Role *
        <a-tooltip>
          <template #title>For what Role will run</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="mt-2"
          :value="data.roleId ?? undefined"
          @change="value => changeSelectValue(value, 'roleId')"
          placeholder="Select..."
          show-search
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="role in roles" :key="role._id" :value="role._id">
            {{ role.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-12 col-md-3">
        Run for tenants
        <a-tooltip>
          <template #title>For what Tenants will run</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="mt-2"
          :value="data.subtenants ?? undefined"
          @change="value => changeSelectValue(value, 'subtenants')"
          placeholder="Select..."
          show-search
          mode="multiple"
          style="width: 100%"
          :filter-option="filterOption"
          :options="allSubtenantsForSelect"
        >
        </a-select>
      </div>
    </div>
  </template>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { blueprintApi } from '@/apps/dataSystem/api';
import { roleApplicationApi } from '@/apps/roleManagement/api';
import { TenantsGetters } from '@/apps/tenants/shared/tenants.store';

export default {
  name: 'workflowBuilderDatesForYearIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex'],
  components: {
    InfoCircleOutlined,
  },
  data() {
    return {
      loading: false,
      roles: [],
      subtenants: [],
      localEvent: { ...this.event },
      allBlueprints: [],
      mainBlueprint: null,
      mainBlueprintFields: [],
      data: {
        active: false,
        commandName: 'Add calendar for year',
        commandFieldId: undefined,
        weekendFieldId: undefined,
        dateFieldId: undefined,
        subtenants: [],
        roleId: undefined,
      },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    allSubtenantsForSelect() {
      return TenantsGetters.getAllSubtenants().map(item => {
        return { label: item.name, value: item._id };
      });
    },
  },
  async created() {
    this.loading = true;
    await this.fetchBlueprint();
    await this.fetchRoles();
    this.loading = false;

    this.data = {
      ...(this.event?.datesForYear ?? {}),
    };
  },
  methods: {
    changeSelectValue(e, keyName) {
      const value = e?.target?.value ?? e;
      this.localEvent.datesForYear[keyName] = value;
      this.data[keyName] = value;
    },
    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      await this.fetchAllBlueprint();

      this.mainBlueprint = blueprint;
      this.mainBlueprintFields = Object.values(fieldsById);
    },
    async fetchAllBlueprint() {
      this.allBlueprints = await blueprintApi.getAllWithFields();
    },
    async fetchRoles() {
      this.roles = await roleApplicationApi.getAllForApplication();
    },
    filterOption(input, option) {
      return option.children()[0].children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
