import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getAppUrlWithGlobal } = SlugGetters;
/**
 * Used for subtenant SMTP test
 * @param data
 * @returns {Promise<{success: boolean, message}|any>}
 */
const postSmtpTest = async data => {
  try {
    const response = await axios.post(`${getAppUrlWithGlobal(false)}/email/smtp/test`, { data });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};
export default {
  postSmtpTest,
};
