<template>
  <div>
    <pre>
      <!-- {{ tableData }} -->
    </pre>
    <a-table :columns="tableColumns" :data-source="tableData" :pagination="tablePagination" rowKey="blueprintId">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'access'">
          <a-checkbox v-model:checked="record.access" @change="e => toggleAccessToService(record, e.target.checked)">Access to service.</a-checkbox>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
  props: ['servicesAccess'],
  data() {
    return {
      tablePagination: {
        pageSize: 20,
      },
      tableColumns: [
        {
          title: 'Service',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Access',
          dataIndex: 'access',
          key: 'access',
        },
      ],
    };
  },
  computed: {
    tableData() {
      return this.servicesAccess ?? [];
    },
  },
  methods: {
    async toggleAccessToService(service, newValue) {
      this.$emit('update', {
        serviceInput: [
          {
            name: service.name,
            access: newValue,
          },
          ...this.servicesAccess.filter(s => s.name !== service.name),
        ],
      });

      message.success('Saved');
    },
  },
};
</script>

<style></style>
