<template>
  <div>
    <div class="details-container">
      <div class="content section" :class="sourceSection === 2 ? 'active' : ''" @click="selectSection">
        <div>
          <CaretDownOutlined v-if="sourceSection === 2" />
          <CaretUpOutlined v-else />
          <strong>2. Response sample object</strong>

          <span v-if="loader">
            <LoadingOutlined class="validate-section" v-if="testPreloader" />
            <span v-if="source.connectionDetail && source.connectionDetail.success">
              <CheckCircleOutlined
                class="validate-section"
                :style="source.connectionDetail && !source.connectionDetail.success ? 'color:#dc2924' : ''"
              />
            </span>
            <span v-else>
              <CloseCircleOutlined
                class="validate-section"
                :style="source.connectionDetail && !source.connectionDetail.success ? 'color:#dc2924' : ''"
              />
            </span>
          </span>
          <span v-else-if="Object.keys(error).length">
            <CloseCircleOutlined class="validate-section" :style="'color:#dc2924'" />
          </span>
        </div>
      </div>

      <template v-if="sourceSection === 2">
        <div class="details-container">
          <div class="content">
            <div class="mb-10">
              <a-button type="primary" :loading="sampleObjPreloader" @click="saveSampleObject"> <SyncOutlined /> Save sample object </a-button>
            </div>

            <a-alert v-if="Object.keys(error).length" :message="error.message" :description="error.catchedMessage" type="error" show-icon />
            <div class="jsonPretty" v-if="Object.keys(error).length && error.data">
              <pre>{{ error.data }}</pre>
            </div>

            <div class="mt-3">
              <Codemirror
                v-if="!sampleObjPreloader && source.connectionDetail && source.connectionDetail.success"
                v-model="expectedReturnObject"
                :options="{
                  btabSize: 4,
                  mode: 'application/json',
                  lineNumbers: true,
                  line: true,
                }"
                :extensions="extensions"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';
import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, LoadingOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons-vue';
import { isValidJson } from '@/core/utils/string-manipulation';
import { notification } from 'ant-design-vue';

export default {
  props: ['testPreloader', 'sourceSection', 'source', 'error'],
  emits: ['ch-section'],
  components: {
    Codemirror,
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
    SyncOutlined,
  },

  data() {
    return {
      loading: false,
      sampleObjPreloader: false,
      expectedReturnObject: null,
      extensions: null,
    };
  },
  filters: {
    jsonPretty: json => {
      const options = {};
      const defaults = { indent: 3, linkUrls: true, quoteKeys: false };
      const settings = { ...defaults, ...options };
      const htmlEntities = text => {
        return text.replace(/&/g, '&amp;').replace(/\\"/g, '&bsol;&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      };
      const buildValueHtml = value => {
        const strType = /^"/.test(value) && 'string';
        const boolType = ['true', 'false'].includes(value) && 'boolean';
        const nullType = value === 'null' && 'null';
        const type = boolType || nullType || strType || 'number';
        const urlRegex = /https?:\/\/[^\s"]+/g;
        const makeLink = link => `<a class=json-link href="${link}">${link}</a>`;
        const display = strType && settings.linkUrls ? value.replace(urlRegex, makeLink) : value;
        return `<span class=json-${type}>${display}</span>`;
      };
      const replacer = (match, p1, p2, p3, p4) => {
        const part = { indent: p1, key: p2, value: p3, end: p4 };
        const findName = settings.quoteKeys ? /(.*)(): / : /"([\w$]+)": |(.*): /;
        const indentHtml = part.indent || '';
        const keyName = part.key && part.key.replace(findName, '$1$2');
        const keyHtml = part.key ? `<span class=json-key>${keyName}</span>: ` : '';
        const valueHtml = part.value ? buildValueHtml(part.value) : '';
        const endHtml = part.end || '';
        return indentHtml + keyHtml + valueHtml + endHtml;
      };
      const jsonLine = /^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([{}[\],]*)?$/gm;
      const newJson = JSON.stringify(json, null, settings.indent);

      return htmlEntities(newJson).replace(jsonLine, replacer);
    },
  },

  watch: {
    'source.connectionDetail': {
      handler(newVal) {
        if (newVal && newVal.objectSample !== undefined) {
          this.expectedReturnObject = JSON.stringify(this.source.connectionDetail.objectSample, null, 2);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async saveSampleObject() {
      this.sampleObjPreloader = true;
      if (isValidJson(this.expectedReturnObject)) {
        await DataProviderActions.updateSourceSampleObject(this.source.providerId, this.source._id, JSON.parse(this.expectedReturnObject));
        // this.$emit("sampleObjUpdated", updatedSource);
      } else {
        notification.warning({
          message: 'Error',
          description: 'The sample object is not valid JSON, please fix the error(s).',
        });
      }
      this.sampleObjPreloader = false;
    },
    selectSection() {
      this.$emit('ch-section', 2);
    },
  },
  computed: {
    loader() {
      if (this.source.connectionDetail) {
        return true;
      }
      return this.testPreloader;
    },
  },
  mounted() {
    const extensions = [javascript(), oneDark];
    this.extensions = extensions;
  },
};
</script>
<style scoped>
.CodeMirror {
  height: 500px !important;
}
.section {
  position: relative;
  cursor: pointer;
}
.section:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.section.active {
  background-color: whitesmoke;
}
.section .validate-section {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #1aa967;
}
</style>
