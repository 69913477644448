import { BlueprintFormBuilder } from './views/BlueprintFormBuilder';
import { BlueprintList } from './views/BlueprintList';
import { SubtenantBlueprintList } from './views/SubtenantBlueprintList';
import { BlueprintPublicForm } from './views/BlueprintPublicForm';

export const dataSystemApplicationRoutes = [
  {
    path: '/blueprint/:blueprintId/form-builder',
    name: 'BlueprintFormBuilder',
    meta: {
      menuTitle: 'Form Builder',
    },
    component: BlueprintFormBuilder,
    props: true,
  },
  {
    path: '/blueprint/list',
    name: 'BlueprintList',
    meta: {
      menuTitle: 'Business Objects',
    },
    component: BlueprintList,
  },
];

export const dataSystemSubtenantRoutes = [
  {
    path: '/collections/:blueprintId?',
    name: 'SubtenantBlueprintList',
    meta: {
      menuTitle: 'Collections',
    },
    component: SubtenantBlueprintList,
    props: true,
  },
];

export const dataSystemRootRoutes = [
  {
    path: '/form/:subtenantId/:linkSharingSlug',
    name: 'BlueprintPublicForm',
    component: BlueprintPublicForm,
    props: true,
    meta: {
      isPublicPage: true,
    },
  },
];
