<template>
  <div class="padding-20">
    <div v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_card hoverable" v-for="fieldMapper in fieldMapperList" :key="fieldMapper._id" style="margin-bottom: 10px">
        <div class="row-contents">
          <router-link
            :to="{
              name: 'FieldMapperBuilder',
              params: { fieldMapperId: fieldMapper._id },
            }"
            custom
            v-slot="{ navigate }"
          >
            <div class="group-text-container" @click="navigate">
              <div class="group-text">
                <span class="title">{{ fieldMapper.name }}</span>
                <span class="muted-text" v-if="fieldMapper.description">
                  {{ fieldMapper.description }}
                </span>
              </div>
            </div>
          </router-link>
          <div class="metadata">
            <div class="controls">
              Fields
              <a-tooltip>
                <a-button style="margin-right: 5px" type="link" @click="openEditSettings(fieldMapper)">
                  <SettingOutlined />
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>

      <a-drawer placement="right" :closable="false" :width="300" :open="filedMapperSettings" @close="closeEditSettings">
        <template #title>
          <a-input v-model:value="editFieldMapperItem.name" placeholder="Field Mapper collection name" />
        </template>
        <p>
          <a-textarea v-model:value="editFieldMapperItem.description" placeholder="Description..." />
        </p>

        <a-button type="primary" :loading="preloaderSaveFieldMapper" @click="saveFieldMapper"> <FileDoneOutlined /> Save </a-button>
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button style="margin-right: 8px" @click="closeEditSettings"> Cancel </a-button>
          <a-popconfirm
            placement="topLeft"
            title="Are you sure delete this workflow group?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="removeFieldMapper(selectedFieldMapperItem)"
          >
            <a-button type="primary" danger :loading="preloaderRemoveFieldMapper"> Delete </a-button>
          </a-popconfirm>
        </div>
      </a-drawer>
    </div>

    <a-button :loading="preloaderAddFieldMapper" @click="addNewFieldMapper"> <PlusOutlined /> Create new Mapper </a-button>
  </div>
</template>

<script>
import { FileDoneOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { FieldMapperActions, FieldMapperGetters } from '@fieldMapper/shared/fieldMapper.store';

export default {
  data() {
    return {
      loading: false,
      preloaderAddFieldMapper: false,
      preloaderSaveFieldMapper: false,
      preloaderRemoveFieldMapper: false,

      filedMapperSettings: false,
      editFieldMapperItem: {
        name: '',
        description: '',
      },
      selectedFieldMapperItem: null,
    };
  },

  async mounted() {
    await FieldMapperActions.fetchFieldMapperList();
  },

  computed: {
    fieldMapperList: FieldMapperGetters.getFieldMapperList,
  },
  methods: {
    async addNewFieldMapper() {
      this.preloaderAddFieldMapper = true;
      await FieldMapperActions.createOneFieldMapper({
        name: 'New mapper',
      });
      this.preloaderAddFieldMapper = false;
    },
    openEditSettings(fieldMapper) {
      this.filedMapperSettings = true;
      this.editFieldMapperItem.name = fieldMapper.name;
      this.editFieldMapperItem.description = fieldMapper.description;
      this.selectedFieldMapperItem = fieldMapper;
    },
    async removeFieldMapper(fieldMapper) {
      this.preloaderRemoveFieldMapper = true;
      await FieldMapperActions.deleteOneFieldMapper(fieldMapper._id);
      this.preloaderRemoveFieldMapper = false;
      this.closeEditSettings();
    },
    async saveFieldMapper() {
      this.preloaderSaveFieldMapper = true;
      await FieldMapperActions.editOneFieldMapper(this.selectedFieldMapperItem._id, {
        name: this.editFieldMapperItem.name,
        description: this.editFieldMapperItem.description,
      });

      this.preloaderSaveFieldMapper = false;
      this.closeEditSettings();
    },
    closeEditSettings() {
      this.filedMapperSettings = false;
    },
  },
  components: {
    FileDoneOutlined,
    PlusOutlined,
    SettingOutlined,
  },
};
</script>
<style scoped>
.padding-20 {
  padding: 20px;
}
</style>
