<template>
  <a-timeline class="actions__timeline">
    <h2 style="margin-bottom: 18px">Roles</h2>
    <a-timeline-item
      v-for="role in roles"
      :key="role._id"
      :color="hoveredRoleId === role._id || selectedRoleId === role._id ? 'blue' : 'gray'"
      :style="hoveredRoleId === role._id || selectedRoleId === role._id ? 'font-weight: 500' : ''"
      @click="() => selectRole(role._id)"
      @mouseover="hoveredRoleId = role._id"
      @mouseleave="hoveredRoleId = null"
    >
      <template #dot>
        <TeamOutlined />
      </template>
      {{ role.name }}
    </a-timeline-item>
  </a-timeline>
</template>

<script>
import { TeamOutlined } from '@ant-design/icons-vue';

export default {
  props: ['roles'],
  emits: ['select'],
  components: {
    TeamOutlined,
  },
  data() {
    return {
      hoveredRoleId: null,
      selectedRoleId: null,
      isAddingNewRole: false,
      newRoleName: '',
      selectedRoleIdToRemove: null,
    };
  },
  methods: {
    selectRole(roleId) {
      this.selectedRoleId = roleId;
      this.$emit('select', this.selectedRoleId);
    },
  },
};
</script>

<style scoped>
.actions__timeline :deep(.ant-timeline-item-content) {
  cursor: pointer;
}
</style>
