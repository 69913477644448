import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

document.addEventListener('updateMessage', async e => {
  await axios.put(`${getSubtenantUrl()}/sms/link`, {
    instanceId: e.detail.instanceId,
    id: localStorage.getItem('nonAllocatedMessageId'),
  });
});
