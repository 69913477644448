export function slugify(str) {
  let string = String(str).trim();
  string = string.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  string = string.toLowerCase(); // convert string to lowercase
  string = string
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '_') // replace spaces with hyphens
    .replace(/-+/g, '_'); // remove consecutive hyphens
  return string;
}

export function isEmpty(str) {
  return !str || str.length === 0;
}
export function extractDateFormat(data) {
  if (!data || typeof data !== 'string') {
    return 'Format invalid';
  }
  const formatPatterns = [
    { regex: /^(\d{2})([./-])(\d{2})\2(\d{4})$/, format: 'DD.MM.YYYY' },
    { regex: /^(\d{4})([./-])(\d{2})\2(\d{2})$/, format: 'YYYY-MM-DD' },
    { regex: /^(\d{2})([./-])(\d{2})\2(\d{4})$/, format: 'MM-DD-YYYY' },
  ];
  const matchedPattern = formatPatterns.find(pattern => pattern.regex.test(data));
  if (matchedPattern) {
    const separator = data.match(matchedPattern.regex)[2];
    return matchedPattern.format.replace(/[./-]/g, separator);
  }
  return 'Format necunoscut';
}

export function isValidJson(str) {
  let isValid;
  try {
    JSON.parse(str);
    isValid = true;
  } catch {
    isValid = false;
  }

  return isValid;
}

export function removeDiacritics(str) {
  return str
    ?.toString()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '');
}
