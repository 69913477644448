<template>
  <a-table
    :columns="tableColumns"
    :data-source="tableDataSource"
    :pagination="pagination"
    @change="onTableChange"
    rowKey="_id"
    class="saas-instance-table"
  >
    <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
      <div style="padding: 8px">
        <a-input
          v-ant-ref="c => (searchInput = c)"
          placeholder="Search"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            e => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }
          "
          @pressEnter="() => onFilterSearch(confirm)"
        />
        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="() => onFilterSearch(confirm)">
          <SearchOutlined /> Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => onFilterReset(clearFilters, column.dataIndex)"> Reset </a-button>
      </div>
    </template>
    <template #referenceField="cell">
      <div v-if="cell" :style="isCellFocused(cell) ? 'border: 1px solid blue' : ''">
        <router-link
          v-if="cell"
          :to="{
            name: 'SubtenantBlueprintList',
            params: { blueprintId: cell.referencedBlueprintId },
          }"
        >
          {{ cell.fieldValue }}
        </router-link>
      </div>
    </template>
    <template #actions="entry">
      <div>
        <a-button @click="() => onClickEditEntry(entry)" :disabled="!canModifyInstanceMap[entry._id]" size="small" style="margin-right: 5px">
          <EditTwoTone two-tone-color="#237804" />
        </a-button>
        <popconfirm-button-delete
          @confirm-delete="onConfirmDeleteEntry(entry)"
          size="small"
          entityName="entry"
          color="red"
          :disabled="!canModifyInstanceMap[entry._id]"
        />
      </div>
    </template>
    <template #editableCell="cell">
      <div
        @click="() => focusCell({ instanceId: cell.instanceId, fieldId: cell.fieldId })"
        :style="isCellFocused(cell) ? 'border: 1px solid blue; height: 100%' : ''"
        v-if="cell"
      >
        <div v-if="isCellInEdit(cell)" class="saas-instance-table-cell">
          <FieldWidget
            :field="fieldsById[cell.fieldId]"
            :value="formLogicByInstance[cell.instanceId].fieldIdToValue[cell.fieldId]"
            :hideLabel="true"
            :hideDescription="true"
            :validity="formLogicByInstance[cell.instanceId].fieldIdToValidity[cell.fieldId]"
            auto-focus
            @blur="event => onEditCellComplete(cell, event)"
            @pressEnter="event => event.target.blur()"
          />
        </div>
        <div v-else @click="() => editCell(cell)" class="saas-instance-table-cell">
          <span v-if="formLogicByInstance[cell.instanceId].fieldIdToIsVisible[cell.fieldId]" style="display: block; width: 100%; cursor: text">
            {{ getCellValue(cell) }}
          </span>
        </div>
      </div>
    </template>
  </a-table>
</template>

<script>
import moment from 'moment';

import { BlueprintTableLogicMixin } from '@dataSystem/mixins/BlueprintLogicMixins';

import PopconfirmButtonDelete from '@/core/components/PopconfirmButtonDelete.vue';
import { FieldWidget } from '@dataSystem/components/FieldWidget';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons-vue';

export default {
  mixins: [BlueprintTableLogicMixin],
  props: ['blueprint', 'fieldsById', 'instanceList', 'instanceListTotalCount', 'canModifyInstanceMap'],
  emits: ['fetchInstanceList', 'editInstance', 'deleteInstance'],
  components: {
    PopconfirmButtonDelete,
    FieldWidget,
    EditTwoTone,
    SearchOutlined,
  },
  data() {
    return {
      pagination: {},
      query: null,
      formLogicByInstance: {},
    };
  },
  created() {
    this.pagination = {
      total: this.instanceListTotalCount || 0,
    };
  },
  watch: {
    instanceListTotalCount() {
      this.pagination = {
        total: this.instanceListTotalCount || 0,
      };
    },
  },
  computed: {
    tableColumns() {
      if (!this.fieldsById) {
        return [];
      }

      const instanceColumns = Object.values(this.fieldsById).map(field => {
        const columnBase = {
          title: field.label,
          dataIndex: field._id,
          key: field._id,
        };
        if (field.structure.type === 'list') {
          return {
            ...columnBase,
            scopedSlots: { customRender: 'listField' },
          };
        }
        if (field.structure.type === 'reference') {
          return {
            ...columnBase,
            dataIndex: `_ref_display_val_${field._id}`,
            scopedSlots: { customRender: 'referenceField' },
          };
        }
        if (field.structure.insertValueMode === 'choices') {
          return {
            ...columnBase,
            filters: field.structure.choices.map(choice => {
              return {
                text: choice.label || choice.value,
                value: choice.value,
              };
            }),
            sorter: true,
            scopedSlots: { customRender: 'editableCell' },
          };
        }
        return {
          ...columnBase,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            customRender: 'editableCell',
          },
          sorter: true,
        };
      });

      const tableColumns = [
        ...instanceColumns,
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: '15%',
        },
      ];

      return tableColumns;
    },
    tableDataSource() {
      if (!this.instanceList) return [];
      return this.instanceList.map(instance => {
        const newInstance = {
          _id: instance._id,
        };

        let cell = {};
        Object.keys(instance).forEach(key => {
          if (key === '_id') {
            return;
          }
          cell = {
            ...cell,
            fieldId: key,
            fieldValue: instance[key],
            instanceId: instance._id,
          };
          if (key.startsWith('_ref_display_val_')) {
            const fieldId = key.split('_ref_display_val_')[1];
            cell = {
              ...cell,
              fieldId,
              referencedBlueprintId: this.fieldsById[fieldId] ? this.fieldsById[fieldId].structure.ruleset.blueprintId : null,
            };
          }
          newInstance[key] = cell;
        });
        return newInstance;
      });
    },
  },
  methods: {
    formatDate(value) {
      if (!value) return '';
      const date = moment(value);
      return date.format('YYYY-MM-DD');
    },
    formatTime(value) {
      if (!value) return '';
      const [hour, minute] = value.split(':');
      const date = moment().hours(hour).minutes(minute);
      return date.format('h:mm a');
    },
    getCellValue(cell) {
      const value = this.formLogicByInstance[cell.instanceId].fieldIdToValue[cell.fieldId];

      const field = this.fieldsById[cell.fieldId];
      if (field.structure.type === 'date') {
        return this.formatDate(value);
      }

      if (field.structure.type === 'time') {
        return this.formatTime(value);
      }

      return value;
    },
    fetchInstanceList() {
      this.$emit('fetchInstanceList', this.query);
    },
    onTableChange(pagination, filters, sorter) {
      this.query = {
        ...this.query,

        page: pagination.current,

        sortFieldId: sorter.field,
        sortOrder: sorter.order,

        ...filters,
      };
      this.fetchInstanceList();
    },
    onClickEditEntry(entry) {
      this.$emit('editInstance', entry._id);
    },
    onConfirmDeleteEntry(entry) {
      this.$emit('deleteInstance', entry._id);
    },
    onFilterSearch(confirm) {
      confirm();
    },
    onFilterReset(clearFilters, dataIndex) {
      this.query = Object.fromEntries(Object.entries(this.query).filter(([key]) => key !== dataIndex));
      this.fetchInstanceList();
      clearFilters();
    },
  },
};
</script>

<style scoped>
.saas-instance-table :deep(.ant-table-tbody) > tr > td {
  padding: 0px !important;
}
.saas-instance-table-cell {
  padding: 18px;
}
</style>
