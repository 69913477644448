<template>
  <div>
    <div v-if="loading" style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0">
      <a-spin style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" />
    </div>
    <div v-else>
      <div class="container padd-20" style="padding-bottom: 70px">
        <div>content: {{ appUrl }}</div>
        <div>blueprint ID: {{ $route.params.blueprintId }}</div>
        <div v-if="$route.params.templateId">template ID: {{ $route.params.templateId }}</div>
        <a-space class="mt-3">
          <a-button @click="goBackToTemplateList"> <StepBackwardOutlined /> Cancel and go back </a-button>
          <a-button type="primary" @click="saveTemplate"> <SaveOutlined /> Save this template </a-button>
          <a-button @click="veziJson">Vezi JSON curent (consola)</a-button>
          <a-button @click="templateSettingsModal = true"> Template settings </a-button>
        </a-space>

        <hr style="margin: 10px 0 20px 0" />
        <div style="margin-bottom: 10px">
          Template name<br />
          <a-input v-model:value="templateName" />
        </div>
        <div style="margin-bottom: 20px">
          Template description<br />
          <a-input v-model:value="templateDescription" />
        </div>

        <div class="mb-3" v-if="existFileExportingData">
          <a-card title="XML schema">
            Name:
            <div class="row">
              <div class="col-4">
                <a-input placeholder="ex.: xs:Invoice, Invoice" v-model:value="fileExportingData.key" />
              </div>
              <div class="col-8">
                <a-button :type="fileExportingData.parameters.length ? 'primary' : ''" @click="xmlSchemaParametersModal = true">
                  <PlusOutlined /> Add parameters
                </a-button>
              </div>
            </div>
          </a-card>
        </div>

        <div v-if="defaultColumnGroups">
          <template v-for="(columnGroup, index) in defaultColumnGroups" :key="index">
            <TemplateRecursion
              :mainTemplate="defaultColumnGroups"
              :template="columnGroup"
              :templates="templates"
              :blueprints="blueprints"
              :mainBlueprintId="mainBlueprintId"
            >
            </TemplateRecursion>
          </template>
        </div>
      </div>

      <a-modal :footer="null" v-model:open="templateSettingsModal" title="Template settings" :width="1200" @cancel="templateSettingsModal = false">
        <a-row>
          <a-col :span="24" style="padding: 3px 5px">
            <a-checkbox v-model:checked="linkSharing.isEnabled" @change="setLinkSharingSlug" :disabled="$route?.params?.templateId ? false : true">
              Public template
            </a-checkbox>
            <strong v-if="needSave" style="color: red"> Save needed</strong>
            <div v-if="linkSharing.isEnabled">Public URL: <strong v-html="linkSharingSlug"></strong></div>
          </a-col>

          <a-col :span="24" style="padding: 3px 5px">
            <a-checkbox v-model:checked="showDownloadPDF">Show download PDF</a-checkbox>
          </a-col>

          <a-col :span="24" style="padding: 3px 5px" v-if="showDownloadPDF">
            <div class="mb-2">
              Static download name
              <help-icon-popover
                title="Static name"
                content="File will be downloaded with this name if there is no opened instanced in the current template OR if the Dynamic name is empty."
              /><br />
              <a-input v-model:value="downloadedPdfSettings.listDownloadName" />
            </div>

            <div>
              Dynamic download name
              <help-icon-popover
                title="Dynamic name"
                content="File will be downloaded with this name if there is an opened instance present. Instance tokens are available for use."
              /><br />
              <a-button @click="tokenModalVisible = true" class="mb-2">Show tokens</a-button>

              <TokensModal
                :blueprint-id="mainBlueprintId"
                :visible="tokenModalVisible"
                :ownerDetailsExist="false"
                :disableDotJs="true"
                @close="tokenModalVisible = false"
              ></TokensModal>

              <a-input v-model:value="downloadedPdfSettings.itemDownloadName" />
            </div>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal v-model:open="xmlSchemaParametersModal" title="XML Schema Parameters" :width="1200" @cancel="xmlSchemaParametersModal = false">
        <parameters v-model:modelValue="fileExportingData.parameters" :allowFieldSelection="false" :card-view="false" />

        <template #footer>
          <a-button @click="xmlSchemaParametersModal = false" type="primary"> OK </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import blueprintApi from '@dataSystem/api/blueprint.api';
import { PlusOutlined, SaveOutlined, StepBackwardOutlined } from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';
import templateManagementApi from '@/apps/templateManagement/templateManagement.api';
import TemplateRecursion from '@/apps/templateManagement/views/Builder/components/TemplateRecursion.vue';
import { generateUUID } from '@/utilities';
import { notification } from 'ant-design-vue';
import parameters from '@/apps/templateManagement/views/Builder/components/fileExporting/components/parameters.vue';
import TokensModal from '@/core/components/TokensModal.vue';

const downloadedPdfSettings = {
  listDownloadName: '',
  itemDownloadName: '',
};

export default {
  name: 'templateManagementBuilder',
  components: {
    TokensModal,
    parameters,
    TemplateRecursion,
    PlusOutlined,
    SaveOutlined,
    StepBackwardOutlined,
  },
  async created() {
    await this.loadBlueprints();
    await this.loadTemplates();

    if (this.$route.params.templateId) {
      await this.loadTemplateForEditing(this.$route.params.templateId);
    }

    this.loading = false;
  },
  watch: {
    defaultColumnGroups: {
      deep: true,
      async handler(value) {
        this.existFileExportingData = await this.existFileExportingDataRecursive(value);
      },
    },
  },
  computed: {
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,
    linkSharingSlug() {
      if (this.linkSharing.slug.indexOf('null') > -1) {
        this.setLinkSharingSlug();
      }
      if (window.location.port === '80' || !window.location.port) {
        return `${window.location.protocol}//${window.location.hostname}/publicTemplate/<span style="color:red">INSERT SUBTENANT SLUG HERE</span>/${this.linkSharing.slug}`;
      }
      return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/publicTemplate/<span style="color:red">INSERT SUBTENANT SLUG HERE</span>/${this.linkSharing.slug}`;
    },
  },
  data() {
    return {
      loading: true,
      blueprints: [],
      templates: null,
      templateName: null,
      templateDescription: null,
      templateSettingsModal: false,
      fileExportingData: {
        type: 'xml',
        key: undefined,
        parameters: [],
      },

      existFileExportingData: false,
      xmlSchemaParametersModal: false,

      mainBlueprintId: null,
      needSave: false,
      linkSharing: {
        isEnabled: false,
        slug: null,
      },
      showDownloadPDF: false,
      downloadedPdfSettings: null,
      tokenModalVisible: false,

      defaultColumnGroups: [
        {
          uid: generateUUID(),
          depth: 1,
          width: 100, // procent
          cssClasses: [],
          inlineStyle: {},
          name: 'Default column',
          object: null,
          tabsView: false,
          tabViewType: 'card',
          defaultBackground: true,
          columnGroups: [],
          visibilityConditions: [],
        },
      ],
    };
  },
  methods: {
    setLinkSharingSlug() {
      if (!this.linkSharing.slug) {
        this.linkSharing.slug = `${this.$route.params.templateId}-${Math.floor(Math.random() * 90000) + 10000}`;
        this.needSave = true;
        return;
      }
      if (this.linkSharing.slug.indexOf('null') > -1) {
        this.linkSharing.slug = this.linkSharing.slug.replace('null', this.$route.params.templateId);
        this.needSave = true;
        return;
      }
      this.needSave = false;
    },
    veziJson() {
      console.log(this.defaultColumnGroups);
    },

    async loadTemplateForEditing(templateId) {
      const template = await templateManagementApi.getOneTemplate(templateId);
      this.defaultColumnGroups = template.columnGroups;
      this.mainBlueprintId = template.blueprintId;
      this.templateName = template.name;
      this.templateDescription = template.description;
      this.showDownloadPDF = template?.showDownloadPDF ?? false;
      this.downloadedPdfSettings = template?.downloadedPdfSettings ?? downloadedPdfSettings;

      if (template?.fileExportingData) {
        this.fileExportingData = template?.fileExportingData;
      }

      if (template.linkSharing) {
        this.linkSharing = template.linkSharing;
      }
    },
    async loadBlueprints() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },
    async loadTemplates() {
      this.templates = await templateManagementApi.getAllTemplates();
    },
    async saveTemplate() {
      this.needSave = false;
      const templateData = {
        blueprintId: this.$route.params.blueprintId,
        columnGroups: this.defaultColumnGroups,
        name: this.templateName,
        fileExportingData: this.fileExportingData,
        description: this.templateDescription,
        linkSharing: this.linkSharing,
        showDownloadPDF: this.showDownloadPDF,
        downloadedPdfSettings: this.downloadedPdfSettings,
      };

      // console.log(templateData);

      if (this.$route.params.templateId) {
        await templateManagementApi
          .updateOneTemplate(this.$route.params.templateId, templateData)
          .then(() => {
            notification.success({
              message: 'Edit template',
              description: 'Template modifications saved.',
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        await templateManagementApi
          .postOneTemplate(templateData)
          .then(() => {
            // console.log(data)
            notification.success({
              message: 'New template',
              description: 'New template saved.',
            });
            this.goBackToTemplateList();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    goBackToTemplateList() {
      this.$router.push({ name: 'TemplateManagementList' });
    },

    existFileExportingDataRecursive(arr) {
      if (!arr || !Array.isArray(arr)) {
        return false;
      }

      let found = false;

      arr.forEach(item => {
        if (found) return; // Early exit if already found

        if (item.object && (item?.object?.fileExportingData?.data ?? []).length) {
          found = true;
        } else if (item.columnGroups && this.existFileExportingDataRecursive(item.columnGroups)) {
          found = true;
        }
      });

      return found;
    },
  },
};
</script>

<style scoped></style>
