<template>
  <div>
    <h2>Blueprint destination</h2>
    <div>
      <label style="min-width: 125px; margin-right: 15px">Select a Blueprint</label>
      <BlueprintSelect v-model="selectedBlueprintId" placeholder="Select a blueprint..." style="width: 500px" />
      <a-button class="ml-3" @click="addNewBlueprint"><PlusOutlined /> Add Blueprint</a-button>
    </div>

    <div v-for="blueprint in blueprints" :key="blueprint._id">
      <BlueprintNode
        v-if="!blueprint.isReferenced"
        :blueprint="blueprint"
        :blueprintFields="getBlueprintFields(blueprint._id)"
        :blueprintsById="blueprintsById"
        :getField="getField"
        @remove-blueprint="removeBlueprintBounds"
        @drop-field="onDropField"
        @remove-field="removeBindField"
        @add-blueprint="addNewBlueprintById"
      />
    </div>

    <div v-if="JSON.stringify(selectedFileMapper) !== '{}'">
      <h2>Embed Code</h2>
      <p>Click on the element below to copy the code and insert it in a template to add a button that triggers file import:</p>
      <a-tooltip :mouseLeaveDelay="0" title="Click to copy">
        <a-tag style="cursor: pointer" @click="copyEmbedCodeToClipboard">
          {{ embedString }}
        </a-tag>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { notification } from 'ant-design-vue';

import { blueprintApi } from '@dataSystem/api';
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';
import { FileMapperActions } from '@fieldMapper/shared/fileMapper.store';
import BlueprintNode from '@/apps/fieldMapper/views/fieldMapperBuilder/components/Destinations/BlueprintNode.vue';

export default {
  props: ['selectedFileMapper'],
  components: {
    BlueprintNode,
    BlueprintSelect,
  },
  data() {
    return {
      loading: false,
      blueprintsById: {},
      destinations: [],
      selectedBlueprintId: null,
    };
  },
  async mounted() {
    this.loadBlueprints();
  },
  watch: {
    selectedFileMapper: {
      deep: true, // Ensures nested changes are also observed
      handler() {
        this.loadBlueprints();
      },
    },
  },
  computed: {
    embedString() {
      return `<InputFile type="primary" fileMapperId="${this.selectedFileMapper._id}" />`;
    },
    blueprints() {
      return Object.values(this.blueprintsById).sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    async loadBlueprints() {
      this.destinations = [];

      if (JSON.stringify(this.selectedFileMapper) !== '{}') {
        const promises = this.selectedFileMapper.fields.flatMap(field =>
          field.boundedFields.map(async bind => {
            const { blueprint, fieldsById } = await blueprintApi.getOne(bind.blueprintId);

            this.blueprintsById[bind.blueprintId] = {
              isReferenced: false,
              ...blueprint,
              fields: Object.values(fieldsById),
            };

            this.destinations.push({
              label: field.name,
              fieldId: field._id,
              ...bind,
            });
          })
        );

        await Promise.all(promises);
      }

      Object.values(this.blueprintsById).forEach(blueprint => {
        const { fields } = blueprint;

        fields.forEach(field => {
          if (field.structure.type === 'reference') {
            const referencedBlueprintId = field.structure.ruleset.blueprintId;
            if (this.blueprintsById[referencedBlueprintId]) {
              this.blueprintsById[referencedBlueprintId].isReferenced = true;
            }
          }
        });
      });
    },
    getBlueprintFields(blueprintId) {
      return this.blueprintsById[blueprintId] ? this.blueprintsById[blueprintId].fields : [];
    },
    async addNewBlueprint() {
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      this.blueprintsById[this.selectedBlueprintId] = {
        isReferenced: false,
        ...blueprint,
        fields: Object.values(fieldsById),
      };

      Object.values(this.blueprintsById).forEach(_blueprint => {
        const { fields } = _blueprint;

        fields.forEach(field => {
          if (field.structure.type === 'reference') {
            const referencedBlueprintId = field.structure.ruleset.blueprintId;
            if (this.blueprintsById[referencedBlueprintId]) {
              this.blueprintsById[referencedBlueprintId].isReferenced = true;
            }
          }
        });
      });
    },
    async addNewBlueprintById(blueprintId) {
      const { blueprint, fieldsById } = await blueprintApi.getOne(blueprintId);

      this.blueprintsById[blueprintId] = {
        isReferenced: true,
        ...blueprint,
        fields: Object.values(fieldsById),
      };
    },
    getField(blueprintId, blueprintFieldId) {
      return this.destinations.find(d => d.blueprintId === blueprintId && d.blueprintFieldId === blueprintFieldId);
    },
    async onDropField(blueprintId, blueprintFieldId, e) {
      notification.success({
        message: 'Saved',
        description: `Field ${e.data.name} is linked.`,
      });

      this.destinations.push({
        label: e.data.name,
        fieldId: e.data._id,
        blueprintId,
        blueprintFieldId,
      });

      await FileMapperActions.createOneBoundedField(this.selectedFileMapper._id, e.data._id, { blueprintId, blueprintFieldId });
    },
    async removeBindField(field) {
      notification.success({
        message: 'Saved',
        description: `Link of field ${field.label} has been removed.`,
      });
      this.destinations = this.destinations.filter(value => value._id !== field._id);
      await FileMapperActions.deleteOneBoundedField(this.selectedFileMapper._id, field.fieldId, field._id);
    },
    async removeBlueprintBounds(blueprintId) {
      notification.success({
        message: 'Saved',
        description: `Blueprint ${this.blueprintsById[blueprintId].name} has been unbounded.`,
      });

      this.destinations = this.destinations.filter(value => value.blueprintId !== blueprintId);
      delete this.blueprintsById[blueprintId];

      await FileMapperActions.deleteBoundsByBlueprintId(this.selectedFileMapper._id, blueprintId);
    },
    copyEmbedCodeToClipboard() {
      navigator.clipboard.writeText(this.embedString);

      notification.success({
        message: 'Saved',
        description: `Embed code copied to clipboard with success.`,
      });
    },
  },
};
</script>
