const TENANT_URL_PART = '/:tenantSlug?';
const APPLICATION_URL_PART = `${TENANT_URL_PART}/:appSlug?`;
const SUBTENANT_URL_PART = `${APPLICATION_URL_PART}/:subtenantSlug?`;

const addPrefix = (prefix, str) => {
  return `${prefix.trim()}/${str.trim()}`;
};

const removeDoubleSlash = str => {
  return str.trim().replace(/\/\/+/g, '/');
};

export const includePrefixedRoutes = (prefix, routes, options) => {
  const prefixedRoutes = routes.map(route => {
    const path = removeDoubleSlash(addPrefix(prefix, route.path));
    let finalPath = null;

    if (options && options.includeTenantPath) {
      finalPath = removeDoubleSlash(`${TENANT_URL_PART}/${path}`);
    }

    if (options && options.includeApplicationPath) {
      finalPath = removeDoubleSlash(`${APPLICATION_URL_PART}/${path}`);
    }

    if (options && options.includeSubtenantPath) {
      finalPath = removeDoubleSlash(`${SUBTENANT_URL_PART}/${path}`);
    }

    return {
      ...route,
      path: finalPath && finalPath.trim().length > 0 ? finalPath : path,
    };
  });
  return prefixedRoutes;
};
