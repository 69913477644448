<template>
  <div>
    <div class="mb-10">
      Referenced blueprint:
      <strong>{{ currentlyReferencedBlueprint }}</strong>
    </div>
    <!--    <label>Select a Blueprint to Reference</label>
    <a-select
      show-search
      placeholder="Choose Blueprint (DISABLED, bugged, TODO)"
      option-filter-prop="blueprint-name"
      style="width: 100%; display: block; margin-top: 5px; margin-bottom: 15px"
      v-model:value="input.blueprintId"
      disabled="disabled"
    >
      <a-select-option
        v-for="blueprint in blueprintList"
        :key="blueprint._id"
        :value="blueprint._id"
        :blueprint-name="blueprint.name"
      >
        {{ blueprint.name }}
      </a-select-option>
    </a-select>-->
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

import { FormBuilderGetters } from '../../../../formBuilder.store';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: ['structure.options'],
    keys: ['blueprintId'],
  },
  data() {
    return {
      blueprintList: [],
    };
  },
  computed: {
    formBlueprint: FormBuilderGetters.getBlueprint,
    currentlyReferencedBlueprint() {
      const isFieldTypeReference =
        this.fieldInput.structure.type === 'reference' || this.fieldInput.structure?.elementStructure?.type === 'reference';
      if (isFieldTypeReference) {
        const referencedBpId =
          this.fieldInput.structure.type === 'reference'
            ? this.fieldInput.structure.ruleset.blueprintId
            : this.fieldInput.structure.elementStructure.ruleset.blueprintId;
        const referencedBp = this.blueprintList.find(bp => bp._id === referencedBpId);
        return `${referencedBp?.name} (ID ${referencedBp?._id})`;
      }
      return 'Not a reference type field!';
    },
  },
  mounted() {
    this.fetchBlueprintList();
  },
  methods: {
    async fetchBlueprintList() {
      const blueprintList = await blueprintApi.getAll();
      this.blueprintList = blueprintList.filter(b => b._id !== this.formBlueprint._id);
    },
  },
};
</script>

<style></style>
