import { DataProviderListBuilder } from './views/dataProviderList';
import { DataProviderBuilder } from './views/dataProviderBuilder';

const davaProviderRoutes = [
  {
    path: '/',
    name: 'DataProviderList',
    meta: {
      menuTitle: 'Dataflows',
    },
    component: DataProviderListBuilder,
  },
  {
    path: '/:dataProviderId/sources',
    name: 'DataProvider',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Sources',
      sectionId: 1,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/sources',
    name: 'DataProviderSources',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Sources',
      sectionId: 1,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/source/:sourceId',
    name: 'DataProviderSource',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Sources',
      sectionId: 1,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/joins',
    name: 'DataProviderJoins',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Output',
      sectionId: 2,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/logs',
    name: 'DataProviderLogs',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Logs',
      sectionId: 3,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/embed',
    name: 'DataProviderEmbed',
    meta: {
      menuTitle: 'Dataflow embed code',
      sectionName: 'Embed code',
      sectionId: 5,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/input',
    name: 'DataProviderInput',
    meta: {
      menuTitle: 'Dataflow input',
      sectionName: 'Input',
      sectionId: 0,
    },
    component: DataProviderBuilder,
  },
  {
    path: '/:dataProviderId/data',
    name: 'DataProviderData',
    meta: {
      menuTitle: 'Dataflow form',
      sectionName: 'Data',
      sectionId: 4,
    },
    component: DataProviderBuilder,
  },
];
export default davaProviderRoutes;
