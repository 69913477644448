<template>
  <a-row style="margin-bottom: 15px" v-if="input.ruleset.useLabel === true">
    <a-col :span="8">
      <label>Label Visibility</label>
      <a-select v-model:value="visibility" default-value="visible" style="width: 200px; display: block">
        <a-select-option value="visible">Visible</a-select-option>
        <a-select-option value="hidden">Hidden</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <label>Label Font weight</label>
      <a-select v-model:value="input.options.labelFontWeight" default-value="normal" style="width: 200px; display: block">
        <a-select-option value="normal">Normal</a-select-option>
        <a-select-option value="bold">Bold</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <label>Label Placement</label>
      <a-select v-model:value="input.options.labelPlacement" default-value="top" style="width: 200px; display: block">
        <a-select-option value="top">Top aligned</a-select-option>
        <a-select-option value="left">Left aligned</a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'widget',
    keys: ['options', 'ruleset'],
  },
  data() {
    return {
      visibility: 'visible',
    };
  },
  mounted() {
    this.visibility = this.input.options.isLabelVisible ? 'visible' : 'hidden';
  },
  watch: {
    visibility() {
      this.input.options.isLabelVisible = this.visibility === 'visible';
    },
  },
};
</script>

<style></style>
