import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl, getAppUrl } = SlugGetters;

const getAllForSubtenant = async () => {
  const response = await axios.get(`${getSubtenantUrl()}/role-management/roles`);
  return response.data;
};

const getAllForUser = async (subtenantSlug = null) => {
  let url = ``;
  if (subtenantSlug) {
    url = `${getAppUrl()}/${subtenantSlug}/role-management/user-roles`;
  } else {
    url = `${getSubtenantUrl()}/role-management/user-roles`;
  }
  const response = await axios.get(url);
  return response.data;
};

const postUsers = async ({ roleId, userIdList }) => {
  const response = await axios.post(`${getSubtenantUrl()}/role-management/roles/${roleId}/users/`, userIdList);
  return response.data;
};

const deleteUser = async ({ roleId, userId }) => {
  const response = await axios.delete(`${getSubtenantUrl()}/role-management/roles/${roleId}/users/${userId}`);
  return response.data;
};

export default {
  getAllForSubtenant,
  getAllForUser,
  postUsers,
  deleteUser,
};
