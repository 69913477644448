<template>
  <div>
    <a-checkbox style="margin: 15px 0" v-model:checked="input.isEnabled">
      Enable Ordering
      <help-icon-popover title="Ordering Logic" content="Instances will be ordered according to the rules" />
    </a-checkbox>

    <div style="padding: 0 27.5px" v-if="input.isEnabled">
      <div v-for="(condition, index) in input.conditions" :key="index" style="margin: 15px 0">
        <span style="margin-right: 15px; font-weight: 500; margin-top: -10px"> {{ index + 1 }}. </span>
        <a-select placeholder="Select field" style="min-width: 200px; margin-right: 15px" v-model:value="condition.fieldId">
          <a-select-option value="_id"> ID </a-select-option>
          <a-select-option value="_created_at"> CREATED AT </a-select-option>

          <a-select-option v-for="field in referenceFields" :key="field._id" :value="field._id">
            (ID: {{ field.successionIndex }}) {{ field.label }}
          </a-select-option>
        </a-select>
        <a-select v-model:value="condition.order" default-value="ASC">
          <a-select-option value="ASC">ASC</a-select-option>
          <a-select-option value="DESC">DESC</a-select-option>
        </a-select>
        <a-tooltip title="Remove condition" style="float: right">
          <a-button @click="onClickRemoveCondition(index)" style="margin-left: 5px"><MinusOutlined /> </a-button>
        </a-tooltip>
      </div>

      <a-button @click="onClickAddCondition" style="margin-top: 10px"> <PlusOutlined /> Add order condition </a-button>
    </div>
  </div>
</template>

<script>
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { blueprintApi } from '@dataSystem/api';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  components: {
    MinusOutlined,
    PlusOutlined,
  },
  fieldInputOptions: {
    path: 'logic.sorting',
    keys: ['isEnabled', 'conditions'],
  },
  data() {
    return {
      referencedBlueprint: null,
      referenceFields: null,
    };
  },
  async mounted() {
    this.referencedBlueprint = await blueprintApi.getAllWithFields([
      this.fieldInput?.structure?.ruleset?.blueprintId ?? // field
        this.fieldInput?.structure?.elementStructure?.ruleset?.blueprintId, // groupField
    ]);
    this.referenceFields = await this.referencedBlueprint[0].fields;
  },
  computed: {},
  methods: {
    onClickAddCondition() {
      this.input.conditions.push({
        fieldId: undefined,
        order: 'ASC',
      });
    },
    onClickRemoveCondition(index) {
      this.input.conditions.splice(index, 1);
    },
  },
};
</script>

<style></style>
