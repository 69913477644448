import _ from 'lodash';

import { FormBuilderActions, FormBuilderGetters, FormBuilderMutations } from '../../formBuilder.store';

const FieldInputOptionsMixin = {
  fieldInputOptions: {
    path: null,
    keys: null,
  },
  data() {
    return {
      input: null,
    };
  },
  props: ['fieldId', 'value', 'acceptance'],
  emits: ['update:value'],
  computed: {
    allFields: FormBuilderGetters.getFields,
    fieldInput() {
      return FormBuilderGetters.getFieldInput(this.fieldId);
    },
  },
  created() {
    if (!this.value) {
      this.input = _.cloneDeep(this.pickFieldInput());
    } else {
      this.input = _.cloneDeep(this.value);
    }
  },
  methods: {
    pickFieldInput() {
      const { path, keys } = this.$options.fieldInputOptions;
      if (path) {
        const objectAtPath = _.get(this.fieldInput, path);

        if (keys) {
          const pickedObject = _.pick(objectAtPath, keys);
          return pickedObject;
        }

        return objectAtPath;
      }
      if (keys) {
        const pickedObject = _.pick(this.fieldInput, keys);
        return pickedObject;
      }

      return this.fieldInput;
    },
    validateInput(input) {
      return { isInputValid: true, validatedInput: input };
    },
    updateFieldInput(updatedInputDeepCopy) {
      FormBuilderActions.updateFieldInput(this.fieldId, this.$options.fieldInputOptions.path, updatedInputDeepCopy);
    },
    updateEntireField(field) {
      FormBuilderMutations.UPDATE_FIELD(field);
    },
  },
  watch: {
    input: {
      deep: true,
      handler() {
        if (this.input == null) {
          return;
        }

        const { isInputValid, validatedInput } = this.validateInput(this.input);
        if (!isInputValid) {
          return;
        }

        if (this.value) {
          this.$emit('update:value', validatedInput);
          return;
        }

        if (!_.isEqual(this.input, this.pickFieldInput())) {
          this.updateFieldInput(_.cloneDeep(validatedInput));
        }
      },
    },
  },
};

export default FieldInputOptionsMixin;
