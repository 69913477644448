<template>
  <div>
    <div class="dataRow">
      <div class="cell w120">
        <a-select v-model:value="updatedValue.method" style="width: 100%">
          <a-select-option value="get">GET</a-select-option>
          <a-select-option value="post">POST</a-select-option>
        </a-select>
      </div>
      <div class="cell pl-15" style="flex-grow: 1">
        <a-input v-model:value="updatedValue.url" placeholder="ex.: https://www.example.com" />
      </div>
      <!--      <div class="cell pl-15 w120">
        <a-select v-model:value="updatedValue.dataType" style="width: 100%">
          <a-select-option value="JSON">JSON</a-select-option>
          <a-select-option value="XML">XML</a-select-option>
        </a-select>
      </div>-->
    </div>

    <!--    <template v-if="updatedValue.dataType === 'XML'">
      <p style="margin-top: 15px">
        How do you want to handle values from recurrent tags?
      </p>
      <a-radio-group
        v-model:value="updatedValue.dataParseType"
        defaultValue="xml_recurrent_cardesian"
      >
        <a-radio
          :style="{ display: 'block', height: '30px', lineHeight: '30px' }"
          value="xml_recurrent_cardesian"
        >
          Create all possible entities using the values of recurrent tags
        </a-radio>
        <a-radio
          :style="{ display: 'block', height: '30px', lineHeight: '30px' }"
          value="xml_recurrent_list"
        >
          Values of recurrent tags should be saved as a list / collection
        </a-radio>
      </a-radio-group>
    </template>-->

    <div class="mt-10 clear">
      <a-button style="float: right" @click="hideOptions()" type="link">
        <CaretDownOutlined v-if="hide" />
        <CaretUpOutlined v-else />
        <span style="padding-right: 5px" v-html="hide ? 'Show' : 'Hide'"></span>
        options
      </a-button>
    </div>

    <div class="saas_card padd-10" v-if="!hide">
      <div style="padding-left: 10px" v-if="selectedDataProvider.dynamicData.active && selectedDataProvider.dynamicData.blueprintId">
        <a-button @click="tokenModalVisible = true" class="mb-2">Show tokens</a-button>

        <TokensModal
          :blueprint-id="selectedDataProvider.dynamicData.blueprintId"
          :visible="tokenModalVisible"
          :ownerDetailsExist="false"
          :disableDotJs="true"
          @close="tokenModalVisible = false"
        ></TokensModal>
      </div>

      <div class="padd-10">
        <a-button-group>
          <a-button :type="tab === 'query' ? 'primary' : ''" @click="tab = 'query'"> URL Params </a-button>
          <a-button :type="tab === 'headers' ? 'primary' : ''" @click="tab = 'headers'"> HTTP Headers </a-button>
          <a-button :type="tab === 'body' ? 'primary' : ''" @click="tab = 'body'"> Request Body </a-button>
        </a-button-group>

        <div class="options-content">
          <template v-if="tab === 'body'">
            <div v-if="updatedValue.bodyType === 'rawStream'" class="mb-3">
              XML file added as raw stream from:<br />
              <div v-if="xmlFromTemplate">
                Template name:
                <strong>{{ xmlFromTemplate.xmlTemplateName }}</strong
                ><br />
                Template id:
                <strong>{{ xmlFromTemplate.xmlTemplateId }}</strong>
              </div>
              <a-button @click="removeRawStreamFile" type="primary" class="mt-2">Remove this file</a-button>
            </div>

            <div v-else>
              <div class="dataRow mb-10" v-for="body in updatedValue.body" :key="body._id">
                <div class="cell">
                  <a-input v-model:value="body.key" placeholder="Key" />
                </div>
                <div class="cell pl-15" style="flex-grow: 1">
                  <a-input v-model:value="body.value" placeholder="Value" />
                </div>
                <div
                  v-if="selectedDataProvider.dynamicData.active && selectedDataProvider.dynamicData.blueprintId"
                  class="cell pl-15"
                  style="flex-grow: 1"
                >
                  <a-tooltip :mouseLeaveDelay="0">
                    <template #title>
                      <span v-pre>Example data (for source testing). Can be left empty if no token is used</span>
                    </template>
                    <a-input v-model:value="body.testData" placeholder="Example data" />
                  </a-tooltip>
                </div>
                <div class="cell w50 pl-15">
                  <a-button @click="remove(body)"><DeleteOutlined /></a-button>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="tab === 'headers'">
            <div class="dataRow mb-10" v-for="header in updatedValue.headers" :key="header._id" :class="{ authToken: !!header.authRestId }">
              <div class="cell">
                <a-input v-model:value="header.key" placeholder="Key" />
              </div>
              <div class="cell pl-15" style="flex-grow: 1">
                <a-tooltip v-if="!!header.authRestId" :mouseLeaveDelay="0">
                  <template #title>
                    <span v-pre>Authentication token, the {{=token}} will be replaced with the token's string</span>
                  </template>
                  <a-input v-model:value="header.value" placeholder="Value" />
                </a-tooltip>

                <a-input v-else v-model:value="header.value" placeholder="Value" />
              </div>
              <div
                v-if="!header.authRestId && selectedDataProvider.dynamicData.active && selectedDataProvider.dynamicData.blueprintId"
                class="cell pl-15"
                style="flex-grow: 1"
              >
                <a-tooltip :mouseLeaveDelay="0">
                  <template #title>
                    <span v-pre>Example data (for source testing). Can be left empty if no token is used</span>
                  </template>
                  <a-input v-model:value="header.testData" placeholder="Example data" />
                </a-tooltip>
              </div>
              <div class="cell w50 pl-15">
                <a-button @click="remove(header)"><DeleteOutlined /></a-button>
              </div>
            </div>
          </template>
          <template v-else-if="tab === 'query'">
            <div class="dataRow mb-10" v-for="query in updatedValue.query" :key="query._id">
              <div class="cell">
                <a-input v-model:value="query.key" placeholder="Key" />
              </div>
              <div class="cell pl-15" style="flex-grow: 1">
                <a-input v-model:value="query.value" placeholder="Value" />
              </div>
              <div
                v-if="selectedDataProvider.dynamicData.active && selectedDataProvider.dynamicData.blueprintId"
                class="cell pl-15"
                style="flex-grow: 1"
              >
                <a-tooltip :mouseLeaveDelay="0">
                  <template #title>
                    <span v-pre>Example data (for source testing). Can be left empty if no token is used</span>
                  </template>
                  <a-input v-model:value="query.testData" placeholder="Example data" />
                </a-tooltip>
              </div>
              <div class="cell w50 pl-15">
                <a-button @click="remove(query)"><DeleteOutlined /></a-button>
              </div>
            </div>
          </template>

          <div class="mt-3">
            <a-button @click="add()"><PlusOutlined /> Add key = value</a-button>
            <a-button v-if="tab === 'headers'" class="ml-2" @click="showAuthTokenModal = true"><PlusOutlined /> Add authentication token</a-button>
            <a-button v-if="tab === 'body' && selectedDataProvider.dynamicData.active" class="ml-2" @click="showFileSelectionModal = true"
              ><PlusOutlined /> Or attach a file as a raw stream</a-button
            >
          </div>
        </div>
      </div>
    </div>

    <a-modal v-model:open="showAuthTokenModal" title="Add authentication token" width="720px" :footer="false">
      <AuthTokens @tokenSelected="addAuthToken" />
    </a-modal>

    <a-modal v-model:open="showFileSelectionModal" title="Attach file to request" width="720px" :footer="false">
      <XmlFromTemplate @fileFromTemplateId="addRawStreamFile" :selectedDataProvider="selectedDataProvider" />
    </a-modal>
  </div>
</template>

<script>
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import TokensModal from '@/core/components/TokensModal.vue';
import AuthTokens from './components/authTokens.vue';
import XmlFromTemplate from './components/xmlFromTemplate.vue';

export default {
  name: 'SubscriptionForm',
  props: ['selectedDataProvider', 'type', 'modelValue'],
  emits: ['update:modelValue'],
  components: {
    TokensModal,
    AuthTokens,
    XmlFromTemplate,
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    PlusOutlined,
  },

  data() {
    return {
      loading: false,
      tab: 'query',
      hide: true,

      updatedValue: {
        method: 'GET',
        url: null,
        dataType: 'JSON',
        dataParseType: null,
        // dataAlwaysNew: true,
        bodyType: 'keyValue', // keyValue, rawStream
        body: [
          // { key: null, value: null }
        ],
        headers: [
          // { key: null, value: null }
        ],
        query: [
          // { key: null, value: null }
        ],
      },

      showAuthTokenModal: false,
      showFileSelectionModal: false,
      tokenModalVisible: false,
    };
  },

  watch: {
    updatedValue: {
      deep: true,
      handler() {
        let body = [];
        let headers = [];
        let query = [];
        if (this.updatedValue.body && this.updatedValue.body.length) {
          body = this.updatedValue.body;
        }
        if (this.updatedValue.headers && this.updatedValue.headers.length && this.updatedValue.headers[0].key) {
          headers = this.updatedValue.headers;
        }
        if (this.updatedValue.query && this.updatedValue.query.length && this.updatedValue.query[0].key) {
          query = this.updatedValue.query;
        }
        this.$emit('update:modelValue', {
          method: this.updatedValue.method,
          url: this.updatedValue.url,
          dataType: this.updatedValue.dataType,
          dataParseType: this.updatedValue.dataParseType,
          bodyType: this.updatedValue.bodyType,
          body,
          headers,
          query,
        });
      },
    },
  },

  computed: {
    xmlFromTemplate() {
      if (this.updatedValue.bodyType === 'rawStream') {
        return this.updatedValue.body.find(el => el.xmlTemplateId !== undefined);
      }
      return null;
    },
  },

  mounted() {
    if (this.modelValue) {
      this.updatedValue = this.modelValue;
    }
  },

  methods: {
    hideOptions() {
      this.hide = !this.hide;
    },
    add() {
      this.updatedValue[this.tab].push({ key: null, value: null });
    },
    addAuthToken(payload) {
      this.updatedValue.headers.push({
        key: payload.key,
        value: '{{=token}}',
        authRestKey: payload.key,
        authRestResponseType: payload.responseType,
        authRestId: payload.authRestId,
      });

      this.showAuthTokenModal = false;
    },
    addRawStreamFile(payload) {
      this.updatedValue.bodyType = 'rawStream';
      this.updatedValue.body = this.updatedValue.body.filter(el => el.xmlTemplateId === undefined);
      this.updatedValue.body.push({
        xmlTemplateId: payload.xmlTemplateId,
        xmlTemplateName: payload.xmlTemplateName,
        testData: payload.xmlExampleData,
      });
      this.showFileSelectionModal = false;
    },
    removeRawStreamFile() {
      this.updatedValue.body = this.updatedValue.body.filter(el => el.xmlTemplateId === undefined);
      this.updatedValue.bodyType = 'keyValue';
    },
    remove(row) {
      const key = this.updatedValue[this.tab].indexOf(row);
      this.updatedValue[this.tab] = this.updatedValue[this.tab].filter(r => this.updatedValue[this.tab].indexOf(r) !== key);
    },
  },
};
</script>
<style scoped>
.authToken input {
  background-color: #ebfff3;
}
.dataRow {
  position: relative;
  display: flex;
  width: 100%;
}
.dataRow .w120 {
  width: 120px;
}
.dataRow .w50 {
  width: 50px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.options-content {
  padding: 15px 10px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 4px;
  margin-top: -5px;
  background-color: #f1f4f5;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.clear::after {
  content: '';
  width: 100%;
  display: block;
  clear: both;
}
</style>
