import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

const getOne = async () => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/webhooks/auth`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export default {
  getOne,
};
