<template>
  <div>
    <h3>Interval</h3>
    <div class="saas_card saas-schedule-card">
      <cron-light v-model="updatedValue.cronExpression" @error="error = $event" />
      <p class="text-lightest pt-2">Cron expression: {{ updatedValue.cronExpression }}</p>
    </div>
  </div>
</template>

<script>
import '@vue-js-cron/light/dist/light.css';
import { CronLight } from '@vue-js-cron/light';

export default {
  name: 'ScheduleComponent',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,

      updatedValue: {
        cronExpression: '0 0 * * *',
        type: 'noEnd',
        error: '',
      },
    };
  },

  watch: {
    updatedValue: {
      deep: true,
      handler() {
        if (!this.updatedValue.cronExpression) {
          return;
        }

        this.$emit('update:modelValue', {
          cronExpression: this.updatedValue.cronExpression,
          type: this.updatedValue.type,
        });
      },
    },
  },
  mounted() {
    if (this.modelValue) {
      this.updatedValue = this.modelValue;
    }
  },

  created() {
    if (!this.modelValue) {
      this.$emit('update:modelValue', {
        cronExpression: this.updatedValue.cronExpression,
        type: this.updatedValue.type,
      });
    }
  },

  methods: {},
  components: { CronLight },
};
</script>
<style scoped>
.dataRow .cell {
  display: table-cell;
}
.dataRow .w120 {
  width: 120px;
}
.dataRow .w50 {
  width: 50px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.saas-schedule-card {
  padding: 10px 20px;
}
</style>
