<template>
  <div>
    <div style="margin: 10px 0px">
      <label>Default value</label>
      <InputEditor
        v-model:value="input.defaultValue"
        @update="
          updatedValue => {
            input.defaultValue = updatedValue;
          }
        "
      ></InputEditor>
    </div>
    <div v-if="field.input.type !== 'simple_text'">
      <div style="margin: 10px 0px">
        <label>Width:</label>
        <a-input-number style="display: block; width: 100%" placeholder="No width set" v-model:value="input.width" />
      </div>
      <div style="margin: 10px 0px">
        <label>Height:</label>
        <a-input-number style="display: block; width: 100%" placeholder="No height set" v-model:value="input.height" />
      </div>
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';
import InputEditor from '../../../../../../components/FieldWidget/Inputs/InputEditor.vue';

export default {
  mixins: [FieldInputOptionsMixin],
  props: ['field'],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['minLength', 'maxLength', 'height', 'width', 'defaultValue'],
  },

  components: {
    InputEditor,
  },
  watch: {
    defaultValue: {
      deep: true,
      handler(value) {
        console.log(value);
      },
    },
  },
  methods: {
    validateInput(input) {
      if (
        ((input.minLength === null || typeof input.minLength === 'number') && (input.maxLength === null || typeof input.maxLength === 'number')) ||
        ((input.width === null || typeof input.width === 'number') && (input.height === null || typeof input.height === 'number'))
      ) {
        return { isInputValid: true, validatedInput: input };
      }
      return { isInputValid: false };
    },
  },
};
</script>

<style></style>
