import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const postOne = async (providerId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/providers/${providerId}/aggregations`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (providerId, aggregationId, data) => {
  try {
    const response = await axios.put(`${getAppUrl()}/providers/${providerId}/aggregations/${aggregationId}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteAll = async providerId => {
  try {
    await axios.delete(`${getAppUrl()}/providers/${providerId}/aggregations`);
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  postOne,
  putOne,
  deleteAll,
};
