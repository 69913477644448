<template>
  <component v-if="typeOptionsComponent" :is="typeOptionsComponent" :fieldId="fieldId" :field="field" />
</template>

<script>
import FileTypeOption from '@dataSystem/views/BlueprintFormBuilder/components/FieldOptions/GeneralOptions/TypeOptions/FileTypeOption.vue';
import StringTypeOptions from './StringTypeOptions.vue';
import ArrayTypeOptions from './ArrayTypeOptions.vue';
import DateTypeOptions from './DateTypeOptions.vue';
import NumberTypeOptions from './NumberTypeOptions.vue';
import JsonTypeOptions from './JsonTypeOptions.vue';
import ReferenceTypeOptions from './ReferenceTypeOptions.vue';
import HtmlTypeOptions from './HtmlTypeOptions.vue';
import { FormBuilderGetters } from '../../../../formBuilder.store';

export default {
  name: 'TypeOptionsIndex',
  props: ['fieldId'],
  computed: {
    field() {
      return FormBuilderGetters.getFieldInput(this.fieldId);
    },
    typeOptionsComponent() {
      if (this.field.structure.type === 'html') {
        return HtmlTypeOptions;
      }
      if (this.field.structure.type === 'string') {
        return StringTypeOptions;
      }
      if (this.field.structure.type === 'array') {
        return ArrayTypeOptions;
      }
      if (this.field.structure.type === 'date') {
        return DateTypeOptions;
      }
      if (this.field.structure.type === 'number') {
        return NumberTypeOptions;
      }
      if (this.field.structure.type === 'json') {
        return JsonTypeOptions;
      }
      if (this.field.structure.type === 'reference') {
        return ReferenceTypeOptions;
      }
      if (this.field.structure.type === 'file') {
        return FileTypeOption;
      }
      return null;
    },
  },
};
</script>

<style></style>
