<template>
  <a-drawer title="Change Email" width="500px" :open="visible" @close="emitClose">
    <div style="margin-top: 20px">
      <label>New E-mail address</label>
      <a-input :disabled="isCodeSent" type="email" />
    </div>
    <div v-if="!isCodeSent" style="margin-top: 30px">
      <a-button @click="onClickSend" type="primary"> Send confirmation code </a-button>
    </div>
    <div v-if="isCodeSent" style="margin-top: 20px">
      <p style="margin-bottom: 10px">A confirmation code has been send to the new e-mail address. Please enter it below.</p>
      <label>Confirmation code:</label>
      <a-input-number style="margin-left: 15px" />
      <a-button type="primary" style="display: block; margin-top: 30px"> Submit </a-button>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: ['visible'],
  emits: ['close'],
  data() {
    return {
      isCodeSent: false,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    onClickSend() {
      this.isCodeSent = true;
    },
  },
};
</script>

<style></style>
