import MenuList from '@/apps/menuBuilder/views/List/MenuList.vue';
import MenuBuilder from '@/apps/menuBuilder/views/Builder/MenuBuilder.vue';

const templateDataSystemRoutes = [
  {
    path: '/',
    name: 'MenuBuilderList',
    component: MenuList,
    meta: {
      menuTitle: 'Menu builder',
    },
  },

  {
    path: '/menu/:menuId',
    name: 'MenuBuilder',
    component: MenuBuilder,
  },
];

export default templateDataSystemRoutes;
