<template>
  <div></div>
</template>

<script>
export default {
  name: 'CoreHomepage',
  mounted() {
    this.$router.push('/tenants');
  },
};
</script>
