import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const getAll = async () => {
  try {
    const response = await axios.get(`${getAppUrl()}/file-mapper/`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOne = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/file-mapper/`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (fileMapperId, data) => {
  try {
    const response = await axios.put(`${getAppUrl()}/file-mapper/${fileMapperId}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async fileMapperId => {
  try {
    await axios.delete(`${getAppUrl()}/file-mapper/${fileMapperId}`);
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOneField = async (fileMapperId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/file-mapper/${fileMapperId}/fields`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOneField = async (fileMapperId, fieldId, data) => {
  try {
    const response = await axios.put(`${getAppUrl()}/file-mapper/${fileMapperId}/fields/${fieldId}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOneField = async (fileMapperId, fieldId) => {
  try {
    const response = await axios.delete(`${getAppUrl()}/file-mapper/${fileMapperId}/fields/${fieldId}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOneBoundedField = async (fileMapperId, fieldId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/file-mapper/${fileMapperId}/fields/${fieldId}/bounded-fields`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOneBoundedField = async (fileMapperId, fieldId, boundedFieldId) => {
  try {
    const response = await axios.delete(`${getAppUrl()}/file-mapper/${fileMapperId}/fields/${fieldId}/bounded-fields/${boundedFieldId}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteBoundsByBlueprintId = async (fileMapperId, blueprintId) => {
  try {
    const response = await axios.delete(`${getAppUrl()}/file-mapper/${fileMapperId}/blueprint/${blueprintId}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getAll,
  postOne,
  putOne,
  deleteOne,

  postOneField,
  putOneField,
  deleteOneField,

  postOneBoundedField,
  deleteOneBoundedField,

  deleteBoundsByBlueprintId,
};
