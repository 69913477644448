<template>
  <div>
    <a-drawer
      v-if="visible && field && sourceId"
      :title="'Filters for `' + field.originalLabel + '`'"
      :open="visible"
      :field="field"
      :body-style="{ paddingBottom: '80px' }"
      width="700px"
      @close="onCloseDrawer"
    >
      <div style="padding-bottom: 5px">Active filters:</div>
      <a-alert
        :show-icon="false"
        v-if="field.filters.length === 0"
        :message="'Currently there are no filters for ' + field.originalLabel"
        banner
      ></a-alert>

      <a-row :gutter="[8, 10]" v-for="(filter, key) in field.filters" :key="filter._id">
        <a-col :span="10">
          <a-input-group compact>
            <span class="ant-input-group-addon" style="width: 20%; height: 32px; line-height: 30px">
              <template v-if="!key">IF</template>
              <template v-else>AND</template>
            </span>

            <a-select
              :value="filter.type == null ? undefined : filter.type"
              @change="filter = selectFilterType(filter, $event)"
              style="width: 80%"
              placeholder="Select condition"
            >
              <a-select-option v-for="(filterString, filterType) in FieldFilterTypes" :key="filterType" :value="filterType">
                <span v-html="filterString" />
              </a-select-option>
            </a-select>
          </a-input-group>
        </a-col>

        <a-col :span="12">
          <a-input :value="filter.expectedValue" @change="filter = changeExpectedValue(filter, $event)" placeholder="Enter the expected value" />
        </a-col>
        <a-col :style="'text-align:right'" :span="2">
          <template v-if="filter.new">
            <a-button @click="removeFilter(filter, key)"><DeleteOutlined /></a-button>
          </template>
          <template v-else>
            <a-popconfirm
              placement="top"
              title="Sre you sure you want to delete this filter?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="removeFilter(filter, key)"
            >
              <a-button :loading="removeFilterProloader === filter._id"><DeleteOutlined /></a-button>
            </a-popconfirm>
          </template>
        </a-col>
      </a-row>

      <a-button @click="addNewFilter" style="margin-top: 15px" type="primary"> <PlusOutlined /> AND </a-button>

      <div class="drawerFotter">
        <a-button style="marginright: 8px" @click="onCloseDrawer"> Cancel </a-button>
        <a-button :loading="addFilterProloader" @click="saveFilter" :disabled="field.filters.length === 0" type="primary"> SAVE FILTERS </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';

const FieldFilterTypes = {
  EQUAL: 'Equal (==)',
  NOT_EQUAL: 'Not equal (&lt;&gt;)',
  GREATER: 'Greater (&gt;)',
  GREATER_OR_EQUAL: 'Greater or equal (&gt;=)',
  LOWER: 'Lower (&lt;)',
  LOWER_OR_EQUAL: 'Lower or equal (&lt;=)',
  EXISTS: 'Exists',
};

export default {
  props: ['visible', 'dataProviderId', 'sourceId', 'fieldId', 'source'],
  components: { DeleteOutlined, PlusOutlined },
  emits: ['close'],
  data() {
    return {
      addFilterProloader: false,
      removeFilterProloader: false,
      createNewFilterButton: false,

      FieldFilterTypes,
      selectedFilterId: null,

      unsavedFilter: {
        type: null,
        expectedValue: null,
      },
    };
  },
  computed: {
    field() {
      return this.source.fields.find(f => f._id === this.fieldId);
    },
  },

  methods: {
    addNewFilter() {
      this.field.filters.push({
        new: true,
        type: null,
        expectedValue: null,
        fieldId: this.fieldId,
      });
    },
    selectFilterType(filter, e) {
      return { ...filter, type: e };
    },
    changeExpectedValue(filter, e) {
      return { ...filter, expectedValue: e.target.value };
    },

    async removeFilter(filter, index) {
      this.removeFilterProloader = filter._id;
      if (filter.new) {
        this.field.filters.splice(index, 1);
      } else {
        await DataProviderActions.deleteOneSourceFieldFilter(this.dataProviderId, this.sourceId, this.fieldId, filter._id);
      }
      this.removeFilterProloader = false;
    },
    async saveFilter() {
      this.addFilterProloader = true;
      const unsavedFilters = this.field.filters.filter(f => f.type != null);
      if (unsavedFilters.length) {
        await DataProviderActions.createUpdateFieldFilter(this.dataProviderId, this.sourceId, this.fieldId, unsavedFilters);
      }
      this.addFilterProloader = false;
    },

    onCloseDrawer() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  display: table-cell;
}
.dataRow .p50 {
  width: 50%;
}
.dataRow .p60 {
  width: 60%;
}
.dataRow .p40 {
  width: 40%;
}
.dataRow .mw300 {
  width: 300px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.drawerFotter {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
