<template>
  <a-drawer :title="`${rankLabel} Management`" width="900px" :open="visible" @close="closeDrawer">
    <InvitationManager
      :visible="InvitationManagerProps.visible"
      :rank="rank"
      :tenantId="tenantId"
      :appId="appId"
      :subtenantId="subtenantId"
      @close="closeInvitationManager"
    />
    <div style="margin-bottom: 15px">
      <a-button @click="openInvitationManager" type="primary"> Invite {{ rankLabel }}(s) </a-button>
      <a-button @click="showCreateUser = true" type="primary" style="margin-left: 10px"> Create {{ rankLabel }} </a-button>
    </div>
    <a-table :columns="tableColumns" :data-source="users">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'actions'">
          <div>
            <a-button @click="() => revokeAccess(record._id)" size="small" v-if="record.active"> Revoke access </a-button>
            <a-button @click="() => grantAcces(record)" size="small" v-if="!record.active" style="width: 102px"> Grant access </a-button>
          </div>
        </template>
      </template>
    </a-table>
    <a-modal
      :footer="false"
      :width="'1200px'"
      :destroyOnClose="true"
      :maskClosable="false"
      v-model:open="showCreateUser"
      @cancel="showCreateUser = false"
      :title="'Create ' + rankLabel.toLowerCase()"
    >
      <CreateUserForm
        :rankLabel="rankLabel"
        :user="user"
        :tenantId="tenantId"
        :appId="appId"
        :subtenantId="subtenantId"
        :emailList="
          users.map(function (item) {
            return item['email'];
          })
        "
        :roleList="roleList"
        @reloadTable="init"
        @cancel="cancel"
      />
    </a-modal>
  </a-drawer>
</template>

<script>
import InvitationManager from '@userManagement/components/InvitationManager/InvitationManager.vue';
import { userApi } from '@userManagement/api';
import { tenantApi, subtenantApi } from '@tenants/api';
import { UserGetters } from '@userManagement/user.store';
import { roleApplicationApi } from '@roleManagement/api';
import CreateUserForm from './components/CreateUserForm.vue';

const tableColumns = [
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    filterMultiple: false,
    sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'First name',
    key: 'firstName',
    dataIndex: 'firstName',
    sorter: (a, b) => a.firstName.length - b.firstName.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Last name',
    key: 'lastName',
    dataIndex: 'lastName',
    sorter: (a, b) => a.lastName.length - b.lastName.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
    sorter: (a, b) => a.role.length - b.role.length,
    sortDirections: ['descend', 'ascend'],
    filters: [],
    filterMultiple: false,
    onFilter: (value, record) => {
      if (record.role === value) {
        return record;
      }
      return null;
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
    filters: [
      { text: 'Active', value: 1 },
      { text: 'Revoked', value: 0 },
    ],
    filterMultiple: false,
    onFilter: (value, record) => {
      if ((record.active & 1) === value) {
        return record;
      }
      return null;
    },
  },
];

export default {
  props: ['visible', 'rank', 'tenantId', 'appId', 'subtenantId'],
  emits: ['close'],
  components: {
    InvitationManager,
    CreateUserForm,
  },
  data() {
    return {
      InvitationManagerProps: {
        visible: false,
      },
      tableColumns,
      users: [],
      rankLabel: '',
      showCreateUser: false,
      roleList: [],
    };
  },
  computed: {
    user: UserGetters.getUser,
  },
  created() {
    this.init();
  },
  watch: {
    rank() {
      this.init();
    },
    visible() {
      if (this.visible) {
        this.init();
      }
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    async fetchRoles() {
      if (this.rank !== 'tenant-admin') {
        this.roleList = await roleApplicationApi.getAllForApplication();
      }
    },
    async init() {
      switch (this.rank) {
        case 'tenant-admin': {
          this.rankLabel = 'Admin';
          break;
        }
        case 'subtenant-manager': {
          this.rankLabel = 'Manager';
          break;
        }
        case 'subtenant-user': {
          this.rankLabel = 'User';
          break;
        }
        default: {
          this.rankLabel = 'User';
          break;
        }
      }
      await this.fetchRoles();
      await this.fetchUsers();
    },
    async fetchUsers() {
      const tableColumnRole = tableColumns.filter(item => item.key === 'role')[0];
      tableColumnRole.filters = [];
      this.roleList.forEach(rol => {
        tableColumnRole.filters.push({ text: rol.name, value: rol.name });
      });
      switch (this.rank) {
        case 'tenant-admin': {
          const usersLists = await userApi.getTenantAdmins(this.tenantId);
          if (usersLists.active) {
            usersLists.active.forEach(usr => {
              usr.key = usr._id;
              usr.active = true;
            });
          }
          if (usersLists.revoked) {
            usersLists.revoked.forEach(usr => {
              usr.key = usr._id;
              usr.active = false;
            });
          }
          if (usersLists.active && usersLists.revoked) {
            this.users = [...usersLists.active, ...usersLists.revoked];
          }
          break;
        }
        case 'subtenant-manager': {
          const usersLists = await userApi.getSubtenantManagers(this.subtenantId);
          (usersLists?.active ?? []).forEach(usr => {
            usr.key = usr._id;
            usr.active = true;
            this.roleList.some(rol => {
              if ((rol.userIdListBySubtenantId[this.subtenantId] ?? []).find(item => item === usr._id.toString())) {
                usr.role = rol.name;
                return true; // Opresc iterația
              }
              return false;
            });
            if (!usr.role) {
              usr.role = '';
            }
          });
          (usersLists?.revoked ?? []).forEach(usr => {
            usr.key = usr._id;
            usr.active = false;
            this.roleList.some(rol => {
              if ((rol.userIdListBySubtenantId[this.subtenantId] ?? []).some(item => item === usr._id.toString())) {
                usr.role = rol.name;
                return true; // Opresc iterarea
              }
              return false;
            });
            if (!usr.role) {
              usr.role = '';
            }
          });
          this.users = [...usersLists.active, ...usersLists.revoked];
          break;
        }
        case 'subtenant-user':
        default: {
          const usersLists = await userApi.getSubtenantUsers(this.subtenantId);
          (usersLists?.active ?? []).forEach(usr => {
            usr.key = usr._id;
            usr.active = true;
            this.roleList.some(rol => {
              if ((rol.userIdListBySubtenantId[this.subtenantId] ?? []).find(item => item === usr._id.toString())) {
                usr.role = rol.name;
                return true; // Aceasta va ieși din `some`, similar cu `break`.
              }
              return false;
            });
            if (!usr.role) {
              usr.role = '';
            }
          });
          (usersLists?.revoked ?? []).forEach(usr => {
            usr.key = usr._id;
            usr.active = false;
            let found = false;
            this.roleList.forEach(rol => {
              if (found) return; // Opresc iterația dacă rolul a fost găsit
              if ((rol.userIdListBySubtenantId[this.subtenantId] ?? []).find(item => item === usr._id.toString())) {
                usr.role = rol.name;
                found = true; // Setez variabila de control pentru a ieși din iterație
              }
            });
            if (!usr.role) {
              usr.role = '';
            }
          });
          this.users = [...(usersLists.active ?? []), ...(usersLists.revoked ?? [])];
          break;
        }
      }
    },
    openInvitationManager() {
      this.InvitationManagerProps = {
        visible: true,
      };
    },
    closeInvitationManager() {
      this.InvitationManagerProps = {
        visible: false,
      };
    },
    async revokeAccess(userIdToRemove) {
      switch (this.rank) {
        case 'tenant-admin': {
          await tenantApi.deleteAdmin(this.tenantId, userIdToRemove);
          break;
        }
        case 'subtenant-manager': {
          await subtenantApi.deleteManager(this.tenantId, this.appId, this.subtenantId, userIdToRemove);
          break;
        }
        case 'subtenant-user':
        default: {
          await subtenantApi.deleteUser(this.tenantId, this.appId, this.subtenantId, userIdToRemove);
          break;
        }
      }
      await this.init();
    },
    cancel() {
      this.showCreateUser = false;
    },
    async grantAcces(row) {
      await userApi.createUser(this.user._id, this.rankLabel, this.tenantId, this.appId, this.subtenantId, {
        ...this.row,
        email: row.email,
      });
      this.init();
    },
  },
};
</script>

<style></style>
