export const serialize = data => {
  return data.map(role => ({
    _id: role._id,
    appId: role.appId,
    name: role.name,
    description: role.description,
    permissionByBlueprintId: role.permissionByBlueprintId,
    servicesAccess: role.servicesAccess,
    userIdList: role.userIdList,
    users: role.users,
  }));
};
