<template>
  <div>
    <a-spin v-if="loading" class="text-center d-block p-3" />
    <template v-else>
      <div class="row">
        <div class="col-4">
          Workflows:
          <a-select
            v-model:value="data.providerId"
            allowClear
            showSearch
            :filter-option="filterOption"
            :options="allProviders"
            style="width: 100%"
            class="mb-3"
            @change="value => changeSelectValue(value, 'providerId')"
          ></a-select>
        </div>
        <div class="col-4">
          Template:
          <a-select
            v-model:value="data.templateId"
            allowClear
            showSearch
            :filter-option="filterOption"
            :options="allTemplates"
            style="width: 100%"
            class="mb-3"
            @change="value => changeSelectValue(value, 'templateId')"
          ></a-select>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import templateManagementApi from '@/apps/templateManagement/templateManagement.api';
import { DataProviderActions, DataProviderGetters } from '@/apps/dataProvider/shared/dataProvider.store';
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';

export default {
  name: 'workflowDataFlows',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  data() {
    return {
      loading: true,
      templates: [],
      data: { templateId: null, providerId: null },
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  async mounted() {
    this.data = {
      ...this.event.dataFlow,
    };
    this.templates = await templateManagementApi.getAllTemplates();
    await DataProviderActions.fetchDataProviderList();
    this.loading = false;
  },
  computed: {
    dataProviderList: DataProviderGetters.getDataProviderList,
    allProviders() {
      const options = [];
      if (this.dataProviderList.length) {
        this.dataProviderList.forEach(provider => {
          options.push({
            value: provider.id,
            label: provider.name + (provider.description && provider.description !== '' ? ` (${provider.description})` : ''),
          });
        });
      }
      return options;
    },
    allTemplates() {
      const options = [];
      if (this.templates.length) {
        this.templates.forEach(template => {
          if (template.blueprintId === this.selectedBlueprintId) {
            options.push({
              value: template._id,
              label: template.name + (template.description && template.description !== '' ? ` (${template.description})` : ''),
            });
          }
        });
      }
      return options;
    },
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  methods: {
    changeSelectValue(value, keyName) {
      this.localEvent.dataFlow[keyName] = value;
      this.data[keyName] = value;
    },
    async updateEvent() {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...this.localEvent });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
