import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getAppUrlWithGlobal } = SlugGetters;
const getAll = async subtenantId => {
  try {
    const response = await axios.get(`${getAppUrlWithGlobal(false)}/apiKeyManagement/apikey/${subtenantId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};
const postOne = async data => {
  try {
    const response = await axios.post(`${getAppUrlWithGlobal(false)}/apiKeyManagement/apikey`, { data });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putOne = async data => {
  try {
    const response = await axios.put(`${getAppUrlWithGlobal(false)}/apiKeyManagement/apikey`, { data });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};
const generateApiKeyCredentials = async (subtenantId, uuid) => {
  try {
    const response = await axios.get(`${getAppUrlWithGlobal(false)}/apiKeyManagement/generateApiKey/${subtenantId}/${uuid}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

export default {
  getAll,
  postOne,
  putOne,
  generateApiKeyCredentials,
};
