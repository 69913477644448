import { reactive } from 'vue';

import { handleNested } from '@/core/utils/array-manipulation';

import { roleApplicationApi } from '../api';
import { serialize } from '../serializers/serializer';

const initialState = {
  roles: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getRoles: () => state.roles,
};

const Mutations = {
  SET_ROLES: roles => {
    state.roles = roles;
  },
  ADD_ROLE: role => {
    state.roles.push(role);
  },
  UPDATE_ROLE: (roleId, updatedRole) => {
    const stateRoleList = handleNested(state.roles, { _id: roleId }, { update: updatedRole });

    state.roles = stateRoleList;
  },
  REMOVE_ROLE: roleId => {
    const stateRoleList = handleNested(state.roles, {
      _id: roleId,
    });

    state.roles = stateRoleList;
  },
};

const Actions = {
  fetchRoles: async () => {
    const roles = await roleApplicationApi.getAllForApplication();
    Mutations.SET_ROLES(serialize(roles));
  },
  createRole: async ({ roleInput }) => {
    const newRole = await roleApplicationApi.postOne({ roleInput });
    Mutations.ADD_ROLE(newRole);
  },
  updateRole: async ({ roleId, roleInput }) => {
    const updatedRole = await roleApplicationApi.patchOne({ roleId, roleInput });
    Mutations.UPDATE_ROLE(roleId, updatedRole);
  },
  updateRolePermission: async ({ roleId, blueprintId, permissionInput }) => {
    const updatedRole = await roleApplicationApi.patchPermission({ roleId, blueprintId, permissionInput });
    Mutations.UPDATE_ROLE(roleId, updatedRole);
  },
  updateRoleServiceAccess: async ({ roleId, serviceInput }) => {
    const updatedRole = await roleApplicationApi.patchServiceAccess({ roleId, serviceInput });
    Mutations.UPDATE_ROLE(roleId, updatedRole);
  },
  deleteRole: async ({ roleId }) => {
    await roleApplicationApi.deleteOne({ roleId });
    Mutations.REMOVE_ROLE(roleId);
  },
};

export const RolesGetters = Getters;
export const RolesMutations = Mutations;
export const RolesActions = Actions;
