<template>
  <div class="AuthLogin_Container">
    <loading-spinner v-if="isLoading" />
    <a-card v-else>
      <h1>Login</h1>

      <label class="AuthLogin_Label" for="username">Email</label>
      <a-input v-model:value="input.email" id="email" type="email" data-cy="inputEmail" />

      <div v-if="emailError">
        <p style="font-weight: 500; color: #a8071a; margin-top: 5px; margin-bottom: 0">
          {{ emailError }}
        </p>
      </div>

      <label class="AuthLogin_Label" for="password">Password</label>
      <a-input @pressEnter="onClickLogin" v-model:value="input.password" id="password" type="password" data-cy="inputPassword" />

      <div v-if="passwordError">
        <p style="font-weight: 500; color: #a8071a; margin-top: 5px; margin-bottom: 0">
          {{ passwordError }}
        </p>
      </div>

      <router-link style="display: block" :to="{ name: 'PasswordReset', params: { email: input.email } }"> Forgot password ? </router-link>

      <div v-if="authError">
        <p style="font-weight: 500; color: #a8071a; margin-top: 10px; margin-bottom: 0">Login failed.</p>
      </div>

      <a-button class="AuthLogin_LoginButton" type="primary" @click="onClickLogin" data-cy="buttonLogin"> <LoginOutlined /> Login </a-button>
    </a-card>
  </div>
</template>

<script>
import { UserGetters, UserActions } from '@userManagement/user.store';
import { LoginOutlined } from '@ant-design/icons-vue';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default {
  name: 'LoginComponent',
  components: {
    LoginOutlined,
  },
  props: ['email'],
  data() {
    return {
      isLoading: false,
      authError: false,
      emailError: null,
      passwordError: null,
      input: {
        email: null,
        password: null,
      },
    };
  },
  async created() {
    if (this.email) {
      this.input.email = this.email;
    }
    this.isLoading = true;
    let successfulLogin = false;
    try {
      await UserActions.silentLogin();

      successfulLogin = true;
    } catch (error) {
      successfulLogin = false;
    }
    if (successfulLogin && UserGetters.getUser()) {
      router.push('/tenants');
    }
    this.isLoading = false;
  },
  methods: {
    async onClickLogin() {
      if (!this.input.email || this.input.email.trim() === '') {
        this.emailError = 'E-mail is required.';
        return;
      }
      if (!this.input.password || this.input.password.trim() === '') {
        this.passwordError = 'Password is required.';
        return;
      }
      if (!this.input.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        this.emailError = 'Invalid e-mail address.';
        return;
      }
      this.isLoading = true;
      try {
        const defaultUrl = await UserActions.login(this.input.email, this.input.password);
        if (!defaultUrl && defaultUrl === '') {
          await router.push('/');
        } else {
          await router.push(defaultUrl);
        }
      } catch {
        this.authError = true;
      }
      this.isLoading = false;
    },
  },
  watch: {
    input: {
      deep: true,
      handler() {
        this.emailError = null;
        this.passwordError = null;
        this.authError = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.AuthLogin {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 100vh;
  }
  &_Label {
    display: block;
    margin-top: 20px;
  }
  &_LoginButton {
    margin-top: 25px;
    width: 150px;
  }
}
</style>
