<template>
  <div style="margin-top: 20px; margin-bottom: 10px">
    <label>Default country code</label>
    <a-select style="width: 100%" show-search @change="onChange">
      <a-select-option v-for="code in countryCodes" :key="code.code">
        {{ code.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import countryCodes from '@/core/constants/countryCodes.constant';

export default {
  emits: ['update:modelValue'],
  data() {
    return {
      countryCodes,
      input: {
        countryCode: null,
      },
    };
  },
  methods: {
    onChange(selectedValue) {
      if (selectedValue) {
        this.$emit('update:modelValue', {
          countryCode: selectedValue,
        });
      } else {
        this.$emit('update:modelValue', null);
      }
    },
  },
};
</script>
