<template>
  <div class="AuthLogin_Container">
    <loading-spinner v-if="isLoading" />
    <a-card>
      <!-- Success/Error message -->
      <div v-if="message && !this.error" class="message" :class="{ error: error, success: !error }">
        <h2>{{ message }}</h2>
      </div>
      <template v-else>
        <h1>Unsubscribe from Emails</h1>
        <p>If you wish to stop receiving emails, please enter your email below to unsubscribe.</p>

        <!-- Email input form -->
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="email">Email Address:</label>
            <input type="email" id="email" v-model="email" @input="validateEmail" required placeholder="Enter your email" />
            <!-- Error message when email is invalid -->
            <span v-if="email && !isValidEmail" class="error-message">Please enter a valid email address.</span>
          </div>

          <button type="submit" :disabled="!isValidEmail || loading">
            {{ loading ? 'Unsubscribing...' : 'Unsubscribe' }}
          </button>
          <h3 v-if="this.error">{{ message }}</h3>
        </form>
      </template>
    </a-card>
  </div>
</template>

<script>
import { unsubscribeApi } from '@userManagement/api';

export default {
  name: 'UnsubscribeEmail',
  data() {
    return {
      email: '', // Model for the email input
      isValidEmail: false, // Boolean to check if the email is valid
      loading: false, // To show loading state
      message: '', // To display success/error message
      error: false, // To track if there's an error
      token: null,
      isLoading: true,
    };
  },
  created() {
    this.isLoading = true;
  },
  async mounted() {
    const { token, email } = this.$route.params;
    this.token = token;
    this.email = email;
    if (this.email) {
      this.validateEmail();
      await this.handleSubmit();
    }
    this.isLoading = false;
  },
  methods: {
    // Validate the email with regex
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
      this.isValidEmail = emailRegex.test(this.email);
    },

    async handleSubmit() {
      if (!this.email || !this.isValidEmail) return;

      this.loading = true;
      this.message = ''; // Reset message
      this.error = false;

      try {
        const response = await unsubscribeApi.unsubscribe({ token: this.token, email: this.email });
        // Handle success
        if (response.success) {
          this.message = response?.message;
        } else {
          this.message = response?.message;
          this.error = true;
        }
      } catch (error) {
        this.message = 'An error occurred. Please try again.';
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.AuthLogin {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 150vh;
  }
}
.form-group {
  margin-bottom: 1.5rem;
}

input[type='email'] {
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.75rem 1.5rem;
  background-color: #ff4c4c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.message {
  margin-top: 1rem;
  font-weight: bold;
}

.message.error {
  color: red;
}

.message.success {
  color: green;
}
</style>
