<template>
  <div class="mt-3 ml-3">
    <p style="font-weight: bold; margin-bottom: 5px">{{ authRest.name }}</p>

    <div v-if="authRest.response && authRest.response?.data">
      <div class="saas_card padd-5 mb-1" v-for="(value, key) in authRest.response.data" :key="key">
        <a-button type="primary" size="small" @click="emitSelectedAuthToken(authRest._id, 'response', key, value)">Use this token</a-button><br />
        <span>{{ key }}: {{ value }}</span>
      </div>
    </div>

    <div v-if="authRest.returnResponse && authRest.returnResponse?.data">
      <div class="saas_card padd-5 mb-1" v-for="(value, key) in authRest.returnResponse.data" :key="key">
        <a-button type="primary" size="small" @click="emitSelectedAuthToken(authRest._id, 'returnResponse', key, value)">Use this token</a-button
        ><br />
        <span>{{ key }}: {{ value }}</span>
      </div>
    </div>

    <ChainedAuthTokensRecursive
      v-if="childAuthRequest(authRest._id)"
      :authFullList="authFullList"
      :authRest="childAuthRequest(authRest._id)"
      @tokenSelectedRecursive="emitRecursiveSelectedAuthToken"
    />
  </div>
</template>

<script>
export default {
  name: 'ChainedAuthTokensRecursive',

  props: ['authFullList', 'authRest'],
  emits: ['tokenelectedRecursive'],
  data() {
    return {
      authList: [],
    };
  },
  methods: {
    childAuthRequest(authReqId) {
      return this.authFullList.find(auth => auth.parentDataAuthRestId === authReqId);
    },
    emitSelectedAuthToken(authRestId, responseType, key, value) {
      this.$emit('tokenSelectedRecursive', {
        authRestId,
        responseType,
        key,
        value,
      });
    },
    emitRecursiveSelectedAuthToken(payload) {
      this.$emit('tokenSelectedRecursive', payload);
    },
  },
};
</script>
