import { reactive } from 'vue';
import { menuBuilderApi } from '@menuBuilder/api';

const initialState = {
  menuLayoutLoading: false,
  menuLayout: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getMenuLayoutLoading: () => {
    return state.menuLayoutLoading;
  },
  getMenuLayout: () => {
    return state.menuLayout;
  },
};

const Mutations = {};

const Actions = {
  init: async () => {
    await Actions.fetchMenuLayout();
  },

  async fetchMenuLayout() {
    state.menuLayoutLoading = true;
    state.menuLayout = await menuBuilderApi.getLayout(true);
    state.menuLayoutLoading = false;
  },
};

export const MenuLayoutGetters = Getters;
export const MenuLayoutMutations = Mutations;
export const MenuLayoutActions = Actions;
