<template>
  <div>
    <h3>
      <BuildOutlined />
      SMS List
      <a-badge
        :count="sms.total"
        :number-style="{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }"
      />
    </h3>
    <h6>Mesaje trimise: {{ sms.totalCount }}.</h6>

    <div>
      <a-modal
        v-model:open="openModal"
        width="100%"
        wrap-class-name="full-modal"
        :title="selectedKey == 'messageText' ? 'Continut mesaj' : 'Eroare mesaj'"
        @ok="selectRow(0, '')"
      >
        <div v-if="selectedKey === 'messageText'">
          <div style="white-space: pre-line">
            {{ sms.data?.find(value => value._id === selected)?.messageText }}
          </div>
        </div>
        <div v-if="selectedKey === 'isSent'">
          <div style="white-space: pre-line">
            {{ sms.data?.find(value => value._id === selected)?.reasonNotSent }}
          </div>
        </div>
      </a-modal>
    </div>

    <a-table
      bordered
      :locale="{ emptyText: '-' }"
      size="middle"
      :loading="loading"
      :columns="tableColumns"
      :data-source="sms.data"
      :pagination="false"
      @change="handleSorter"
    >
      <template #customFilterIcon="{ filtered, column }">
        <SearchOutlined v-if="column.key?.includes('message')" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <FilterOutlined v-else :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="width: 200px; padding: 8px; display: block">
          <template v-if="column.key === 'sentAt'">
            <a-date-picker
              :readOnly="true"
              :placeholder="'From'"
              :format="'DD.MM.YYYY'"
              :value="selectedKeys[0] ? dayjs(new Date(selectedKeys[0])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => setSelectedKeys(value ? [value, selectedKeys[1]] : [null, selectedKeys[1]])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-date-picker
              :readOnly="true"
              :placeholder="'To'"
              :format="'DD.MM.YYYY'"
              :value="selectedKeys[1] ? dayjs(new Date(selectedKeys[1])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => setSelectedKeys(value ? [selectedKeys[0], value] : [selectedKeys[0], null])"
            />
          </template>
          <template v-else-if="column.key === 'isSent'">
            <div style="margin-bottom: 8px">Status</div>
            <div style="margin-bottom: 8px">
              <a-checkbox
                :value="selectedKeys[0]"
                @click="e => (selectedKeys[0] = !selectedKeys[0])"
                @change="e => setSelectedKeys(e.target.checked ? [e.target.checked, selectedKeys[1]] : [false, selectedKeys[1]])"
              >
                Sent
              </a-checkbox>
            </div>
            <div style="margin-bottom: 8px">
              <a-checkbox
                :value="selectedKeys[1]"
                @click="e => (selectedKeys[1] = !selectedKeys[1])"
                @change="e => setSelectedKeys(e.target.checked ? [selectedKeys[0], e.target.checked] : [selectedKeys[0], false])"
              >
                Not Sent
              </a-checkbox>
            </div>
          </template>
          <template v-else>
            <a-input
              allowClear
              :placeholder="`Cauta in '${column.title}'`"
              :value="selectedKeys[0]"
              style="margin-bottom: 8px"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
          </template>

          <a-button
            type="primary"
            size="small"
            style="width: 88px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 88px; position: absolute" @click="handleReset(clearFilters, confirm)">Reset</a-button>
        </div>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'smsCount'">
          <div>
            {{ record.isSent ? record.smsCount : '-' }}
          </div>
        </template>
        <template v-if="column.key === 'isSent'">
          <div v-if="record.isSent" class="text-success">
            <CheckSquareOutlined />
            Trimis.
          </div>
          <div v-else class="text-danger">
            <CloseSquareOutlined />
            Netrimis.
          </div>
          <a-button v-if="!record.isSent" @click="selectRow(record._id, 'isSent')" class="m-2">Vizualizare eroare...</a-button>
          <a-button v-if="!record.isSent" @click="resendSMS(record._id)" class="m-2">Retrimite mesaj</a-button>
        </template>
        <template v-else-if="column.key === 'messageText'">
          <div style="white-space: pre-line">
            {{ record.messageText.length > 128 ? record.messageText.substring(0, 128) + '...' : record.messageText }}
          </div>
          <a @click="selectRow(record._id, 'messageText')">
            <PlusSquareOutlined />
          </a>
        </template>
        <template v-else-if="column.key === 'sentAt'">
          <a>
            {{
              new Date(record.sentAt).toLocaleDateString('ro-RO', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })
            }}
          </a>
        </template>
      </template>
    </a-table>
    <a-pagination
      v-model:current="sms.page"
      v-model:page-size="sms.limit"
      :size="'default'"
      :total="sms.total"
      :showTotal="total => `${total} mesaje.`"
      @change="onChange"
      style="padding: 10px 0"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ro';

import { SearchOutlined, FilterOutlined, PlusSquareOutlined, BuildOutlined, CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { instanceApi } from '@dataSystem/api';
import { SmsGetters, SmsActions } from '../../shared/sms.store';

export default {
  data() {
    return {
      loading: false,
      visible: false,
      selected: 0,
      selectedKey: '',
      openModal: false,
      tableColumns: [],
      sms: {
        page: 1,
        pages: 0,
        total: 0,
        limit: 10,
        data: [],
      },
      sorters: {},
      filters: {},
    };
  },
  props: ['instanceId'],
  async mounted() {
    this.loading = true;

    await this.fetchSmsList(this.sms.page, this.sms.limit);
    this.columns();

    this.loading = false;
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  methods: {
    columns() {
      let columns = [];
      if (this.sms.data.length === 0) {
        columns = [
          {
            dataIndex: 'messageTo',
            title: 'Message To',
            key: 'messageTo',
            customFilterDropdown: true,
            sorter: true,
          },
          {
            dataIndex: 'smsCount',
            title: 'SMS Count',
            key: 'smsCount',
          },
          {
            dataIndex: 'messageText',
            title: 'Message Text',
            key: 'messageText',
            customFilterDropdown: true,
          },
          {
            dataIndex: 'isSent',
            title: 'Is Sent',
            key: 'isSent',
            customFilterDropdown: true,
            sorter: true,
          },
          {
            dataIndex: 'sentAt',
            title: 'Sent At',
            key: 'sentAt',
            customFilterDropdown: true,
            sorter: true,
          },
        ];
      } else {
        Object.keys(this.sms.data[0] ?? {})?.forEach(key => {
          if (key !== '_id' && key !== 'reasonNotSent') {
            columns.push({
              dataIndex: key,
              title: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
              key,
              customFilterDropdown: key !== 'smsCount',
              sorter: key !== 'messageText' && key !== 'smsCount',
            });
          }
        });
      }

      this.tableColumns = columns;
    },
    async resendSMS(smsId) {
      this.loading = true;
      try {
        const response = await instanceApi.resendSMS(smsId);
        if (response.sent) {
          notification.success({
            message: 'SMS Retrimite',
            description: `SMS-ul a fost retrimis cu succes.`,
          });
        } else {
          throw new Error('Eroare la retrimitere');
        }
      } catch (error) {
        notification.error({
          message: 'Eroare la retrimitere',
          description: 'Mesajul SMS nu a putut fi retrimis.',
        });
      } finally {
        this.loading = false;
        await this.fetchSmsList(this.sms.page, this.sms.limit);
      }
    },
    async fetchSmsList(page, limit) {
      this.loading = true;

      if (this.instanceId) {
        await SmsActions.fetchForInstance(this.instanceId);
      } else {
        await SmsActions.fetchSmsList(page, limit, this.sorters, this.filters);
      }

      this.sms = SmsGetters.getAll();
      this.columns();

      this.loading = false;
    },
    async handleSorter(pagination, filters, sorter) {
      if (sorter.order) {
        this.sorters[sorter.columnKey] = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        this.sorters = {};
      }

      await this.fetchSmsList(this.sms.page, this.sms.limit);
    },
    async handleSearch(selectedKeys, confirm, dataIndex) {
      const [firstKey, secondKey] = selectedKeys;

      if (dataIndex === 'sentAt') {
        if (new Date(firstKey) > new Date(secondKey))
          this.filters[dataIndex] = {
            ...(secondKey ? { from: new Date(secondKey) } : {}),
            ...(firstKey ? { to: new Date(firstKey) } : {}),
          };
        else
          this.filters[dataIndex] = {
            ...(firstKey ? { from: new Date(firstKey) } : {}),
            ...(secondKey ? { to: new Date(secondKey) } : {}),
          };
      } else if (dataIndex === 'isSent') {
        if (firstKey === secondKey) {
          this.filters[dataIndex] = null;
        } else if (firstKey === true) {
          this.filters[dataIndex] = true;
        } else if (secondKey === true) {
          this.filters[dataIndex] = false;
        }
      } else {
        this.filters[dataIndex] = firstKey;
      }

      await this.fetchSmsList(this.sms.page, this.sms.limit);
      confirm();
    },
    async handleReset(clearFilters, confirm) {
      clearFilters();
      this.filters = {};

      await this.fetchSmsList(this.sms.page, this.sms.limit);
      confirm();
    },
    onChange(page) {
      this.fetchSmsList(page, this.sms.limit);
    },
    selectRow(rowId, key) {
      this.openModal = rowId !== 0;
      this.selected = rowId;
      this.selectedKey = key;
    },
  },
  components: {
    BuildOutlined,
    SearchOutlined,
    FilterOutlined,
    PlusSquareOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
  },
};
</script>
