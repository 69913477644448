<template>
  <div v-if="blueprint">
    <template v-if="(loadDetailsPopupInstance && openItemInPopup === 'stayInPopup') || (loadDetailsPopupInstance && loadMoreView)">
      <a-button @click="loadDetailsPopupInstance = null"><CaretLeftOutlined /> Back</a-button>
      <a-divider />
      <ElementDataItemView
        :lastDepth="lastDepth"
        :propsBlueprint="blueprint"
        :propsInstance="loadDetailsPopupInstance"
        :instanceId="loadDetailsPopupInstance?._id"
        :object="editedObject"
        :popup-view="true"
      />
    </template>

    <template v-else-if="!object.view.default?.showDefaultCreateForm">
      <h3 v-if="!loadMoreView && object?.view?.default?.showTitle">
        {{ object?.view?.default?.customTitle?.length ? object?.view?.default?.customTitle : blueprint?.name }}
      </h3>
      <div
        style="margin-bottom: 20px; padding-left: 10px; padding-right: 10px; float: right"
        v-if="(object.view.default?.showDownloadXLSX ?? false) && !(object.view.default?.moveTopDownloadXLSX ?? false)"
      >
        <a-button type="primary" @click="exportXls(object._id, blueprint._id)" :loading="downloadXLSX.status">Download Excel</a-button>
      </div>
      <div
        style="margin-bottom: 20px; padding-left: 10px; padding-right: 10px; float: right; position: absolute; top: 0; right: 0"
        v-else-if="object.view.default?.moveTopDownloadXLSX ?? false"
      >
        <a-button type="primary" @click="exportXls(object._id, blueprint._id)" :loading="downloadXLSX.status">Download Excel</a-button>
      </div>
      <div v-if="object.view.default?.simpleListView ?? false" class="clearfix"></div>

      <template v-if="!object.view.default?.simpleListView ?? false">
        <div
          style="margin-bottom: 20px; float: left; margin-right: 10px"
          v-if="blueprint && (userRolesWhichCanCreate?.length ?? false) && (object.view.default?.showAddNewButton ?? true)"
        >
          <a-button type="primary" @click="openCloseModal('createNew' + (loadMoreView ? '-loadMoreView' : ''), true)">{{
            (object?.view?.default?.addNewButtonLabel ?? '') !== '' ? object?.view?.default?.addNewButtonLabel : 'Add new'
          }}</a-button>
        </div>

        <div
          style="margin-bottom: 20px; float: left; margin-right: 10px"
          v-if="blueprint && (userRolesWhichCanCreate?.length ?? false) && (object.view.default?.showMassEmail ?? false)"
        >
          <a-button type="primary" @click="openCloseModal('massEmail' + (loadMoreView ? '-loadMoreView' : ''), true)">{{
            (object?.view?.default?.massEmailButtonLabel ?? '') !== '' ? object?.view?.default?.massEmailButtonLabel : 'Send E-mail'
          }}</a-button>
        </div>

        <div
          style="margin-bottom: 20px; float: left"
          v-if="blueprint && (userRolesWhichCanCreate?.length ?? false) && (object.view.default?.showSearchTable ?? false)"
        >
          <search-table :object="object" :blueprint="blueprint"> </search-table>
        </div>

        <div style="margin-bottom: 20px; width: 120px; padding-left: 10px; float: left" v-if="blueprint && (user?.isSuperAdmin ?? null)">
          <a-select v-model:value="adminViewMode" @change="adminCheckboxActions" default-value="user" style="width: 120px">
            <a-select-option value="user">User Mode</a-select-option>
            <a-select-option value="viewGlobal">View Global</a-select-option>
          </a-select>
        </div>
        <div
          style="margin-bottom: 20px; padding-left: 20px; float: left"
          v-if="adminViewMode !== 'user' && blueprint && (userRolesWhichCanCreate?.length ?? false)"
        >
          <a-button type="primary" @click="saveAdminChanges()"> Save </a-button>
        </div>

        <div class="clearfix"></div>
        <a-modal
          :footer="false"
          :width="'1200px'"
          :destroyOnClose="true"
          :maskClosable="false"
          :open="isOpenModal('linkToReferenceInstance')"
          :title="linkToReferenceInstanceBlueprintName"
          @cancel="openCloseModal('linkToReferenceInstance', false)"
        >
          <CreateEditInstance
            :object="object"
            :showEditButton="false"
            :showDeleteButton="false"
            :addNew="false"
            @closePopup="openCloseModal('linkToReferenceInstance', false)"
            :blueprintId="linkToReferenceInstanceBlueprintId"
            :instanceId="linkToReferenceInstanceInstanceId"
          />
        </a-modal>
      </template>

      <a-spin :spinning="contentHtmlLoading" style="width: 100%">
        <RenderHtml v-if="renderedContentHtml" @buttonClicked="renderHtmlButtonClicked" :key="renderHtmlTemplateKey" :content="renderedContentHtml" />
      </a-spin>
      <div v-if="object.view.listFields.find(item => item.show && item.isCalendar)" class="mb-3">
        <a-date-picker :allow-clear="false" v-model:value="calendarDate" @change="regenerateList" format="MMM YYYY" :picker="'month'" />
      </div>
      <template v-if="object.view.default?.showAutoData ?? true">
        <template v-if="object.view.default?.simpleListView">
          <SimpleListTable
            :object="object"
            :blueprint="blueprint"
            :dataColumns="object.view?.listFieldsCustomHeader?.enabled ? customDataColumns : dataColumns"
            :dataList="loadMore && !loadMoreView ? fixedInstances : dataList"
            :userRolesWhichCanCreate="userRolesWhichCanCreate"
            :loadMore="loadMore"
            :loadMoreView="loadMoreView"
            :rowsNumbering="object.view.default?.rowsNumbering"
            :pageNumber="paginationSwitcher.current"
            :defaultPageSize="parseInt(paginationSwitcher.pageSize)"
            :listActionMode="listActionMode"
            :listActionModeDelete="listActionModeDelete"
            :fixedFirstRow="object.view?.listFieldsCustomHeader?.enabled && object.view?.listFieldsCustomHeader?.showFirstRow"
          />
        </template>
        <template v-else>
          <ListTable
            :object="object"
            :blueprint="blueprint"
            :dataColumns="object.view?.listFieldsCustomHeader?.enabled ? customDataColumns : dataColumns"
            :dataList="loadMore && !loadMoreView ? fixedInstances : dataList"
            :listFields="object.view.listFields"
            :userRolesWhichCanCreate="userRolesWhichCanCreate"
            :userRolesWhichCanDelete="userRolesWhichCanDelete"
            :user="user"
            :total="loadMore && !loadMoreView ? (fixedInstances?.length ?? 0) : (dataList?.length ?? 0)"
            :loadMore="loadMore"
            :loadMoreView="loadMoreView"
            :rowsSelected="rowsSelected"
            :activateCheckBox="adminViewMode !== 'user' || (object.view.default?.showMassEmail ?? null)"
            :listActionMode="listActionMode"
            :listActionModeDelete="listActionModeDelete"
            :fixedFirstRow="object.view?.listFieldsCustomHeader?.enabled && object.view?.listFieldsCustomHeader?.showFirstRow"
            :openEditInstanceField="openEditInstanceField"
            :openTabColumnTrigger="openTabColumnTrigger"
            @tableRowClick="tableRowClick"
            @tableOpenLinkToReferenceInstance="tableOpenLinkToReferenceInstance"
            @tableFetchInstances="tableFetchInstances"
            @clearSelections="clearSelections"
            @updateRowsSelected="updateRowsSelected"
            @pushRowsSelected="pushRowsSelected"
            @removeRowsSelected="removeRowsSelected"
            @spliceRowsSelected="spliceRowsSelected"
            @saveOpenEditInstanceField="toggleSaveToOpenEditInstanceField"
            @savedOpenEditInstanceField="toggleSavedToOpenEditInstanceField"
            @savedInstance="savedInstance"
          />
        </template>
      </template>

      <div v-if="paginationSwitcher.pageSize" style="text-align: center; margin-top: 20px">
        <a-pagination
          show-quick-jumper
          :defaultPageSize="parseInt(paginationSwitcher.pageSize)"
          :default-current="paginationSwitcher.current"
          :total="paginationSwitcher.total"
          :show-total="total => (total && !object.view.default?.simpleListView ? `Rezultate: ${total}` : '')"
          @change="paginateList"
          :showSizeChanger="false"
        />
      </div>
      <div
        style="text-align: center; font-size: 14px; margin-top: 15px; opacity: 0.65"
        v-else-if="totalCount && !object.view.default?.simpleListView"
      >
        Rezultate: {{ totalCount }}
      </div>

      <!-- Load more -->
      <template v-if="loadMore && !loadMoreView">
        <div style="text-align: center; margin-top: 10px">
          <a-button type="primary" @click="openCloseModal('loadMore', true)"> View all </a-button>
        </div>
        <a-modal
          :footer="false"
          :width="'1200px'"
          :destroyOnClose="true"
          :open="isOpenModal('loadMore')"
          :title="blueprint.name"
          @cancel="openCloseModal('loadMore', false)"
        >
          <ElementDataListView :loadMoreView="true" :object="object" :popup-view="true" />
          <template #footer>
            <a-button @click="openCloseModal('loadMore', false)"> Close </a-button>
          </template>
        </a-modal>
      </template>
      <!-- Load more -->

      <a-spin :spinning="contentHtmlLoading" style="width: 100%">
        <RenderHtml
          v-if="renderedContentHtmlAfter"
          @buttonClicked="renderHtmlButtonClicked"
          :key="renderHtmlTemplateKeyAfter"
          :content="renderedContentHtmlAfter"
        />
      </a-spin>
    </template>
    <!--    v-else show direct edit form -->
    <template v-else>
      <h3 v-if="!loadMoreView && object?.view?.default?.showTitle">
        {{ object?.view?.default?.customTitle?.length ? object?.view?.default?.customTitle : blueprint?.name }}
      </h3>
      <hr style="margin: 10px 0 20px 0" />
      <CreateEditInstance
        :object="object"
        :filterConditions="filterConditions"
        :additionalFilterConditions="additionalFilterConditions"
        :showEditButton="object.view.default?.showEditButton ?? true"
        :editButtonLabel="object.view.default?.editButtonLabel ?? ''"
        :showDeleteButton="object.view.default?.showDeleteButton ?? true"
        :deleteButtonLabel="object.view.default?.deleteButtonLabel ?? ''"
        :showFields="object?.view?.itemFields ?? []"
        @closePopup="openCloseModal('createNew' + (loadMoreView ? '-loadMoreView' : ''), false)"
        :blueprint="blueprint"
        :clearAfterSubmit="true"
        :addNew="true"
        :instanceId="null"
      />
    </template>

    <!-- ElementDataItemView -->
    <template v-if="loadDetailsPopupInstance && openItemInPopup === 'otherPopup' && !loadMoreView">
      <a-modal
        :footer="false"
        :maskClosable="false"
        :width="'1200px'"
        :destroyOnClose="true"
        :open="isOpenModal('details')"
        :title="blueprint.name"
        @cancel="openCloseModal('details', false)"
      >
        <ElementDataItemView
          v-if="loadDetailsPopupInstance"
          :lastDepth="lastDepth"
          :instanceId="loadDetailsPopupInstance._id"
          :object="editedObject"
          :popup-view="true"
        />
        <template #footer>
          <a-button @click="openCloseModal('details', false)"> Close </a-button>
        </template>
      </a-modal>
    </template>
    <!-- ElementDataItemView -->

    <!-- CreateNewInstance -->
    <a-modal
      :footer="false"
      :width="'1200px'"
      :destroyOnClose="true"
      :maskClosable="false"
      :open="isOpenModal('createNew' + (loadMoreView ? '-loadMoreView' : ''))"
      :title="(object?.view?.default?.addNewButtonLabel ?? '') || 'Add new'"
      @cancel="openCloseModal('createNew' + (loadMoreView ? '-loadMoreView' : ''), false)"
    >
      <CreateEditInstance
        :object="object"
        :filterConditions="filterConditions"
        :additionalFilterConditions="additionalFilterConditions"
        :showEditButton="object.view.default?.showEditButton ?? true"
        :editButtonLabel="object.view.default?.editButtonLabel ?? ''"
        :showDeleteButton="object.view.default?.showDeleteButton ?? true"
        :deleteButtonLabel="object.view.default?.deleteButtonLabel ?? ''"
        :showFields="object?.view?.itemFields ?? []"
        @closePopup="openCloseModal('createNew' + (loadMoreView ? '-loadMoreView' : ''), false)"
        :blueprint="blueprint"
        :addNew="true"
        :instanceId="null"
      />
    </a-modal>
    <!-- CreateNewInstance -->
    <!-- MassEmail -->
    <a-modal
      :footer="false"
      :width="'1200px'"
      :destroyOnClose="true"
      :maskClosable="false"
      :open="isOpenModal('massEmail' + (loadMoreView ? '-loadMoreView' : ''))"
      :title="(object?.view?.default?.massEmailButtonLabel ?? '') || 'Mass E-mail'"
      @cancel="openCloseModal('massEmail' + (loadMoreView ? '-loadMoreView' : ''), false)"
    >
      <MassEmail
        :object="object"
        :dataColumns="dataColumns"
        :listFields="object.view.listFields"
        @closePopup="openCloseModal('massEmail' + (loadMoreView ? '-loadMoreView' : ''), false)"
        @clearSelections="clearSelections"
        :blueprint="blueprint"
        :rowsSelected="rowsSelected"
      />
    </a-modal>
    <a-modal
      :footer="false"
      :width="'1200px'"
      :destroyOnClose="true"
      :maskClosable="false"
      :open="isOpenModal('textDetails')"
      :title="blueprint.name"
      @cancel="openCloseModal('textDetails', false)"
    >
      <ViewInstance :object="object" :showFields="object.view?.itemFields ?? []" :blueprint="blueprint" :instance="loadDetailsPopupInstance" />
    </a-modal>
  </div>
</template>

<script>
import CreateEditInstance from '@templateDataSystem/views/components/CreateEditInstance';
import MassEmail from '@templateDataSystem/views/components/MassEmail';
import RenderHtml from '@templateDataSystem/views/components/RenderHtml';
import { objectId } from '@/core/utils/array-manipulation';

import ListTable from '@templateDataSystem/views/components/ListTable.vue';
import SimpleListTable from '@templateDataSystem/views/components/SimpleListTable.vue';
import { NewTemplatesActions, NewTemplatesGetters, NewTemplatesMutations } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { UserGetters } from '@userManagement/user.store';
import { instanceApi, fieldApi } from '@dataSystem/api';
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
import _ from 'lodash';
import SearchTable from '@templateDataSystem/views/components/SearchTable.vue';
import { extractDateFormat, removeDiacritics } from '@/core/utils/string-manipulation';
import ViewInstance from '@templateDataSystem/views/components/ViewInstanceAsText/ViewInstanceAsText.vue';
import { CaretLeftOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
// import { TemplateDataSerializer } from '@/apps/templateDataSystem/shared/TemplateDataSerializer';
import dayjs from 'dayjs';
import ElementDataItemView from './ElementDataItemView.vue';

export default {
  name: 'ElementDataListView',
  props: ['object', 'loadMoreView', 'viewType', 'lastDepth'],
  emits: ['exportXls'],
  components: {
    ViewInstance,
    SearchTable,
    ListTable,
    SimpleListTable,
    RenderHtml,
    CreateEditInstance,
    ElementDataItemView,
    MassEmail,
    CaretLeftOutlined,
  },
  data() {
    return {
      adminViewMode: 'user',
      loading: false,
      page: 1,
      pagination: {},
      popupPagination: {},
      contentHtml: null,
      loadMore: false,
      loadDetailsPopup: false,
      loadDetailsPopupInstance: null,
      renderHtmlTemplateKey: objectId(),
      renderHtmlTemplateKeyAfter: objectId(),
      linkToReferenceInstanceBlueprintId: null,
      linkToReferenceInstanceInstanceId: null,
      linkToReferenceInstanceBlueprintName: '',
      createNewInstance: false,
      listActionMode: false,
      listActionModeDelete: false,
      fixedInstances: [],
      totalCount: null,
      emailRecipient: null,
      emailQuery: null,
      rowsSelected: [],
      rowsSelectedOnInit: [],
      clearAllApplied: false,
      calendarDate: undefined,
      additionalFilterConditions: {},
      openEditInstanceField: {
        cancel: false,
        status: false,
        save: false,
      },
      openTabColumnTrigger: false,
    };
  },
  watch: {
    instances: {
      deep: true,
      handler() {
        this.setFixedInstancesIfLoadMore();
      },
    },
    updatePagination(data) {
      if (data?.page) {
        this.createPagination(data.page, data.limit, data.totalCount);
      }
      if (data?.setPage) {
        this.page = data.setPage;
      }

      // load more reset
      if (!this.isOpenModal('loadMore')) {
        this.setFixedInstancesIfLoadMore();
      }

      // update totalCount
      if (data?.totalCount) {
        this.totalCount = data.totalCount;
      }
    },

    async createdEditedDeletedInstance(data) {
      const updateListOnAnyCRUD = this.object.view?.default?.updateListOnAnyCRUD ?? true;
      const updateListOnBlueprintIds = this.object.view?.default?.updateListOnBlueprintIdCRUD ?? [];

      if (!data.status) {
        if (updateListOnAnyCRUD || updateListOnBlueprintIds.includes(data.blueprintId) || data.objectId === this.object._id) {
          const instanceListQueryParams = NewTemplatesGetters.getInstanceListQueryParams(this.object._id);
          const query = instanceListQueryParams[this.object.blueprintId] ?? null;
          if (query) {
            query.page = query.page ?? 1;
            await NewTemplatesActions.fetchInstanceList(this.object._id, this.object.blueprintId, query);
          }
        }
      }
    },
  },
  computed: {
    user: UserGetters.getUser,
    downloadXLSX: NewTemplatesGetters.getDownloadXLSX,
    downloadPDF: NewTemplatesGetters.getDownloadPDF,
    instances() {
      return NewTemplatesGetters.getInstanceList(this.object._id, this.object.blueprintId);
    },
    filterConditions() {
      return NewTemplatesGetters.getFilterConditions(this.object._id);
    },

    renderedContentHtml() {
      return NewTemplatesGetters.getContentHtml(this.object._id, this.object.view.type);
    },

    renderedContentHtmlAfter() {
      return NewTemplatesGetters.getContentHtmlAfter(this.object._id, this.object.view.type);
    },

    contentHtmlLoading() {
      return NewTemplatesGetters.getContentHtmlLoading(this.object._id);
    },

    ///
    paginationSwitcher() {
      return this.loadMoreView ? this.popupPagination : this.pagination;
    },
    editedObject() {
      return {
        ...this.object,
        view: {
          ...this.object.view,
          contentHtml: '',
          default: {
            ...this.object.view.default,
            showAutoData: true,
          },
        },
      };
    },

    createdEditedDeletedInstance: NewTemplatesGetters.getCreatedEditedDeletedInstance,

    userRoles: NewTemplatesGetters.getRoles,

    userRolesWhichCanCreate() {
      if (Object.keys(this.userRoles).length) {
        return this.userRoles.filter(role => {
          const permission = role.permissionByBlueprintId[this.object.blueprintId];
          if (!permission) {
            return false;
          }
          return permission.createAuthority.mode === 'yes';
        });
      }
      return null;
    },

    userRolesWhichCanDelete() {
      if (Object.keys(this.userRoles).length) {
        return this.userRoles.filter(role => {
          const permission = role.permissionByBlueprintId[this.object.blueprintId];
          if (!permission) {
            return false;
          }
          return permission.deleteAuthority.mode !== 'none';
        });
      }
      return null;
    },

    blueprint() {
      return NewTemplatesGetters.getBlueprint(this.object.blueprintId);
    },

    updatePagination() {
      return NewTemplatesGetters.getUpdatePagination(this.object._id);
    },

    openItemInPopup() {
      return this.object.clickItem?.openItemInPopup ?? null;
    },
    dataColumns() {
      const query = NewTemplatesGetters.getInstanceListQueryParams(this.object._id, this.object.blueprintId);
      const columns = this.object.view.listFields
        .filter(item => item.show)
        .map((item, index) => {
          const data = {
            title: item.label,
            key: item.id,
            dataIndex: item.id.split('.'),
            dateRange: item?.dateRange ?? false,
            type: item.type,
            isCalendar: item?.isCalendar ?? false,
            customCell: (record, rowIndex, column) => ({
              onDblclick: () => {
                if (item.editOnDbClick) {
                  this.cellDbClick(record, rowIndex, column);
                }
              },
              onclick: () => {
                this.cellResetDbClick(record, rowIndex, column);
              },
              'data-instance-id': item.editOnDbClick ? record._id : null,
              'data-row-index': item.editOnDbClick ? rowIndex : null,
              'data-column-index': item.editOnDbClick ? index : null,
              tabindex: item.editOnDbClick ? `${rowIndex}${index + 1}` : 0,
              class: [
                this.openEditInstanceField?.status &&
                this.openEditInstanceField?.column?.key === column.key &&
                this.openEditInstanceField?.rowIndex === rowIndex
                  ? 'edit-field-open'
                  : '',
                item.editOnDbClick ? `tab-${rowIndex}${index + 1}` : '',
                item.editOnDbClick ? 'edit-field' : '',
                'column-class',
              ],
            }),
          };
          if (item.formatting?.columnWidth) {
            data.width = parseInt(item.formatting.columnWidth, 10);
          }

          if (item.id === 'action') {
            this.listActionMode = true;
            this.listActionModeDelete = item.delete ?? false;
            data.width = 135;
          } // ok
          else {
            // let scopedSlots = {
            //   ...data.scopedSlots ?? {},
            // }
            // if (item?.searchable) {
            //   scopedSlots = {
            //     ...scopedSlots,
            //     filterDropdown: 'filterDropdown',
            //     filterIcon: 'filterIcon',
            //   }
            // }

            if (item?.searchable) {
              data.customFilterDropdown = true;
            }

            data.defaultFilteredValue = query?.filters && query.filters[item.id] ? query.filters[item.id] : [];
            data.defaultSortOrder = query && query.sortFieldId === item.id ? query.sortOrder : null;

            if (item?.filter) {
              data.filters = item?.filterValues ?? [];
            }
            data.sorter = item?.order ?? false;
          }
          return data;
        });

      // de aici implementam calandar view
      let fixedRight = false;
      const isCalendarExist = columns.find(c => c.isCalendar);
      return columns
        .map(item => {
          if (item.isCalendar) {
            fixedRight = true;
            return this.generateCalendarColumns(isCalendarExist);
          }
          if (isCalendarExist) {
            return { ...item, width: 150, fixed: fixedRight ? 'right' : 'left' };
          }
          return { ...item };
        })
        .flat();
    },
    customDataColumns() {
      const columns = [];
      if (this.dataColumns && this.object.view?.listFieldsCustomHeader && this.object.view?.listFieldsCustomHeader?.enabled) {
        this.object.view.listFieldsCustomHeader.headerColumns.forEach(col => {
          const colObj = this.generateTableHeaderCell(col);
          columns.push(colObj);
        });
      }
      return columns;
    },

    // dataList() {
    //   return TemplateDataSerializer.instanceListToData(this.instances?.instanceList ?? [], this.object, this.blueprint);
    // },
    dataList() {
      let dataList = (this.instances?.instanceList ?? []).map(item => {
        const refValues = {};
        Object.keys(item).forEach(key => {
          const field = this.blueprint.fields.find(f => f._id === key);

          if (!key.startsWith('_')) {
            if (field && field?.structure?.type === 'file') {
              const filesArr = [];
              if (item[key] && item[key] !== '') {
                const files = JSON.parse(item[key]);
                if (Object.keys(files).length) {
                  (files ?? []).forEach(file => {
                    filesArr.push(file.name);
                  });
                }
              }
              refValues[key] = filesArr.join(', ');
            } else if (item[`_ref_display_val_tokens_${key}`]) {
              refValues[key] = item[`_ref_display_val_tokens_${key}`];
              refValues[`${key}-original-value`] = item[key];
            } else if (item[`_ref_display_val_${key}`]) {
              refValues[key] = item[`_ref_display_val_${key}`];
              refValues[`${key}-original-value`] = item[key];
            } else if (this.object.view.listFields.find(lf => lf?.id === field?.id)?.formatting?.toUppercase) {
              refValues[key] = item[key]?.toString()?.toUpperCase();
            }
          }

          if (key.startsWith('_concat_')) {
            const listFIeld = this.object.view.listFields.find(lf => lf?.id === key);
            if (listFIeld?.formatting?.toUppercase) {
              refValues[key] = item[key]?.toString()?.toUpperCase();
            }
          }

          if (key.startsWith('_other_blueprint_')) {
            const listFIeld = this.object.view.listFields.find(lf => lf?.id === key);
            if (listFIeld?.formatting?.toUppercase) {
              if (Array.isArray(item[key])) {
                refValues[key] = item[key].map(val => val?.toString()?.toUpperCase());
              } else {
                refValues[key] = item[key]?.toString()?.toUpperCase();
              }
            }
          }

          if (key.startsWith('_ref_display_val_tokens_')) {
            const listFIeld = this.object.view.listFields.find(lf => lf?.id === key.replace('_ref_display_val_tokens_', ''));
            if (listFIeld?.formatting?.toUppercase) {
              if (Array.isArray(item[key])) {
                refValues[key.replace('_ref_display_val_tokens_', '')] = item[key].map(val => val?.toString()?.toUpperCase());
              } else {
                refValues[key.replace('_ref_display_val_tokens_', '')] = item[key]?.toString()?.toUpperCase();
              }
            }
          }

          if (key === '_created_at') {
            const format = this.object?.view?.default?.dateFormat ?? 'DD.MM.YYYY, HH:mm';
            const data = dayjs(new Date(item[key]));
            if (data.isValid()) {
              refValues[key] = data.format(format);
            } else {
              refValues[key] = item[key];
            }
          }

          /* if (field && field.structure.type === 'date') {
            const fieldDataFormat = field.structure.options.format;
            const fieldData = moment(new Date(item[key]));
            if (item[key] && fieldData._isValid) {
              refValues[key] = fieldData.format(fieldDataFormat);
            } else {
              refValues[key] = item[key];
            }
          } */
        });
        return {
          ...item,
          ...refValues,
          key: `${item._id}-${objectId()}`,
        };
      });

      let fixedFirstRowData = null;

      if (this.object.view?.listFieldsCustomHeader?.enabled && this.object.view?.listFieldsCustomHeader?.showFirstRow) {
        fixedFirstRowData = {};
        this.object.view.listFieldsCustomHeader.headerColumns.forEach(col => {
          if (!col.headerColumns.length) {
            if (col.attachedField) {
              fixedFirstRowData[col.attachedField] = col.firstRowData;
            }
          } else {
            fixedFirstRowData = this.getStaticFirstRowData(fixedFirstRowData, col);
          }
        });
      }
      if (fixedFirstRowData) {
        dataList.unshift(fixedFirstRowData);
      }

      if (this.object.view.default?.replaceDiacritics) {
        dataList.forEach(row => {
          Object.entries(row).forEach(([key, value]) => {
            if (typeof value === 'string') {
              row[key] = removeDiacritics(value);
            } else if (Array.isArray(value)) {
              row[key] = value.map(arrVal => removeDiacritics(arrVal));
            }
          });
        });
      }

      // calendar logic
      const calendar = this.object.view.listFields.find(item => item.show && item.isCalendar);
      if (calendar) {
        if (calendar.calendarSettings && calendar.calendarSettings.dateFieldId && calendar.calendarSettings.valueFieldId) {
          const groupBy = calendar.calendarSettings.groupByFieldId;
          const date = calendar.calendarSettings.dateFieldId;
          const value = calendar.calendarSettings.valueFieldId;
          dataList = dataList.reduce((acc, item) => {
            const existing = groupBy
              ? acc.find(
                  i =>
                    ((i[groupBy] && i[groupBy][0]) ?? i[groupBy]) === ((item[groupBy] && item[groupBy][0]) ?? item[groupBy]) &&
                    ((i[groupBy] && i[groupBy][0]) ?? i[groupBy])
                )
              : false;
            if (existing) {
              existing[`_calendar_${item[`_unformatted_${date}`]}`] = item[value];
              existing[`_instance_calendar_${item[`_unformatted_${date}`]}`] = item._id;
            } else {
              acc.push({
                ...item,
                [`_calendar_${item[`_unformatted_${date}`]}`]: item[value],
                [`_instance_calendar_${item[`_unformatted_${date}`]}`]: item._id,
              });
            }
            return acc;
          }, []);
        }
      }
      return dataList;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  },
  async created() {
    this.calendarDate = dayjs();
    if (this.updatePagination?.page) {
      this.createPagination(this.updatePagination.page, this.updatePagination.limit, this.updatePagination.totalCount);
    }
    // copy for load more
    this.setFixedInstancesIfLoadMore();

    const { totalCount } = NewTemplatesGetters.getUpdatePagination(this.object._id);
    this.totalCount = totalCount;
    NewTemplatesMutations.SET_DOWNLOAD_PDF(this.object.view.default?.showDownloadPDF ?? false, false);
  },
  methods: {
    // on edit field code
    toggleSaveToOpenEditInstanceField(value) {
      this.openEditInstanceField.save = value;
    },
    toggleSavedToOpenEditInstanceField() {
      this.openEditInstanceField.save = false;
      this.openEditInstanceField.status = false;
    },
    async handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.openEditInstanceField = {
          ...this.openEditInstanceField,
          cancel: true,
          status: false,
          save: false,
        };
      }
      if (event.key === 'Tab') {
        event.preventDefault();
        return;
        // @TODO - not working ok, to activate later on
        // const calendar = this.object.view.listFields.find(item => item.show && item.isCalendar);
        // if (calendar) {
        //   event.preventDefault();
        //   return;
        // }
        // let currentElement = document.activeElement;
        // if (!currentElement.classList.contains('column-class')) {
        //   currentElement = currentElement.closest('td.column-class');
        // }
        // const focusableCells = document.querySelectorAll('td.column-class[tabindex]:not([tabindex="0"])');
        // const cellsArray = Array.from(focusableCells);
        // const currentIndex = cellsArray.indexOf(currentElement);
        // const nextIndex = (currentIndex + 1) % cellsArray.length;
        // // eslint-disable-next-line prefer-destructuring
        // let dataColumns = this.dataColumns;
        // if (calendar) {
        //   dataColumns = this.dataColumns.filter(item => item.isCalendar);
        // }
        // let column = dataColumns[cellsArray[nextIndex].dataset.columnIndex];
        // if (column.children && column.children.length > 0) {
        //   // eslint-disable-next-line prefer-destructuring
        //   column = column.children[0];
        // }
        // const record = this.dataList[cellsArray[nextIndex].dataset.rowIndex];
        //
        // // save
        // this.openEditInstanceField = {
        //   ...this.openEditInstanceField,
        //   save: true,
        // };
        //
        // this.openTabColumnTrigger = true;
        //
        // const el = this;
        // setTimeout(() => {
        //   el.openTabColumn(
        //     cellsArray[nextIndex].dataset.rowIndex,
        //     column,
        //     record,
        //     column?.isCalendar && calendar?.calendarSettings?.valueFieldId ? calendar.calendarSettings.valueFieldId : null
        //   );
        // }, 300);
      }
      if (event.key === 'Enter') {
        this.openEditInstanceField = {
          ...this.openEditInstanceField,
          cancel: false,
          status: false,
          save: true,
        };
      }
    },
    savedInstance(value) {
      if (value) {
        this.openTabColumnTrigger = false;
      }
    },
    async openTabColumn(rowIndex, column, record, fieldId = null) {
      this.openEditInstanceField = {
        rowIndex: parseInt(rowIndex, 10),
        column,
        record,
        status: true,
        cancel: false,
        save: false,
      };
      console.log(this.openEditInstanceField);
      const field = await fieldApi.getOne(fieldId ?? column.key);
      this.openEditInstanceField = { ...this.openEditInstanceField, field };
    },
    async cellDbClick(record, rowIndex, column) {
      if (
        this.openEditInstanceField.status &&
        rowIndex === this.openEditInstanceField.rowIndex &&
        column.key === this.openEditInstanceField.column.key
      ) {
        return;
      }
      if (!column.key.startsWith('_')) {
        this.openEditInstanceField = { rowIndex, column, record, status: true, cancel: false, save: false };
        const field = await fieldApi.getOne(column.key);
        this.openEditInstanceField = { ...this.openEditInstanceField, field };
      }
    },
    async cellCalendarDbClick(record, rowIndex, column) {
      let localRecord = record;
      const calendar = this.object.view.listFields.find(item => item.show && item.isCalendar);
      const instanceId = record[`_instance${column.dataIndex}`] ?? null;
      localRecord = {
        ...record,
        [calendar.calendarSettings.valueFieldId]: record[column.dataIndex] ?? 0,
        _id: instanceId,
      };
      if (
        this.openEditInstanceField.status &&
        rowIndex === this.openEditInstanceField.rowIndex &&
        column.key === this.openEditInstanceField.column.key
      ) {
        return;
      }
      if (!column.key.startsWith('_')) {
        const additionalFilterConditions = {};
        if (calendar.calendarSettings.dateFieldId) {
          // mapped fieldId to value in the instance
          const dateFormat = extractDateFormat(
            Array.isArray(record[calendar.calendarSettings.dateFieldId])
              ? record[calendar.calendarSettings.dateFieldId][0]
              : record[calendar.calendarSettings.dateFieldId]
          );
          const dateFieldId = calendar.calendarSettings.dateFieldIdMappedFieldId ?? calendar.calendarSettings.dateFieldId;
          additionalFilterConditions[dateFieldId] = dayjs(column.dataIndex.replace(/^_calendar_/, '')).format(dateFormat);
        }
        if (calendar.calendarSettings.groupByFieldId) {
          const groupByFieldId = calendar.calendarSettings.groupByFieldIdMappedFieldId ?? calendar.calendarSettings.groupByFieldId;
          additionalFilterConditions[groupByFieldId] = Array.isArray(record[calendar.calendarSettings.groupByFieldId])
            ? record[calendar.calendarSettings.groupByFieldId][0]
            : record[calendar.calendarSettings.groupByFieldId];
        }
        this.openEditInstanceField = { rowIndex, column, additionalFilterConditions, record: localRecord, status: true, cancel: false, save: false };
        const field = await fieldApi.getOne(calendar.calendarSettings.valueFieldId);
        this.openEditInstanceField = { ...this.openEditInstanceField, field };
      }
    },
    cellResetDbClick(record, rowIndex, column) {
      if (
        this.openEditInstanceField.status &&
        rowIndex === this.openEditInstanceField.rowIndex &&
        column.key === this.openEditInstanceField.column.key
      ) {
        this.openEditInstanceField.save = true;
        this.openEditInstanceField.status = true;
      } else {
        this.openEditInstanceField.status = false;
        this.openEditInstanceField.cancel = false;
      }
    },
    // on edit field code
    async regenerateList() {
      await this.fetchInstances();
      this.generateCalendarColumns();
    },
    generateCalendarColumns() {
      const calendarField = this.object.view.listFields.find(c => c.isCalendar);
      const calendarColumns = [];
      const startDate = this.calendarDate ?? dayjs();
      const year = startDate.year();
      const month = startDate.month() + 1;
      const daysInMonth = startDate.daysInMonth();
      const daysArray = [...Array(daysInMonth).keys()].map(i => i + 1);
      daysArray.forEach((day, index) => {
        const currentDate = new Date(year, month, day);
        const dayOfWeek = currentDate.getDay();
        calendarColumns.push({
          title: day,
          isCalendar: true,
          key: `date-${year}-${month}-${day >= 1 && day <= 9 ? `0${day}` : String(day)}`,
          dateValue: currentDate.toISOString().split('T')[0],
          dateIsWeekend: dayOfWeek === 0 || dayOfWeek === 6,
          dateDay: day,
          dateDayName: currentDate.toLocaleDateString('ro-RO', { weekday: 'short' }),
          align: 'center',
          width: 60,
          children: [
            {
              title: currentDate.toLocaleDateString('ro-RO', { weekday: 'short' }).toUpperCase()[0],
              isCalendar: true,
              key: `calendar-date-${year}-${month}-${day >= 1 && day <= 9 ? `0${day}` : String(day)}`,
              dataIndex: `_calendar_${year}-${month}-${day >= 1 && day <= 9 ? `0${day}` : String(day)}`,
              align: 'center',
              width: 60,
              customCell: (record, rowIndex, column) => ({
                onClick: () => {
                  this.cellResetDbClick(record, rowIndex, column);
                  if (!calendarField.editOnDbClick) {
                    this.cellCalendarClick(record, rowIndex, column);
                  }
                },
                onDblclick: () => {
                  if (calendarField.editOnDbClick) {
                    this.cellCalendarDbClick(record, rowIndex, column);
                  }
                },
                'data-instance-id': calendarField.editOnDbClick ? record._id : null,
                'data-row-index': calendarField.editOnDbClick ? rowIndex : null,
                'data-column-index': calendarField.editOnDbClick ? index : null,
                tabindex: calendarField.editOnDbClick ? `${rowIndex}${index + 1}` : 0,
                class: [
                  this.openEditInstanceField?.status &&
                  this.openEditInstanceField?.column?.key === column.key &&
                  this.openEditInstanceField?.rowIndex === rowIndex
                    ? 'edit-field-open'
                    : '',
                  calendarField.editOnDbClick ? `tab-${rowIndex}${index + 1}` : '',
                  calendarField.editOnDbClick ? 'edit-field' : '',
                  'column-class',
                ],
                width: 60,
              }),
            },
          ],
        });
      });
      return calendarColumns;
    },
    cellCalendarClick(record, rowIndex, column) {
      const instanceId = record[`_instance${column.dataIndex}`] ?? null;
      if (instanceId) {
        const instance = (this.instances?.instanceList ?? []).find(item => item._id === instanceId);
        this.rowClick(instance, rowIndex, column, 'edit');
      } else {
        // add
        const calendar = this.object.view.listFields.find(item => item.show && item.isCalendar);
        if (calendar.calendarSettings.dateFieldId) {
          // mapped fieldId to value in the instance
          const dateFormat = extractDateFormat(
            Array.isArray(record[calendar.calendarSettings.dateFieldId])
              ? record[calendar.calendarSettings.dateFieldId][0]
              : record[calendar.calendarSettings.dateFieldId]
          );
          const dateFieldId = calendar.calendarSettings.dateFieldIdMappedFieldId ?? calendar.calendarSettings.dateFieldId;
          this.additionalFilterConditions[dateFieldId] = dayjs(column.dataIndex.replace(/^_calendar_/, '')).format(dateFormat);
        }
        if (calendar.calendarSettings.groupByFieldId) {
          const groupByFieldId = calendar.calendarSettings.groupByFieldIdMappedFieldId ?? calendar.calendarSettings.groupByFieldId;
          this.additionalFilterConditions[groupByFieldId] = Array.isArray(record[calendar.calendarSettings.groupByFieldId])
            ? record[calendar.calendarSettings.groupByFieldId][0]
            : record[calendar.calendarSettings.groupByFieldId];
        }
        this.openCloseModal(`createNew${this.loadMoreView ? '-loadMoreView' : ''}`, true);
      }
    },
    removeRowsSelected(index) {
      this.rowsSelected.splice(index, 1);
    },
    pushRowsSelected(row) {
      this.rowsSelected.push(row);
    },
    spliceRowsSelected(index, count) {
      this.rowsSelected.splice(index, count);
    },
    generatePDF() {
      NewTemplatesMutations.SET_DOWNLOAD_PDF(this.object.view.default?.showDownloadPDF ?? false, true);
    },
    generateTableHeaderCell(col) {
      let tableCell = {};
      if (col.attachedField) {
        const configuredColumn = this.dataColumns.find(dc => dc.key === col.attachedField);
        tableCell = _.cloneDeep(configuredColumn);
        if (col?.displayText && col?.displayText?.length) {
          tableCell.title = col.displayText;
        }
      } else {
        tableCell.title = col.displayText;
      }

      tableCell.children = [];

      if (col.headerColumns.length) {
        col.headerColumns.forEach(subCol => {
          tableCell.children.push(this.generateTableHeaderCell(subCol));
        });
      }

      if (!tableCell.children.length) {
        delete tableCell.children;
      }

      return tableCell;
    },

    tableRowClick(data) {
      const calendar = this.object.view.listFields.find(item => item.show && item.isCalendar);
      if (calendar) {
        return;
      }
      this.rowClick(data.row, data.index, data.column, data.actionType);
    },
    tableOpenLinkToReferenceInstance(column, record, index) {
      this.openLinkToReferenceInstance(column, record, index);
    },
    tableFetchInstances(pagination, filters, sorter) {
      this.fetchInstances(pagination, filters, sorter);
    },

    setFixedInstancesIfLoadMore() {
      this.fixedInstances = [];
      this.dataList.forEach(instance => {
        this.fixedInstances.push(instance);
      });
    },
    openLinkToReferenceInstance(column, record, index) {
      const props = String(column.key).split('.');
      this.openCloseModal('linkToReferenceInstance', true);

      if (props[0] === 'owner') {
        return;
      }

      let value = record[`${props[0]}-original-value`];
      let id;

      if (Array.isArray(value)) {
        value = value.sort();
        id = value[index];
      } else {
        id = parseInt(value, 10);
      }

      this.linkToReferenceInstanceInstanceId = id;
      this.linkToReferenceInstanceBlueprintId = column.blueprintId;
      this.linkToReferenceInstanceBlueprintName = column.blueprintName;
    },
    openCloseModal(name, status, reset = false) {
      NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`${name}-${this.object._id}`, status, reset);
      if (!status || reset) {
        this.loadDetailsPopupInstance = null;
        this.linkToReferenceInstanceBlueprintId = null;
        this.linkToReferenceInstanceInstanceId = null;
        this.linkToReferenceInstanceBlueprintName = '';
      }
    },

    isOpenModal(name) {
      return NewTemplatesGetters.getIsOpenModal(`${name}-${this.object._id}`);
    },

    async paginateList(page) {
      return this.fetchInstances({ current: page }, null, null);
    },
    async fetchInstances(pagination, filters, sorter) {
      let page = this.object.view?.startPage ?? 1;
      if (pagination?.current) {
        page = pagination.current;
      }

      const limit = this.object.view?.itemsPerPage ?? 10;
      const order = Array.isArray(this.object.view.default.order) ? this.object.view.default.order : [this.object.view.default.order];
      const convertOwnerIdToOwnerDetails = this.object.view?.default?.convertOwnerIdToOwnerDetails ?? false;

      let query = NewTemplatesGetters.getInstanceListQueryParams(this.object._id, this.object.blueprintId);

      query = {
        limit,
        order,
        convertOwnerIdToOwnerDetails,

        ...query,

        page,
        objectId: this.object._id,

        filterConditions: this.object.view?.filters ?? [],
      };
      if (this.calendarDate) {
        query.calendarDate = this.calendarDate.format('YYYY-MM');
      }
      if (sorter?.field) {
        query.sortFieldId = sorter.field;
      }
      if (sorter?.order) {
        query.sortOrder = sorter.order;
      }

      if (filters) {
        query.filters = filters;
      }

      await NewTemplatesActions.fetchInstanceList(this.object._id, this.object.blueprintId, query);
    },

    rowClick(row, index, column, actionType) {
      if (this.object.clickItem.open) {
        const { templateId } = this.object.clickItem;
        const target = this.object.clickItem.openTarget;
        const passFieldId = this.object.clickItem?.passFieldId ?? null;

        let instanceId = row._id;
        //
        const instance = this.instances?.instanceList.find(item => item._id === row._id);
        if (passFieldId) {
          const field = this.blueprint.fields.find(item => item._id === passFieldId);
          if (field && field.isRequired && instance[passFieldId]) {
            instanceId = instance[passFieldId];
          }
        }

        if (actionType === 'view') {
          if (target === 'template') {
            const params = {
              viewType: 'open',
              templateId,
              instanceId,
            };
            if (this.$route.params.menuItemId) {
              params.menuItemId = this.$route.params.menuItemId;
            }
            this.$router
              .push({
                name: 'templateDataSystemListOpenTemplate',
                params,
                query: { mode: 'view' },
              })
              .catch(() => {});

            this.openCloseModal('details', false, true);
          } else {
            this.loadInstance(row._id);
            this.openCloseModal('textDetails', true);
          }
          return;
        }

        if (target === 'popup' && actionType !== 'view') {
          // open modal
          this.openCloseModal('details', true);

          this.loadDetailsPopupInstance = this.instances?.instanceList.find(item => item._id === row._id);
        } else if (target === 'template') {
          const params = {
            viewType: 'open',
            templateId,
            instanceId,
          };
          if (this.$route.params.menuItemId) {
            params.menuItemId = this.$route.params.menuItemId;
          }
          this.$router
            .push({
              name: 'templateDataSystemListOpenTemplate',
              params,
            })
            .catch(() => {});

          this.openCloseModal('details', false, true);
        } else if (target === 'self') {
          const params = { viewType: 'view', instanceId };

          if (this.$route.params.templateId) {
            params.templateId = this.$route.params.templateId;
          }
          if (this.$route.params.menuItemId) {
            params.menuItemId = this.$route.params.menuItemId;
          }

          this.$router
            .push({
              name: 'templateDataSystemListOpenTemplate',
              params,
            })
            .catch(() => {});

          this.openCloseModal('details', false, true);
        }
      }
    },
    async loadInstance(instanceId) {
      let instance = null;
      if (this.object.blueprintId) {
        if (this.propsInstance) {
          instance = this.propsInstance;
        } else {
          instance = NewTemplatesGetters.getInstance(this.object.blueprintId, instanceId);
        }
        if (!instance) {
          instance = await NewTemplatesActions.fetchInstance(this.object._id, this.object.blueprintId, instanceId);
        }
      }
      this.loadDetailsPopupInstance = instance;
    },
    createPagination(current, pageSize, total) {
      if (pageSize < total && this.object.view.loadMore) {
        this.loadMore = true;
        this.popupPagination = {
          current,
          pageSize,
          total,
        };
      } else {
        this.loadMore = false;
        this.popupPagination = false;
      }

      if (pageSize >= total || this.object.view.loadMore) {
        this.pagination = false;
      } else {
        this.pagination = {
          current,
          pageSize,
          total,
        };
      }
    },
    renderHtmlButtonClicked(data) {
      this.rowClick(data);
    },
    clearSelections() {
      this.rowsSelected = [];
      this.clearAllApplied = true;
    },
    updateRowsSelected(data) {
      this.rowsSelected = data;
    },

    adminCheckboxActions(value) {
      if (value === 'viewGlobal') {
        this.selectAllFiltered();
      }
    },
    async saveAdminChanges() {
      if (this.adminViewMode === 'viewGlobal') {
        const removed = this.rowsSelectedOnInit.filter(({ _id: id1 }) => !this.rowsSelected.some(({ _id: id2 }) => id2 === id1));
        removed.forEach(item => {
          item._global = false;
        });
        const added = this.rowsSelected.filter(({ _id: id1 }) => !this.rowsSelectedOnInit.some(({ _id: id2 }) => id2 === id1));
        added.forEach(item => {
          item._global = true;
        });
        this.rowsSelectedOnInit = _.cloneDeep(this.rowsSelected);
        if (added.length) {
          await this.saveGlobal(added, true);
        }
        if (removed.length) {
          await this.saveGlobal(removed, false);
        }
      }
    },
    async saveGlobal(instanceDataList, state) {
      this.isLoading = true;
      let selectedOwnerRoleId = null;
      if (!(this.userRolesWhichCanCreate.length === 1)) {
        message.warning('You must select an Owner Role.');
        return;
      }
      selectedOwnerRoleId = this.userRolesWhichCanCreate[0]._id;

      const idList = instanceDataList.map(instance => instance._id);
      const data = {};
      data._global = state ?? false;
      const response = await NewTemplatesActions.updateInstances(this.object._id, this.object.blueprintId, idList, data, selectedOwnerRoleId, []);

      if (response?.error) {
        message.warning(response.message);
      }
      message.success('Saved successfully.');
      this.isLoading = false;
    },

    async selectAllFiltered() {
      this.isLoading = true;
      const query = _.cloneDeep(NewTemplatesGetters.getLastQuery());
      query.limit = null;

      const filterConditions = await FilterConditions(query?.filterConditions ?? []);
      const queryStripParams = query;
      delete queryStripParams.filterConditions;
      delete queryStripParams.templateId;
      const fieldsToSearch = [];
      fieldsToSearch.push('_global');

      queryStripParams.instanceFieldList = fieldsToSearch;
      const { instanceList } = await instanceApi.getAll(this.blueprint._id, queryStripParams, filterConditions);
      this.updateRowsSelected(instanceList.filter(item => item._global === true)); // check rows
      this.rowsSelectedOnInit = _.cloneDeep(instanceList.filter(item => item._global === true)); // used for at save of unchecked checkbox
      this.isLoading = false;
    },
    dataColumnLastChild(item) {
      let children = [];
      item.children.forEach(child => {
        if (child.children) {
          children = children.concat(this.dataColumnLastChild(child));
        } else {
          children.push(child.key);
        }
      });
      return children;
    },
    exportXls(templateId, blueprintId) {
      const dataColumns = this.object.view?.listFieldsCustomHeader?.enabled ? this.customDataColumns : this.dataColumns;
      let keyDataColumn = [];
      dataColumns.forEach(item => {
        if (item.key !== 'action') {
          if (item.children) {
            keyDataColumn = keyDataColumn.concat(this.dataColumnLastChild(item));
          } else {
            keyDataColumn.push(item.key);
          }
        }
      });
      NewTemplatesMutations.SET_DOWNLOAD_XLSX(
        true,
        [templateId],
        [blueprintId],
        [keyDataColumn],
        [this.object?.view?.default?.customTitle?.length ? this.object?.view?.default?.customTitle : this.blueprint?.name]
      );
      this.$emit('exportXls', templateId, blueprintId);
    },
  },
};
</script>

<style></style>
