import { UserGetters } from '@userManagement/user.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { isEmpty, removeDiacritics, slugify } from '@/core/utils/string-manipulation';
import moment from 'moment/moment';
import doT from 'dot';

// eslint-disable-next-line import/no-cycle
import { NewTemplatesActions, NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { instanceApi } from '@dataSystem/api';

async function getListData(objectId, blueprint, settings) {
  const dateFormat = isEmpty(settings?.dateFormat) ? 'DD-MM-YYYY, hh:mm' : settings?.dateFormat;

  const listData = {};
  const instanceList = NewTemplatesGetters.getInstanceList(objectId, blueprint._id);

  if (instanceList) {
    const instances = await Promise.all(
      instanceList.instanceList.map(async item => {
        let data = {};

        await Promise.all(
          Object.entries(item).map(async ([key, value]) => {
            let localValue = value;
            if (key === '_created_at' && dateFormat) {
              const date = moment(new Date(localValue));
              if (date.isValid()) {
                localValue = date.format(dateFormat);
              }
            }

            // edit date
            const field = blueprint.fields.find(fieldItem => fieldItem._id.toString() === key.toString());
            // if (field && field.structure.type === 'date') {
            //   localValue = moment(new Date(localValue)).format(field.structure.options.format);
            // }

            if (field) {
              data = {
                ...data,
                [slugify(field?.label ?? key)]: localValue,
              };
            } else {
              data = { ...data, [key]: localValue };
            }
          })
        );

        return data;
      })
    );

    listData[slugify(blueprint.name)] = {
      list: instances,
      totalCount: instanceList?.totalCount ?? 0,
    };
  }

  return listData;
}

async function getItemData(objectId, blueprint, instance, settings) {
  let itemData = {};

  if (instance) {
    const dateFormat = isEmpty(settings?.dateFormat) ? 'DD-MM-YYYY, hh:mm' : settings?.dateFormat;

    const blueprintName = slugify(blueprint.name);

    //
    await Promise.all(
      Object.entries(instance).map(async ([fieldId, value]) => {
        let localValue = value;
        const field = blueprint.fields.find(item => item._id.toString() === fieldId.toString());
        if (field || ['owner', '_created_at'].includes(fieldId)) {
          const fieldName = field ? slugify(field.label) : fieldId;
          if (fieldId === '_created_at' && dateFormat) {
            const date = moment(new Date(localValue));
            if (date.isValid()) {
              localValue = date.format(dateFormat);
            }
          }

          // edit date
          /* if (field && field.structure.type === 'date') {
              localValue = (moment(new Date(localValue))).format(field.structure.options.format);
          } */

          itemData = {
            [blueprintName]: {
              ...(itemData[blueprintName] ?? {}),
              [fieldName]: localValue,
            },
          };
        }
      })
    );
  }

  return itemData;
}

export const DynamicTemplateData = async (objectId, blueprintId, contentType, settings, replaceDiacritics) => {
  const user = UserGetters.getUser();
  const date = moment(new Date());

  const idToLabel = settings?.idToLabel ?? false;
  const fieldReferenceData = settings?.fieldReferenceData ?? false;
  const excludeFieldMultipleReferenceData = settings?.excludeFieldMultipleReferenceData ?? false;

  let data = {
    user: { ...(user ?? {}) },
  };

  const tenantAppSubtenant = TenantsGetters.getTenantAppSubtenant();

  if (tenantAppSubtenant?.subtenant) {
    tenantAppSubtenant.subtenant.data = await instanceApi.getSubtenantData(tenantAppSubtenant?.subtenant._id);
  }

  data = {
    ...data,
    global: { ...tenantAppSubtenant },
    env: { VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL },
  };

  data.currentDate = date.format('DD.MM.YYYY');

  let blueprint = NewTemplatesGetters.getBlueprint(blueprintId);
  if (!blueprint) {
    await NewTemplatesActions.fetchBlueprint([blueprintId]);
  }
  blueprint = NewTemplatesGetters.getBlueprint(blueprintId);

  if (blueprint) {
    // list
    if (contentType === 'list' && blueprint) {
      const listData = await getListData(objectId, blueprint, settings);
      data = { ...data, ...listData };
    }
  }

  // additional instances opened from list
  const openInstances = NewTemplatesGetters.getAllInstanceByBlueprint();

  await Promise.all(
    Object.entries(openInstances).map(async ([_blueprintId, instance]) => {
      // load with referenceData
      let localInstance = instance;
      if (fieldReferenceData && !localInstance._allReferenceData) {
        const instanceData = await instanceApi.getOne(_blueprintId, localInstance._id, {
          idToLabel,
          fieldReferenceData,
          excludeFieldMultipleReferenceData,
        });
        if (instanceData) {
          localInstance = instanceData;
        }
      }
      // load with referenceData
      blueprint = NewTemplatesGetters.getBlueprint(_blueprintId);
      if (!blueprint) {
        await NewTemplatesActions.fetchBlueprint([_blueprintId]);
      }
      blueprint = NewTemplatesGetters.getBlueprint(_blueprintId);

      if (blueprint) {
        const blueprintName = slugify(blueprint.name);
        if (!data[blueprintName]) {
          const itemData = await getItemData(objectId, blueprint, localInstance, settings);
          if (replaceDiacritics) {
            Object.keys(itemData).forEach(prop => {
              if (typeof itemData[prop] === 'object') {
                Object.entries(itemData[prop]).forEach(([key, value]) => {
                  if (typeof value === 'string') {
                    itemData[prop][key] = removeDiacritics(value);
                  } else if (Array.isArray(value)) {
                    itemData[prop][key] = value.map(arrVal => removeDiacritics(arrVal));
                  }
                });
              }
            });
          }
          data = { ...data, ...itemData };
        }
      }
    })
  );
  return data;
};

export const DynamicTemplate = async (html, objectId, blueprintId, contentType, settings, replaceDiacritics) => {
  const data = await DynamicTemplateData(objectId, blueprintId, contentType, settings, replaceDiacritics);
  doT.templateSettings.varname = 'token';
  const tempFn = await doT.template(html);
  return tempFn(data);
};
