import Account from './views/Account/Account.vue';
import PasswordReset from './views/PasswordReset/PasswordReset.vue';
import PasswordResetConfirm from './views/PasswordResetConfirm/PasswordResetConfirm.vue';

const accountRoutes = [
  {
    path: '/',
    name: 'Account',
    meta: {
      menuTitle: 'Account',
    },
    component: Account,
  },
  {
    path: '/password/reset/:email?',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true,
    meta: {
      isPublicPage: true,
    },
  },
  {
    path: '/password/reset/confirm/:resetToken',
    name: 'PasswordResetConfirm',
    props: true,
    component: PasswordResetConfirm,
    meta: {
      isPublicPage: true,
    },
  },
];

export default accountRoutes;
