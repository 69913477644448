<template>
  <CreateFileMapper
    v-if="openedModalForCreateFileMapper"
    :opened="openedModalForCreateFileMapper"
    :fieldMapperId="fieldMapperId"
    @updateFileMapperList="updateFileMapperList"
    @cancelModal="toggleModalForCreateFileMapper"
  />
  <AddEditFieldToFileMapper
    v-if="openedModalForAddFieldToFileMapper"
    :opened="openedModalForAddFieldToFileMapper"
    :selectedFileMapper="selectedFileMapper"
    :selectedFieldToEdit="selectedFieldToEdit"
    @cancelModal="toggleModalForAddEditFieldToFileMapper"
  />

  <h4 class="mt-3">File Mappers</h4>
  <a-select
    style="width: 100%"
    @change="selectFileMapper"
    :value="selectedFileMapper._id == null ? undefined : selectedFileMapper._id"
    placeholder="Select provider output"
  >
    <a-select-option v-for="fileMapper in fileMapperList" :key="fileMapper._id">
      {{ fileMapper.name }}
    </a-select-option>
  </a-select>

  <div class="d-flex flex-row align-items-start">
    <a-button class="mt-3 border-primary text-primary" @click="toggleModalForCreateFileMapper">Create File Mapper</a-button>
    <a-button v-if="JSON.stringify(selectedFileMapper) !== '{}'" class="mt-3 mx-2 border-danger text-danger" @click="deleteFileMapper">
      Delete
    </a-button>
  </div>

  <div v-if="JSON.stringify(selectedFileMapper) !== '{}'">
    <h4 class="mt-3">File Fields</h4>
    <transition-group name="list" tag="div">
      <div v-if="selectedFileMapper?.fields?.length === 0">
        <a-alert data-cy="superAdminAlertTenantListEmpty" banner message="No fields found." />
      </div>
      <div class="d-flex flex-row gap-2 w-100" v-for="field in selectedFileMapper.fields" :key="field._id">
        <drag :data="field" @cut="remove(n)" :go-back="true" class="w-100 mx-2">
          <div class="saas_card hoverable_drag mb-10 from-sourse">
            <div class="row-contents">
              <div class="group-text-container">
                <div class="group-text">
                  <span class="title">
                    <MoreOutlined />
                    <MoreOutlined style="margin-left: -11px" />
                    {{ field.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </drag>
        <a-button @click="editField(field)">
          <EditOutlined />
        </a-button>
      </div>
    </transition-group>

    <a-button class="mt-3" @click="toggleModalForAddEditFieldToFileMapper">Add a Field</a-button>
  </div>
</template>

<script>
import { remove } from 'lodash/array';
import { Drag } from 'vue-easy-dnd';
import { notification } from 'ant-design-vue';
import { FileMapperActions } from '@fieldMapper/shared/fileMapper.store';
import { MoreOutlined, EditOutlined } from '@ant-design/icons-vue';
import CreateFileMapper from './components/CreateFileMapper.vue';
import AddEditFieldToFileMapper from './components/AddEditFieldToFileMapper.vue';

export default {
  components: { Drag, AddEditFieldToFileMapper, CreateFileMapper, MoreOutlined, EditOutlined },
  emits: ['selectFileMapper', 'updateFileMapperList'],
  props: ['selectedFileMapper', 'fileMapperList', 'fieldMapperId'],
  data() {
    return {
      loading: false,
      openedModalForCreateFileMapper: false,
      openedModalForAddFieldToFileMapper: false,

      selectedFieldToEdit: null,
    };
  },
  computed: {},
  methods: {
    remove,
    toggleModalForCreateFileMapper() {
      this.openedModalForCreateFileMapper = !this.openedModalForCreateFileMapper;
    },
    toggleModalForAddEditFieldToFileMapper() {
      this.openedModalForAddFieldToFileMapper = !this.openedModalForAddFieldToFileMapper;

      if (!this.openedModalForAddFieldToFileMapper) {
        this.selectedFieldToEdit = null;
      }
    },
    selectFileMapper(value) {
      this.$emit('selectFileMapper', value);
    },
    updateFileMapperList() {
      this.$emit('updateFileMapperList');
    },
    async deleteFileMapper() {
      notification.success({
        message: 'Saved',
        description: `File Mapper ${this.selectedFileMapper.name} deleted with success.`,
      });
      await FileMapperActions.deleteOneFileMapper(this.selectedFileMapper._id);
      this.$emit('updateFileMapperList');
      this.$emit('selectFileMapper', null);
    },
    editField(field) {
      this.selectedFieldToEdit = field;
      this.toggleModalForAddEditFieldToFileMapper();
    },
  },
};
</script>

<style></style>
