<template>
  <div>
    <div style="margin-bottom: 20px">
      <a-button size="small" @click="openTokens = true" type="primary">Tokens to use</a-button>
    </div>

    <span style="padding-right: 10px">Send to:</span>
    <a-checkbox-group
      v-model:checked="localEvent.sendEmail.toType"
      :options="types"
      @change="
        value => {
          localEvent.sendEmail.toType = value;
        }
      "
    />

    <div v-if="localEvent.sendEmail.toType.includes('role')" style="margin-top: 10px">
      <a-select v-model:value="localEvent.sendEmail.roleId" placeholder="Select a group..." allowClear style="min-width: 200px">
        <a-select-option v-for="role in roles" :key="role._id" :value="role._id">
          {{ role.name }}
        </a-select-option>
      </a-select>
    </div>

    <div v-if="localEvent.sendEmail.toType.includes('emailField')" style="margin-top: 10px">
      <a-select v-model:value="localEvent.sendEmail.emailOrTokenValue" mode="tags" style="width: 100%" placeholder="Emails or Tokens"></a-select>
    </div>

    <a-divider></a-divider>

    <div style="margin-top: 10px">
      <div style="margin-bottom: 10px">
        <a-input v-model:value="localEvent.sendEmail.subject" addon-before="Subject" />
      </div>

      <a-textarea v-model:value="localEvent.sendEmail.message" :rows="7" placeholder="Message..." />
    </div>

    <TokensModal :visible="openTokens" @close="openTokens = false" :blueprintId="selectedBlueprintId" />
  </div>
</template>

<script>
import { roleApplicationApi } from '@roleManagement/api';
import TokensModal from '@core/components/TokensModal';
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderEmailIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  components: {
    TokensModal,
  },
  data() {
    return {
      roles: [],
      openTokens: false,

      sendTo: 'owner',

      types: [
        { label: 'Owner', value: 'owner' },
        { label: 'Owner role', value: 'ownerRole' },
        { label: 'Role', value: 'role' },
        { label: 'Email', value: 'emailField' },
      ],
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  async created() {
    await this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      this.roles = await roleApplicationApi.getAllForApplication();
    },
  },
};
</script>
