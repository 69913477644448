import Register from './views/Register/Register.vue';
import UserFeedback from './views/UserFeedback/UserFeedback.vue';

const userRoutes = [
  {
    path: '/invitation/:invitationToken',
    name: 'Register',
    props: true,
    component: Register,
    meta: { isPublicPage: true },
  },
  {
    path: '/user-feedback',
    name: 'UserFeedback',
    component: UserFeedback,
  },
];

export default userRoutes;
