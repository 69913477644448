// src/socket.js
import io from 'socket.io-client';

export const socket = process.env.VUE_APP_SOCKET_URL ? io(process.env.VUE_APP_SOCKET_URL) : null;

if (socket) {
  socket.on('connect', () => {
    console.log('Connected to socket server with ID:', socket.id);
  });

  socket.on('connect_error', error => {
    console.error('Connection error:', error);
  });

  socket.on('reconnect_attempt', () => {
    console.log('Attempting to reconnect...');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from socket server');
  });
}
