import { UserGetters, UserActions } from './user.store';

export const authGuard = async (to, from, next) => {
  if (to.meta && to.meta.isPublicPage) {
    return next();
  }

  if (!UserGetters.getUser()) {
    try {
      await UserActions.silentLogin();
      if (!UserGetters.getUser()) {
        return next('/auth/login');
      }
      return next();
    } catch (error) {
      return next('/auth/login');
    }
  }

  return next();
};
