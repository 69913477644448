import { ref } from 'vue';

const currentLanguage = ref('ro');
const translations = ref({});

const sprintf = function (str, ...args) {
  let i = 0;
  return str.replace(/%s/g, () => {
    const arg = args[i];
    i += 1;
    return arg;
  });
};

const loadLanguage = async () => {
  let lang = currentLanguage.value;
  try {
    if (localStorage.getItem('lang')) {
      lang = localStorage.getItem('lang');
    }
    const langModule = await import(`./assets/lang/${lang}.json`);
    translations.value = langModule.default;
    currentLanguage.value = lang;
  } catch (error) {
    console.error(`Could not load language file for ${lang}:`, error);
  }
};

const changeLanguage = async lang => {
  try {
    const langModule = await import(`./assets/lang/${lang}.json`);
    translations.value = langModule.default;
    currentLanguage.value = lang;
    localStorage.setItem('lang', lang);
  } catch (error) {
    console.error(`Could not load language file for ${lang}:`, error);
  }
};

const __lang = (key, args = []) => {
  if (translations.value[key]) {
    return sprintf(translations.value[key], ...args);
  }
  return sprintf(key, ...args);
};

export { __lang, changeLanguage, loadLanguage };
