import axios from 'axios';

const postOne = async (blueprintId, ownerRoleId, instanceInput, reCaptchaToken, subtenantId) => {
  const response = await axios.post(
    `/public/data-system/instances?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}&reCaptchaToken=${reCaptchaToken}&subtenantId=${subtenantId}`,
    instanceInput
  );
  return response.data;
};

export default {
  postOne,
};
