<template>
  <div>
    <div>
      <a-checkbox v-model:checked="input.allowImagePopup" class="fieldRule">
        Allow image popup renderer
        <help-icon-popover title="Image popup" content="Allow image to expand in popup" />
      </a-checkbox>
    </div>
    <div>
      <a-checkbox v-model:checked="input.showImages" class="fieldRule">
        Show images
        <help-icon-popover title="Show images" content="Show images minified" />
      </a-checkbox>
    </div>
    <div>
      <a-checkbox v-model:checked="input.showAudioPlayer" class="fieldRule">
        Show audio player (mp3)
        <help-icon-popover title="Show audio player" content="When MP3 file uploaded show listen !" />
      </a-checkbox>
    </div>
    <div>
      <a-checkbox v-model:checked="input.hideDownloadLink" class="fieldRule">
        Hide download link
        <help-icon-popover title="Hide link" content="When is checked, then link is hidden" />
      </a-checkbox>
    </div>
    <div>
      <a-checkbox v-model:checked="input.formatBase64" class="fieldRule">
        Return base64
        <help-icon-popover title="Return upload as base64" content="When is checked, return as base64 format instead of url" />
      </a-checkbox>
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  props: ['field'],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['allowImagePopup', 'showImages', 'showAudioPlayer', 'hideDownloadLink', 'formatBase64'],
  },
};
</script>

<style></style>
