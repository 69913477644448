import { reactive } from 'vue';
// eslint-disable-next-line import/extensions
import { handleNested, objectId } from '@/core/utils/array-manipulation';

import { providerApi, sourcesApi, sourceFieldApi, sourceFieldFilterApi, joinApi } from '../api';

const initialState = {
  dataProviderList: [],
  dataProvider: [],
  joinOutputFields: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getDataProviderList: () => {
    return state.dataProviderList;
  },
  getDataProvider: () => {
    return state.dataProvider;
  },
  getJoinOutputFields: () => {
    return state.joinOutputFields;
  },
};

const Mutations = {
  SET_DATAPROVIDER_LIST: dataProviderList => {
    state.dataProviderList = dataProviderList;
  },
  SET_DATAPROVIDER: dataProvider => {
    state.dataProvider = dataProvider;
  },

  INSERT_DATAPROVIDER: dataProvider => {
    state.dataProviderList.push(dataProvider);
  },
  EDIT_DATAPROVIDER: (providerId, updatedDataProvider) => {
    const statedProvider = handleNested([state.dataProvider], { _id: providerId }, { update: updatedDataProvider });
    if (statedProvider.length) {
      [state.dataProvider] = statedProvider;
    }
  },
  DELETE_DATAPROVIDER: providerId => {
    const statedataProviderList = handleNested(state.dataProviderList, {
      _id: providerId,
    });
    state.dataProviderList = statedataProviderList;
  },

  // SOURCES
  INSERT_SOURCE: newSource => {
    const statedataProvider = {
      ...state.dataProvider,
      sources: state.dataProvider.sources.map(source => {
        if (source.new) {
          return newSource;
        }
        return source;
      }),
    };
    state.dataProvider = statedataProvider;
  },
  EDIT_SOURCE: (sourceId, updateSource) => {
    const statedataProvider = {
      ...state.dataProvider,
      sources: state.dataProvider.sources.map(source => {
        if (source._id === sourceId) {
          return updateSource;
        }
        return source;
      }),
    };
    state.dataProvider = statedataProvider;
  },
  DELETE_SOURCE: sourceId => {
    const statedataProvider = {
      ...state.dataProvider,
      sources: state.dataProvider.sources.filter(source => {
        return sourceId === 'new' ? !source.new : source._id !== sourceId;
      }),
    };
    state.dataProvider = statedataProvider;
  },

  // SOURCES FIELDS
  SET_UPDATE_SOURCE_FIELD: (providerId, sourceId, fieldId, updatedSourceField) => {
    const statedataProvider = handleNested(
      [state.dataProvider],
      { _id: providerId, sources: { _id: sourceId, fields: { _id: fieldId } } },
      { update: updatedSourceField }
    );
    if (statedataProvider.length) {
      [state.dataProvider] = statedataProvider;
    }
    return statedataProvider;
  },
  // FILTERS
  INSERT_EDIT_SOURCE_FIELD_FILTERS: (sourceId, fieldId, filters) => {
    state.dataProvider.sources.find(s => s._id === sourceId).fields.find(f => f._id === fieldId).filters = filters;
  },
  DELETE_SOURCE_FIELD_FILTER: (providerId, sourceId, fieldId, filterId) => {
    const statedataProvider = handleNested([state.dataProvider], {
      _id: providerId,
      sources: {
        _id: sourceId,
        fields: { _id: fieldId, filters: { _id: filterId } },
      },
    });
    if (statedataProvider.length) {
      [state.dataProvider] = statedataProvider;
    }
  },

  // JOINS
  SET_JOINS_OUTPUT_FIELDS: outputFields => {
    state.joinOutputFields = outputFields;
  },
  EDIT_JOIN: joinData => {
    state.dataProvider.aggregations.push(joinData);
  },
  DELETE_ALL: () => {
    state.dataProvider.aggregations = [];
    state.dataProvider.primarySourceId = null;
    state.dataProvider.isEnabled = false;
  },
};

const Actions = {
  fetchDataProviderList: async () => {
    const dataProviderList = await providerApi.getAll();
    Mutations.SET_DATAPROVIDER_LIST(dataProviderList);
  },
  fetchOneDataProvider: async dataProviderId => {
    const dataProvider = await providerApi.getOne(dataProviderId);
    if (dataProvider) {
      Mutations.SET_DATAPROVIDER(dataProvider);
    }
  },

  createOneDataProvider: async (dataProviderData, type) => {
    const newDataProvider = await providerApi.postOne(dataProviderData, type);
    Mutations.INSERT_DATAPROVIDER(newDataProvider);
  },
  editOneDataProvider: async (dataProviderId, dataProviderForm) => {
    const updatedProvider = await providerApi.putOne(dataProviderId, dataProviderForm);
    Mutations.EDIT_DATAPROVIDER(dataProviderId, updatedProvider);
  },
  deleteOneDataProvider: async dataProviderId => {
    await providerApi.deleteOne(dataProviderId);
    Mutations.DELETE_DATAPROVIDER(dataProviderId);
  },

  // SOURCES
  createOneSource: async (dataProviderId, newSourceData) => {
    const newSource = await sourcesApi.postOne(dataProviderId, newSourceData);
    if (newSource.error) {
      return {
        connectionDetail: {
          error: {
            message: newSource.response.data.errors.length ? newSource.response.data.errors[0] : 'Unexpected error',
          },
          success: false,
        },
      };
    }
    if (newSource.connectionDetail.success) {
      Mutations.INSERT_SOURCE(newSource);
    }

    return newSource;
  },
  deleteOneSource: async (providerId, sourceId) => {
    if (sourceId !== 'new') {
      await sourcesApi.deleteOne(providerId, sourceId);
    }
    Mutations.DELETE_SOURCE(sourceId);
  },

  // Fields
  updateSourceField: async (providerId, sourceId, fieldId, updateInput) => {
    const updatedSourceField = await sourceFieldApi.putOne(providerId, sourceId, fieldId, updateInput);
    if (Mutations.SET_UPDATE_SOURCE_FIELD(providerId, sourceId, fieldId, updatedSourceField)?.length) {
      await Actions.calculateJoinsFields();
    }
  },

  // Filters
  createUpdateFieldFilter: async (providerId, sourceId, fieldId, createInput) => {
    const filters = await sourceFieldFilterApi.putMeny(providerId, sourceId, fieldId, createInput);
    Mutations.INSERT_EDIT_SOURCE_FIELD_FILTERS(sourceId, fieldId, filters);
  },
  deleteOneSourceFieldFilter: async (providerId, sourceId, fieldId, filterId) => {
    await sourceFieldFilterApi.deleteOne(providerId, sourceId, fieldId, filterId);
    Mutations.DELETE_SOURCE_FIELD_FILTER(providerId, sourceId, fieldId, filterId);
  },

  updateOneSource: async (providerId, sourceId, updateInput) => {
    const updatedSource = await sourcesApi.putOne(providerId, sourceId, updateInput);
    if (updatedSource.error) {
      return {
        connectionDetail: {
          error: {
            message: updatedSource.response.data.errors.length ? updatedSource.response.data.errors[0] : 'Unexpected error',
          },
          success: false,
        },
      };
    }
    if (updatedSource.connectionDetail.success) {
      Mutations.EDIT_SOURCE(sourceId, updatedSource);
    }

    return updatedSource;
  },

  testOneSource: async (sourceId, providerId) => {
    const testedSource = await sourcesApi.testOne(sourceId, providerId);
    if (testedSource.error) {
      return {
        connectionDetail: {
          error: {
            message: testedSource.response.data.errors.length ? testedSource.response.data.errors[0] : 'Unexpected error',
          },
          success: false,
        },
      };
    }
    if (testedSource.connectionDetail.success) {
      Mutations.EDIT_SOURCE(sourceId, testedSource);
    }

    return testedSource;
  },

  updateSourceSampleObject: async (providerId, sourceId, sampleObject) => {
    const updatedSource = await sourcesApi.updateSampleObject(providerId, sourceId, sampleObject);
    if (updatedSource.error) {
      return {
        connectionDetail: {
          error: {
            message: updatedSource.response.data.errors.length ? updatedSource.response.data.errors[0] : 'Unexpected error',
          },
          success: false,
        },
      };
    }
    Mutations.EDIT_SOURCE(sourceId, updatedSource);

    return updatedSource;
  },

  updateSourceOptions: async (providerId, sourceId, sampleObject) => {
    const updatedSource = await sourcesApi.updateSourceOptions(providerId, sourceId, sampleObject);
    if (updatedSource.error) {
      return {
        connectionDetail: {
          error: {
            message: updatedSource.response.data.errors.length ? updatedSource.response.data.errors[0] : 'Unexpected error',
          },
          success: false,
        },
      };
    }
    Mutations.EDIT_SOURCE(sourceId, updatedSource);

    return updatedSource;
  },

  updateOneSourceSafely: async (providerId, sourceId, updateInput) => {
    await sourcesApi.putOneSafely(providerId, sourceId, updateInput);
  },

  // JOINS
  createOneJoin: async (providerId, joinData) => {
    if (joinData.first) {
      Mutations.EDIT_JOIN(joinData);
    } else {
      const newJoin = await joinApi.postOne(providerId, joinData);
      Mutations.EDIT_JOIN(newJoin);
    }
    /* const newJoin = await joinApi.postOne(providerId, joinData);
    Mutations.EDIT_JOIN(newJoin); */
    await Actions.calculateJoinsFields();
  },
  deleteAllJoin: async providerId => {
    // if(state.dataProvider.aggregations.filter(j=>!j.first).length){
    await joinApi.deleteAll(providerId);
    // }
    Mutations.DELETE_ALL();
    await Actions.calculateJoinsFields();
  },
  calculateJoinsFields: async () => {
    // add first join for single source
    if (state.dataProvider) {
      if (state.dataProvider.sources.filter(s => s._id === state.dataProvider.primarySourceId).length && !state.dataProvider.aggregations.length) {
        await Actions.createOneJoin(state.dataProvider._id, {
          first: true,
          origin: {
            sourceId: state.dataProvider.primarySourceId,
          },
        });
      }
      // we can delete `primarySourceId` if not found
    }

    const newOutput = [];
    const joins = [];
    // if(state.dataProvider.length){
    for (let i = 0; i < state.dataProvider.sources?.length; i += 1) {
      const source = state.dataProvider.sources[i];
      if (
        Actions.findJoin(state.dataProvider.aggregations, source._id, 'origin') ||
        Actions.findJoin(state.dataProvider.aggregations, source._id, 'destination')
      ) {
        source.fields.forEach(field => {
          if (field.isIncludedInOutput) {
            const findJoinedField = Actions.findJoinFields(state.dataProvider.aggregations, source._id, field._id);
            newOutput.push({
              _id: objectId(),
              originalSourceId: source._id,
              originalId: field._id,
              label: field.outputLabel,
              originalLabel: field.originalLabel,
              joined: findJoinedField,
            });
            findJoinedField.forEach(join => {
              joins.push(join);
            });
          }
        });
      }
    }
    const updatedOutput = newOutput.filter(out => {
      return !joins.some(j => j.sourceId === out.originalSourceId && j.sourceFieldId === out.originalId);
    });

    Mutations.SET_JOINS_OUTPUT_FIELDS(updatedOutput);
    // }
  },

  findJoin: (aggregations, sourceId, type) => {
    return aggregations.some(j => j[type] && j[type].sourceId === sourceId);
  },

  findJoinFields: (aggregations, sourceId, sourceFieldId) => {
    const joins = [];
    aggregations.forEach(agg => {
      if (agg.origin.sourceId === sourceId && agg.origin.sourceFieldId === sourceFieldId) {
        joins.push(agg.destination);
      }
    });
    return joins;
  },
};

export const DataProviderGetters = Getters;
export const DataProviderMutations = Mutations;
export const DataProviderActions = Actions;
