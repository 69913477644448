<template>
  <div class="PasswordReset_Container">
    <loading-spinner v-if="isLoading" />
    <a-card v-else-if="isError">
      <p>Reset token is invalid or has expired.</p>
    </a-card>
    <a-card v-else>
      <h1>Set a new password</h1>
      <div style="margin-top: 20px">
        <label class="PasswordReset_Label">E-mail address</label>
        <a-input :value="email" :disabled="true" />
      </div>
      <div style="margin-top: 20px">
        <label class="PasswordReset_Label">New password</label>
        <a-input v-model:value="input.newPassword" type="password" />
      </div>
      <div style="margin-top: 20px">
        <label class="PasswordReset_Label">Confirm new password</label>
        <a-input v-model:value="input.newPasswordConfirm" type="password" />
      </div>
      <div style="margin-top: 25px">
        <a-button @click="onClickChangePassword" type="primary"> <LockOutlined /> Change password </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { LockOutlined } from '@ant-design/icons-vue';
import { userApi, credentialApi } from '@userManagement/api';

import { message } from 'ant-design-vue';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default {
  props: ['resetToken'],
  components: {
    LockOutlined,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      email: null,
      input: {
        newPassword: null,
        newPasswordConfirm: null,
      },
    };
  },
  created() {
    this.fetchEmailByResetToken();
  },
  methods: {
    async fetchEmailByResetToken() {
      this.isLoading = true;
      try {
        this.email = await userApi.getEmailByResetToken(this.resetToken);
      } catch {
        this.isError = true;
      }
      this.isLoading = false;
    },
    async onClickChangePassword() {
      try {
        await credentialApi.patchPasswordResetConfirm(this.email, this.resetToken, this.input.newPassword);
        message.success('Your password has been changed successfully!');
        await router.push({ name: 'Login', params: { email: this.email } });
      } catch {
        message.error('Something went wrong...');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Register {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 100vh;
  }
  &_Label {
    display: block;
    margin-top: 20px;
  }
  &_RegisterButton {
    margin-top: 25px;
    width: 150px;
  }
}
</style>
