<template>
  <a-tabs type="card" v-if="user">
    <a-tab-pane :key="1" tab="General" class="tabsStyle" v-if="!modalObject._id || !isUserSubtenantManager()">
      <p>
        <a-input v-model:value="modalObject.name" placeholder="Name" />
        <span style="font-weight: 500; margin-left: 5px; color: red" v-if="!isSubtenantNameUnique"> Name is already in use. </span>
      </p>
      <p>
        <a-textarea v-model:value="modalObject.description" placeholder="Descriere" data-cy="inputTenantDescription" />
      </p>
      <label>Blueprint date {{ modalObject.name }}:</label>
      <BlueprintSelect
        v-model="modalObject.blueprintId"
        style="min-width: 100%"
        @update:modelValue="
          newValue => {
            modalObject.blueprintId = newValue;
          }
        "
      />
    </a-tab-pane>
    <a-tab-pane :key="2" tab="Email" v-if="modal.type === 'subtenant'" class="tabsStyle">
      <SMTP v-model="modalObject.emailSMTP"></SMTP>
    </a-tab-pane>
  </a-tabs>
  <div style="padding-top: 10px">
    <a-row>
      <a-col :span="12">
        <a-button type="primary" :loading="loading" @click="processForm">
          <template v-if="modal.edit"><EditOutlined /> Edit</template>
          <template v-else><PlusOutlined /> Create</template>
        </a-button>
      </a-col>
      <a-col :span="12" style="text-align: right">
        <a-button key="back" @click="closeModal"> Cancel </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { TenantsActions } from '@tenants/shared/tenants.store';
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';
import { EditOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { SMTP } from '@/apps/tenants/views/TenantList/components/forms/email';
import { UserGetters } from '@/apps/userManagement/user.store';

export default {
  props: ['users', 'tenantId', 'appId', 'modal'],
  emits: ['close', 'closeModal', 'modalUpdate'],
  components: {
    BlueprintSelect,
    EditOutlined,
    PlusOutlined,
    SMTP,
  },
  computed: {
    user: UserGetters.getUser,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      selectedOwnerRoleId: null,
      userRoles: [],
      instance: null,
      instanceList: null,
      instanceId: null,
      selectdUsers: [],
      modalObject: {
        name: '',
        blueprintId: null,
      },
      isSubtenantNameUnique: true,
    };
  },
  mounted() {
    if (this.modal.edit) {
      this.modalObject.name = this.modal.editData.name;
      this.modalObject.description = this.modal.editData.description;
      this.modalObject._id = this.modal.editData._id;
      this.modalObject.blueprintId = this.modal.editData.blueprintId ?? null;
      this.modalObject.emailSMTP = this.modal.editData.emailSMTP ?? null;
    } else {
      this.modalObject.name = null;
      this.modalObject.description = null;
      this.modalObject.blueprintId = null;
      this.modalObject.emailSMTP = null;
    }
  },
  methods: {
    isUserSubtenantManager() {
      return this.modal.editData?.managerUserIdList?.indexOf(this.user._id) !== -1;
    },
    close() {
      this.$emit('close');
    },
    closeModal() {
      this.$emit('closeModal');
    },

    async processForm() {
      const modalUpdate = this.modal;
      if (!this.modalObject.name || this.modalObject.name.trim().length === 0) {
        return;
      }
      this.loading = true;

      try {
        if (this.modal.type === 'subtenant') {
          if (this.modal.edit) {
            await TenantsActions.editOneSubtenant(this.tenantId, this.appId, this.modalObject._id, this.modalObject);
          } else {
            const newSubtenant = await TenantsActions.createOneSubtenant(this.tenantId, this.appId, this.modalObject);
            this.modalObject._id = newSubtenant?._id?.toString() ?? null;
            modalUpdate.editData.slug = newSubtenant?.slug;
            modalUpdate.edit = true;
          }
        }
        if (this.modal.type === 'application') {
          if (this.modal.edit) {
            await TenantsActions.editOneApplication(this.tenantId, this.modalObject._id, this.modalObject);
          } else {
            const newAplication = await TenantsActions.createOneApplication(this.tenantId, this.modalObject);
            this.modalObject._id = newAplication?._id?.toString() ?? null;
            modalUpdate.editData.slug = newAplication?.slug ?? null;
            modalUpdate.edit = true;
          }
        }
        if (this.modal.type === 'tenant') {
          if (this.modal.edit) {
            await TenantsActions.editOneTenant(this.modalObject._id, {
              ...this.modalObject,
            });
          } else {
            const newTenant = await TenantsActions.createOneTenant({
              ...this.modalObject,
            });
            this.modalObject._id = newTenant?._id?.toString() ?? null;
            modalUpdate.editData.slug = newTenant?.slug;
            modalUpdate.edit = true;
          }
        }
        this.$emit('modalUpdate', modalUpdate);

        this.closeModal();
      } catch (error) {
        this.isSubtenantNameUnique = false;
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.tabsStyle {
  padding: 0px 10px 10px 10px;
}
</style>
