import WebhooksManager from './views/WebhooksManager/WebhooksManager.vue';

const webhooksRoutes = [
  {
    path: '/',
    name: 'WebhooksManager',
    meta: {
      menuTitle: 'Webhooks',
    },
    component: WebhooksManager,
  },
];

export default webhooksRoutes;
