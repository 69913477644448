import { reactive } from 'vue';

import { handleNested } from '@/core/utils/array-manipulation';

import { roleSubtenantApi } from '../api';
import { serialize } from '../serializers/serializer';

const initialState = {
  roles: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getUserRoles: () => state.roles,
};

const Mutations = {
  SET_USER_ROLES: roles => {
    state.roles = roles;
  },
  UPDATE_USER_ROLES: (roleId, newRole) => {
    const stateRoleList = handleNested(state.roles, { _id: roleId }, { update: newRole });
    state.roles = stateRoleList;
  },
};

const Actions = {
  fetchForSubtenant: async () => {
    const roles = await roleSubtenantApi.getAllForSubtenant();
    Mutations.SET_USER_ROLES(serialize(roles));
  },
  fetchForUser: async () => {
    const roles = await roleSubtenantApi.getAllForUser();
    Mutations.SET_USER_ROLES(serialize(roles));
  },
  postUsers: async ({ roleId, userIdList }) => {
    const newRole = await roleSubtenantApi.postUsers({ roleId, userIdList });
    Mutations.UPDATE_USER_ROLES(roleId, newRole);
  },
  deleteUser: async ({ roleId, userId }) => {
    const newRole = await roleSubtenantApi.deleteUser({ roleId, userId });
    Mutations.UPDATE_USER_ROLES(roleId, newRole);
  },
};

export const UserRolesGetters = Getters;
export const UserRolesMutations = Mutations;
export const UserRolesActions = Actions;
