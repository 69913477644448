import axios from 'axios';

const postPasswordReset = async email => {
  const response = await axios.post(`/user-management/password-reset`, {
    email,
  });
  return response.data;
};

const patchPasswordResetConfirm = async (email, resetToken, newPassword) => {
  const response = await axios.patch(`/user-management/password-reset-confirm`, {
    email,
    resetToken,
    newPassword,
  });
  return response.data;
};

const patchPasswordChange = async (currentPassword, newPassword) => {
  const response = await axios.patch(`/user-management/password-change`, {
    currentPassword,
    newPassword,
  });
  return response.data;
};

export default {
  postPasswordReset,
  patchPasswordResetConfirm,
  patchPasswordChange,
};
