<template>
  <div class="LoadingSpinner_Wrapper">
    <div class="LoadingSpinner_Center_Element">
      <h2 v-if="title">{{ title }}</h2>
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
};
</script>

<style lang="scss" scoped>
.LoadingSpinner_Wrapper {
  display: block;
  min-height: 32px;
  width: 100%;
  position: relative;
}
.LoadingSpinner_Center_Element {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
h2 {
  transform: translate(-50%, 0);
}
p {
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-weight: 500;
}
</style>
