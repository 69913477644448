<template>
  <div v-if="!isLoading">
    <FieldRuleset />

    <FormSettings :visible="FormSettingsProps.visible" :blueprintId="blueprintId" @close="closeFormSettings" />

    <ConstraintsSettings :visible="formUniqueConstraintsVisible" :blueprintId="blueprintId" @close="closeConstraintsSettings" />

    <a-page-header style="border-bottom: 3px solid rgb(235, 237, 240); padding-top: 0; padding-bottom: 0; margin-top: -10px">
      <template #title>
        <div style="width: 100%">
          <div style="display: inline-flex">
            <div>
              <h1 style="margin: 0; margin-right: 10px; font-size: 1.5rem">
                {{ blueprint.name }}
              </h1>
              <p style="margin: 0; margin-right: 10px; font-size: 1rem; font-weight: 400">
                <span v-if="blueprint.description">
                  {{ blueprint.description }}
                </span>
                <span v-else>No description.</span>
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #extra>
        <a-button-group style="margin-right: 20px; margin-top: 10px">
          <a-button
            :type="isPreviewAndTestMode ? '' : 'primary'"
            size="large"
            @click="
              isPreviewAndTestMode = false;
              setSubtenantSlug(null);
            "
          >
            <EditOutlined /> Edit
          </a-button>
          <a-button
            :type="isPreviewAndTestMode ? 'primary' : ''"
            size="large"
            @click="
              isPreviewAndTestMode = true;
              setSubtenantSlug(subtenantSlug ?? lastSubtenantSlug);
            "
          >
            <EyeOutlined /> Preview & Test
          </a-button>
        </a-button-group>
        <a-button @click="openFormSettings" size="large" style="margin-top: 10px"> <SettingOutlined /> Settings </a-button>
        <a-button @click="openConstraintsSettings" size="large" style="margin-top: 10px"> <ExclamationCircleOutlined /> Unique constraints </a-button>
      </template>
    </a-page-header>

    <div v-if="!isPreviewAndTestMode">
      <div class="leftPanelAbsolute">
        <FieldSelect />
      </div>

      <div style="display: flex; background-color: #f5f5f5; padding: 20px">
        <div class="leftPanelRelative" />
        <div class="rightPanel">
          <FieldList :blueprint="blueprint" />
        </div>
      </div>
    </div>
    <div style="background-color: #f5f5f5; height: 100vh" v-else>
      <div class="leftPanelAbsolute">
        <a-card style="width: 300px; float: left" v-if="subtenants">
          <span style="padding-left: 10px">Subtenant:</span>
          <a-select :value="subtenantSlug" @change="value => setSubtenantSlug(value)" style="width: 100%">
            <template v-for="subtenant of subtenants" :key="subtenant._id">
              <a-select-option :value="subtenant.slug">{{ subtenant.name }}</a-select-option>
            </template>
          </a-select>
        </a-card>
      </div>
      <div style="display: flex; background-color: #f5f5f5; padding: 0px 20px 20px 20px">
        <div class="leftPanelRelative" />
        <a-card style="width: 800px; margin-left: auto; margin-right: auto" v-if="lastSubtenantSlug">
          <FormRenderer :blueprintId="blueprintId" :preview="true" />
        </a-card>
      </div>
    </div>
  </div>
  <loading-spinner v-else />
</template>

<script>
import { FormRenderer } from '@dataSystem/components/FormRenderer';
import { EditOutlined, ExclamationCircleOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { TenantsActions, TenantsGetters } from '@/apps/tenants/shared/tenants.store';
import { SlugGetters, SlugSetters } from '@/slug.store';
import FieldRuleset from './components/FieldRuleset/index.vue';

import { FormBuilderGetters, FormBuilderActions } from './formBuilder.store';
import FieldSelect from './components/FieldSelect.vue';
import FieldList from './components/FieldList.vue';
import { FormSettings } from './components/FormSettings';
import { ConstraintsSettings } from './components/ConstraintsSettings';

export default {
  data() {
    return {
      isLoading: true,
      isPreviewAndTestMode: false,
      isSaveMessageVisible: false,
      FormSettingsProps: {
        visible: false,
      },
      formUniqueConstraintsVisible: false,
      blueprintId: null,
      lastSubtenantSlug: null,
    };
  },
  components: {
    FieldRuleset,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    SettingOutlined,
    FieldSelect,
    FieldList,
    FormRenderer,
    FormSettings,
    ConstraintsSettings,
  },
  // props: ["blueprintId"],
  computed: {
    blueprint: FormBuilderGetters.getBlueprint,
    fields: FormBuilderGetters.getFields,
    builder: FormBuilderGetters.getBuilder,
    subtenantSlug: SlugGetters.getSubtenantSlug,
    subtenants: TenantsGetters.getAllSubtenants,
  },
  mounted() {
    this.initBuilder();
    if (!this.subtenantSlug) {
      TenantsActions.fetchTenants();
    }
    if (this.subtenants?.length) {
      this.lastSubtenantSlug = this.subtenants[0].slug;
    }
  },
  methods: {
    async initBuilder() {
      this.blueprintId = this.$route?.params.blueprintId ?? null;
      await FormBuilderActions.initBuilder(this.blueprintId);
      this.isLoading = false;
    },
    togglePreviewAndTestMode() {
      this.isPreviewAndTestMode = !this.isPreviewAndTestMode;
    },
    openFormSettings() {
      this.FormSettingsProps = {
        visible: true,
      };
    },
    closeFormSettings() {
      this.FormSettingsProps = {
        visible: false,
      };
    },

    openConstraintsSettings() {
      this.formUniqueConstraintsVisible = true;
    },
    closeConstraintsSettings() {
      this.formUniqueConstraintsVisible = false;
    },
    setSubtenantSlug(slug) {
      this.lastSubtenantSlug = null;
      setTimeout(async () => {
        if (slug) {
          this.lastSubtenantSlug = slug;
        }
        SlugSetters.updateSubtenantSlug(slug);
      }, 50);
    },
  },
  watch: {
    'builder.lastSaveTime': function () {
      if (this.isSaveMessageVisible) {
        return;
      }
      message.success('Saved', 1, () => {
        this.isSaveMessageVisible = false;
      });
      this.isSaveMessageVisible = true;
    },
  },
};
</script>

<style scoped>
.leftPanelAbsolute {
  width: 315px;
  position: fixed;
  top: 157px;
  left: 34px;
}

.leftPanelRelative {
  margin-left: 34px;
  height: 100vh;
  flex: 315px 0 0;
}
.rightPanel {
  flex-grow: 1;
}
@media only screen and (max-width: 1000px) {
  .leftPanel {
    margin-left: 0;
  }
}
</style>
