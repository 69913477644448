import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const getAll = async () => {
  try {
    const response = await axios.get(`${getAppUrl()}/field-mapper/`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOne = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/field-mapper/`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (fieldMapperId, data) => {
  try {
    const response = await axios.put(`${getAppUrl()}/field-mapper/${fieldMapperId}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async fieldMapperId => {
  try {
    await axios.delete(`${getAppUrl()}/field-mapper/${fieldMapperId}`);
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOneMapper = async (fieldMapperId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/field-mapper/${fieldMapperId}/mapper`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const updateOneMapper = async (fieldMapperId, mapperId, data) => {
  try {
    const response = await axios.put(`${getAppUrl()}/field-mapper/${fieldMapperId}/mapper/${mapperId}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOneMapper = async (fieldMapperId, mapperId) => {
  try {
    const response = await axios.delete(`${getAppUrl()}/field-mapper/${fieldMapperId}/mapper/${mapperId}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOneBind = async (fieldMapperId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/field-mapper/${fieldMapperId}/bind`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOneBind = async (fieldMapperId, bindId, providerFieldId) => {
  try {
    let response;

    if (['_instanceId', '_blueprintId', '_userId', '_subtenantId', '_fileDownload'].includes(providerFieldId)) {
      response = await axios.delete(`${getAppUrl()}/field-mapper/${fieldMapperId}/bind-special/${bindId}`);
    } else {
      response = await axios.delete(`${getAppUrl()}/field-mapper/${fieldMapperId}/bind/${bindId}`);
    }

    return response?.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getAll,
  postOne,
  putOne,
  deleteOne,

  postOneMapper,
  updateOneMapper,
  deleteOneMapper,

  postOneBind,
  deleteOneBind,
};
