export const serializeTenant = data => {
  return {
    _id: data._id,
    slug: data.slug,
    name: data.name,
    description: data.description,

    email: data.email,
    nrRegCom: data.nrRegCom,
    cui: data.cui,
    contactPerson: data.contactPerson,
    address: data.address,
    phone: data.phone,
  };
};

export const serializeApp = data => {
  return {
    _id: data._id,
    slug: data.slug,
    name: data.name,
    description: data.description,
  };
};

export const serializeSubtenant = data => {
  return {
    _id: data._id,
    slug: data.slug,
    name: data.name,
    description: data.description,
  };
};
