<template>
  <div>
    <!-- reinitialize tinimce Editor -->
    <a-tabs default-active-key="1" v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="General">
        <GeneralOptions :fieldId="fieldId" v-if="activeKey === '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="Appearance">
        <AppearanceOptions :fieldId="fieldId" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="Advanced">
        <AdvancedOptions v-if="blueprint" :fieldId="fieldId" :blueprint="blueprint" />
      </a-tab-pane>
      <a-tab-pane
        key="4"
        tab="Display text"
        v-if="fieldInput.structure?.type === 'reference' || fieldInput.structure?.elementStructure?.type === 'reference'"
      >
        <DisplayTextOptions :fieldId="fieldId" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { FormBuilderGetters } from '@dataSystem/views/BlueprintFormBuilder/formBuilder.store';
import { AdvancedOptions } from './AdvancedOptions';
import { AppearanceOptions } from './AppearanceOptions';
import { GeneralOptions } from './GeneralOptions';
import { DisplayTextOptions } from './DisplayTextOptions';

export default {
  name: 'FieldOptionsIndex',
  props: ['fieldId', 'blueprint'],
  components: {
    AppearanceOptions,
    AdvancedOptions,
    GeneralOptions,
    DisplayTextOptions,
  },
  data() {
    return {
      activeKey: '1',
    };
  },
  computed: {
    fieldInput() {
      return FormBuilderGetters.getFieldInput(this.fieldId);
    },
  },
  methods: {
    test() {
      console.log(this.fieldInput);
    },
  },
};
</script>

<style></style>
