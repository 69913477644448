<template>
  <div style="position: relative">
    <div class="field-row-flex" v-if="blueprint && instance">
      <template v-for="field in showFields" :key="field.id">
        <template v-if="field.show === true && instance[field.id]">
          <div style="width: 100%; flex-basis: auto" :key="'fields-separator-' + field.id" v-if="field?.displayOnNewRow"></div>
          <!-- <div style="width: 100%; flex-basis: auto" :key="'fields-separator-' + field.id"></div> -->
          <div style="margin-bottom: 20px" class="mobile-field-div">
            <FieldWidget
              :rawValue="instance[field.id.toString()]"
              :field="blueprint.fields.find(f => f._id.toString() === field.id.toString())"
              :fields="blueprint.fields"
              :viewingMode="true"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import FieldWidget from '@dataSystem/components/FieldWidget/FieldWidget.vue';

export default {
  name: 'ViewInstance',
  props: ['object', 'showFields', 'blueprint', 'instance'],
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    FieldWidget,
  },
};
</script>
