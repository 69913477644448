<template>
  <div class="card">
    <div class="row-contents" v-if="autoHide" @click="editFilter = !editFilter" style="cursor: pointer">
      <div class="group-text-container">
        <div class="group-text">
          <span class="title"> Filters </span>
          <span class="muted-text">{{ activeFilters ? 'Active' : 'Off' }}</span>
        </div>
      </div>
      <div class="metadata">
        <div class="controls">
          <EditOutlined />
        </div>
      </div>
    </div>
    <template v-if="fieldConditionsBlueprint && editFilter">
      <template v-for="(group, groupKey) of fieldConditionsData" :key="groupKey">
        <div v-if="groupKey" class="text-center mb-2" :key="'group-' + groupKey">
          <a-radio-group v-model:value="group.clause" :default-value="group.clause" button-style="solid">
            <a-radio-button value="AND">AND</a-radio-button>
            <a-radio-button value="OR">OR</a-radio-button>
          </a-radio-group>
        </div>
        <div class="card mb-2" style="margin-left: 10px; margin-right: 10px">
          <div class="card-body">
            <template v-for="(conditions, key) of group.conditions" :key="key">
              <div>
                <a-divider v-if="key" :class="'full-width-divider'" orientation="left"> OR continue if </a-divider>
                <div class="mb-2" v-for="(condition, conditionKey) of conditions" :key="conditionKey">
                  <div class="row">
                    <div class="col-5">
                      <template v-if="useFieldManager">
                        <a-card>
                          <!-- used in validation and wokflows timed event filters                -->
                          <ReferenceFieldManger
                            v-if="blueprints && mainSelectedBlueprint && !secondBlueprint"
                            style="width: 100%"
                            v-model="condition.refField"
                            @update:modelValue="selectReferenceFieldConditionAction(condition, conditionKey, groupKey, 'refField')"
                            :field="returnField(condition.refField)"
                            :blueprints="blueprints"
                            :selectedBlueprint="mainSelectedBlueprint"
                            :toFieldFromOtherBlueprint="false"
                            :justReferenceFields="justReferenceFields"
                            :justReferenceAndIds="justReferenceAndIds"
                            :autoFieldConditions="true"
                            :mainBlueprint="mainSelectedBlueprint"
                            :mainBlueprintId="mainSelectedBlueprint._id.toString()"
                            :cardView="false"
                            :otherBlueprint="true"
                            :forceShowId="true"
                            @removeRefField="removeRefField(condition, 'refField')"
                          />
                          <!--                          switched and keep old comparasions, used only on comparasion -->
                          <ReferenceFieldManger
                            :key="secondBlueprintKey"
                            v-if="blueprints && mainSelectedBlueprint && secondBlueprint"
                            style="width: 100%"
                            v-model="condition.referenceField"
                            :blueprints="blueprints"
                            @update:modelValue="selectReferenceFieldConditionAction(condition, conditionKey, groupKey, 'referenceField')"
                            :field="returnField(condition.referenceField)"
                            :selectedBlueprint="secondBlueprint"
                            :toFieldFromOtherBlueprint="false"
                            :justReferenceFields="justReferenceFields"
                            :justReferenceAndIds="justReferenceAndIds"
                            :autoFieldConditions="false"
                            :mainBlueprint="secondBlueprint"
                            :mainBlueprintId="secondBlueprint._id.toString()"
                            :cardView="false"
                            :otherBlueprint="false"
                            :forceShowId="true"
                            @removeRefField="removeRefField(condition, 'referenceField')"
                          />
                        </a-card>
                      </template>
                      <template v-else>
                        <a-select
                          showSearch
                          v-model:value="condition.fieldId"
                          @change="value => selectFieldConditionAction(value, condition)"
                          :filter-option="filterOption"
                          allowClear
                          placeholder="Select field..."
                          style="width: 100%"
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                        >
                          <a-select-option key="_id" :value="'_id'" :label="'ID'">ID (number)</a-select-option>
                          <a-select-option v-for="field of fieldConditionsBlueprint.fields" :key="field._id" :value="field._id" :label="field.label">
                            {{ field.label }} ({{
                              field.structure.type === 'array'
                                ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                : (field.structure?.elementStructure?.type ?? field.structure.type)
                            }})
                          </a-select-option>
                        </a-select>
                      </template>
                      <template v-if="condition.fieldId && fieldConditionsBlueprint.fields">
                        <div>
                          <a-select
                            v-if="
                              fieldConditionsBlueprint.fields.find(
                                item => item._id === condition.fieldId && (item?.widget?.extract ? item.widget.extract.length : 0 > 1)
                              )
                            "
                            v-model="condition.getFromInstance"
                            @change="
                              value =>
                                selectFieldConditionTypeChange(
                                  value,
                                  condition,
                                  fieldConditionsBlueprint.fields.find(item => item._id === condition.fieldId).widget.extract
                                )
                            "
                            :placeholder="'Extract'"
                            style="width: 100%; margin-top: 20px"
                            value=""
                            :style="{
                              'margin-top': marginTopFromParent ?? '0px',
                            }"
                          >
                            <a-select-option
                              v-for="item in fieldConditionsBlueprint.fields.find(item => item._id === condition.fieldId).widget.extract"
                              :key="item.value"
                              :value="item.value"
                            >
                              {{ item.label }} ({{ item.type }})
                              <template v-if="item.label === 'Gender'">[male/female] </template>
                            </a-select-option>
                          </a-select>
                        </div>
                      </template>
                      <template v-if="condition.getFromInstance">
                        <a-select
                          :placeholder="'Calculate using date field'"
                          v-model:value="condition.calculateWithMainBlueprintField"
                          v-if="
                            fieldConditionsBlueprint.fields
                              .find(item => item._id === condition.fieldId)
                              .widget.extract.find(i => i.value === condition.getFromInstance).calculate ?? null === 'fromDate'
                          "
                          style="width: 100%"
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                        >
                          <a-select-option :key="''" :value="''">
                            {{ 'Current date' }}
                          </a-select-option>
                          <a-select-option
                            v-for="it in selectedBlueprint.fields.filter(f => f.structure.type === 'date')"
                            :key="it._id"
                            :value="it._id"
                          >
                            {{ it.label }} ({{ it.structure.type }})
                          </a-select-option>
                        </a-select>
                      </template>
                    </div>
                    <div :class="conditionsAcceptFieldValue ? 'col-2' : 'col-2'">
                      <a-select
                        v-model:value="condition.comparison"
                        style="width: 100%"
                        placeholder="Condition..."
                        data-cy="comparation"
                        :style="{ 'margin-top': marginTopFromParent ?? '0px' }"
                      >
                        <template v-for="(filterString, filterType) in fieldFilterTypes">
                          <a-select-option
                            :key="filterType"
                            :value="filterType"
                            :title="filterType"
                            v-if="showConditionsNulls || (filterType !== 'IS_NOT_NULL' && filterType !== 'IS_NULL')"
                          >
                            <span v-html="filterString" />
                          </a-select-option>
                        </template>
                      </a-select>

                      <div v-if="conditionsAcceptFieldValue">
                        <a-select
                          v-model:value="condition.valueType"
                          placeholder="Type"
                          style="width: 100%"
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                        >
                          <a-select-option key="static_value">Static value</a-select-option>
                          <a-select-option key="from_field">From Field</a-select-option>
                          <a-select-option v-if="(pluginsData ?? []).length" key="from_plugin_data">From Plugin data</a-select-option>
                        </a-select>
                      </div>
                    </div>

                    <div
                      :class="conditionsAcceptFieldValue ? 'col-4' : 'col-4'"
                      v-if="condition.comparison !== 'IS_NULL' && condition.comparison !== 'IS_NOT_NULL'"
                    >
                      <template v-if="condition.valueType === 'from_field' && conditionsAcceptFieldValueWithDepth">
                        <a-card>
                          <!-- switched and keep old comparasions, used only on comparasion -->
                          <ReferenceFieldManger
                            :key="secondBlueprintKey"
                            v-if="blueprints && secondBlueprint"
                            style="width: 100%"
                            v-model="condition.refField"
                            :blueprints="blueprints"
                            @update:modelValue="selectReferenceFieldConditionAction(condition, conditionKey, groupKey, 'refField')"
                            :field="returnField(condition.refField)"
                            :selectedBlueprint="mainSelectedBlueprint"
                            :toFieldFromOtherBlueprint="false"
                            :justReferenceFields="justReferenceFields"
                            :justReferenceAndIds="justReferenceAndIds"
                            :autoFieldConditions="true"
                            :mainBlueprint="mainSelectedBlueprint"
                            :mainBlueprintId="mainSelectedBlueprint._id.toString()"
                            :cardView="false"
                            :otherBlueprint="false"
                            :forceShowId="true"
                            @removeRefField="removeRefField(condition, 'refField')"
                          />
                        </a-card>
                      </template>

                      <template v-else-if="condition.valueType === 'from_plugin_data' && (pluginsData ?? []).length">
                        <a-select
                          placeholder="Select plugin..."
                          style="width: 100%"
                          allowClear
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                          v-model:value="condition.selectedPluginPath"
                        >
                          <a-select-option v-for="pluginField in pluginsData" :key="pluginField.path" :value="pluginField.path">
                            {{ pluginField.name + ' -> ' + pluginField.endpoint }}
                          </a-select-option>
                        </a-select>
                        <a-select
                          v-if="condition.selectedPluginPath"
                          allowClear
                          placeholder="Select a Field..."
                          :options="pluginsData.find(p => p.path === condition.selectedPluginPath).fields"
                          style="width: 100%"
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                          showSearch
                          :filter-option="filterOption"
                          v-model:value="condition.value"
                        />
                      </template>

                      <template v-else-if="condition.valueType === 'from_field' && conditionsAcceptFieldValue">
                        <a-select
                          v-model:value="condition.referenceField"
                          :placeholder="mainBlueprint?.name + ' fields'"
                          style="width: 100%"
                          :style="{
                            'margin-top': marginTopFromParent ?? '0px',
                          }"
                        >
                          <a-select-option v-for="field in mainBlueprint?.fields ?? []" :key="field._id">
                            {{ mainBlueprint?.name }} > {{ field.label }} ({{
                              field.structure.type === 'array'
                                ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                : (field.structure?.elementStructure?.type ?? field.structure.type)
                            }})
                          </a-select-option>
                        </a-select>
                      </template>

                      <template v-else>
                        <!--        referenceField workflow validate comparasion    and       refField for workflow validate, workflow timed fliters          -->
                        <template v-if="(condition?.referenceField?.input || condition?.refField?.input) && condition.fieldType !== 'date'">
                          <FieldWidget
                            v-if="
                              resetSelectedWidgetCondition !== conditionKey ||
                              (resetSelectedWidgetGroup !== groupKey && (secondBlueprint ? condition?.referenceField : condition.refField)?.input)
                            "
                            :field="returnField(secondBlueprint ? condition?.referenceField : condition.refField)"
                            v-model="condition.value"
                            placeholder="Enter value..."
                            :hideLabel="true"
                            :hideDescription="true"
                            :style="{
                              'margin-top': marginTopFromParent ?? '0px',
                            }"
                          />
                        </template>

                        <template v-else-if="condition.fieldType === 'date'">
                          <div>
                            <a-checkbox v-model:checked="condition.itIsThisYearDate">It's this Year</a-checkbox>
                          </div>
                          <div v-if="!condition.itIsThisYearDate">
                            <a-checkbox class="m-0" v-model:checked="condition.manuallyCurrentDate">Current date</a-checkbox>
                            <a-date-picker
                              style="width: 150px"
                              :style="{
                                'margin-top': marginTopFromParent ?? '0px',
                              }"
                              v-if="!condition.manuallyCurrentDate"
                              :readOnly="true"
                              :format="condition.dateFormat ?? 'YYYY-MM-DD'"
                              v-model:value="condition.value"
                              :valueFormat="'YYYY-MM-DD'"
                            />
                            <a-input-number style="width: 80px; top: -4px" class="ml-2" v-model:value="condition.manuallyDateDays" />
                            days
                          </div>
                        </template>
                        <template v-else-if="['number', 'reference'].includes(condition.fieldType)">
                          <a-input-number
                            placeholder="Value"
                            style="width: 100%"
                            :style="{
                              'margin-top': marginTopFromParent ?? '0px',
                            }"
                            v-model:value="condition.value"
                          />
                        </template>
                        <template v-else-if="condition.fieldType === 'boolean'">
                          <a-select
                            placeholder="Value"
                            style="width: 100%"
                            :style="{
                              'margin-top': marginTopFromParent ?? '0px',
                            }"
                            v-model:value="condition.value"
                          >
                            <a-select-option :value="true">True</a-select-option>
                            <a-select-option :value="false">False</a-select-option>
                          </a-select>
                        </template>
                        <template v-else>
                          <a-input
                            v-model:value="condition.value"
                            placeholder="Value"
                            :style="{
                              'margin-top': marginTopFromParent ?? '0px',
                            }"
                          />
                        </template>
                      </template>
                    </div>
                    <div class="col-1">
                      <a-tooltip
                        v-if="groupKey !== 0 || conditionKey !== 0"
                        :title="groupKey !== 0 && conditionKey === 0 ? 'Remove group' : 'Remove condition'"
                        style="margin-top: 20px"
                        :style="{ 'margin-top': marginTopFromParent ?? '0px' }"
                      >
                        <a-button v-if="groupKey !== 0 && conditionKey === 0" @click="removeGroup(fieldConditionsData, groupKey)"
                          ><MinusOutlined /> G</a-button
                        >
                        <a-button v-if="conditionKey !== 0" @click="removeCondition(conditions, conditionKey)"><MinusOutlined /></a-button>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
                <a-button size="small" class="mr-2" @click="addFieldConditions(conditions, 'AND')" type="primary"><PlusOutlined /> AND</a-button>
              </div>
            </template>

            <div class="mt-3">
              <a-button size="small" @click="addFieldConditions(group.conditions, 'OR')" type="primary"><PlusOutlined /> OR</a-button>
            </div>
          </div>
        </div>
      </template>
      <div class="mt-3">
        <a-button size="small" @click="addFieldConditionsGroup" style="margin-left: 10px; margin-bottom: 10px"><PlusOutlined /> GROUP</a-button>
      </div>
    </template>
  </div>
</template>

<script>
import ReferenceFieldManger from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFieldManger.vue';
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldWidget from '@dataSystem/components/FieldWidget/FieldWidget.vue';
import _ from 'lodash';

const FieldFilterTypes = {
  EQUAL: 'Equal (==)',
  NOT_EQUAL: 'Not equal (&lt;&gt;)',
  GREATER: 'Greater (&gt;)',
  GREATER_OR_EQUAL: 'Greater or equal (&gt;=)',
  LOWER: 'Lower (&lt;)',
  LOWER_OR_EQUAL: 'Lower or equal (&lt;=)',
  EXISTS: 'Exists',
  IS_NULL: 'Is NULL',
  IS_NOT_NULL: 'Is not NULL',
  CONTAINS: 'Contains',
  NOT_CONTAINS: 'Not Contains',
};

const DefaultFieldConditionsData = {
  clause: 'AND',
  blueprintId: null,
  conditions: [
    [
      {
        refField: undefined,
        fieldId: undefined,
        fieldType: null,
        comparison: undefined,

        valueType: 'static_value',
        selectedPluginPath: undefined,
        referenceField: undefined,

        value: null,
        calculateWithMainBlueprintField: '',
        mainBlueprintId: null,
        itIsThisYearDate: false,
        manuallyCurrentDate: false,
        manuallyDateDays: 0,
        manuallyDateDaysFromField: undefined,
        manuallyDateDaysCondition: 'static-value',

        manuallyDateMounts: 0,
        manuallyDateMountsFromField: undefined,
        manuallyDateMountsCondition: 'static-value',
        dateFormat: null,
      },
    ],
  ],
};
export default {
  name: 'FieldConditions',
  components: {
    EditOutlined,
    FieldWidget,
    ReferenceFieldManger,
    MinusOutlined,
    PlusOutlined,
  },
  emits: ['setFieldConditionsData', 'update:modelValue', 'setFilterState'],
  props: [
    'useFieldManager',
    'fieldConditions',
    'fieldConditionsBlueprint',
    'mainBlueprint',
    'blueprints',
    'selectedBlueprint',
    'secondBlueprint',
    'conditionsAcceptFieldValue',
    'conditionsAcceptFieldValueWithDepth',
    'toFieldFromOtherBlueprint',
    'justReferenceFields',
    'justSingleReferenceFields',
    'autoFieldConditions',
    'mainSelectedBlueprint',
    'otherBlueprint',
    'marginTopFromParent',
    'justReferenceAndIds',
    'showConditionsNulls',
    'pluginsData',
    'autoHide',
    'modelValue',
  ],
  data() {
    return {
      selectedFiedId: null,
      selectedFiedIdKey: null,
      getFromInstance: null,
      autoFieldConditionsData: [],
      referenceField: [],
      resetSelectedWidgetCondition: null,
      resetSelectedWidgetGroup: null,
      fieldConditionsData: [],
      fieldFilterTypes: FieldFilterTypes,
      secondBlueprintKey: 0,
      editFilter: !this.autoHide,
    };
  },
  mounted() {
    if (this.fieldConditions.length) {
      this.fieldConditionsData = this.fieldConditions;
    } else {
      const condition = {
        ...JSON.parse(JSON.stringify(DefaultFieldConditionsData)),
      };
      condition.blueprintId = this.mainBlueprint._id.toString();
      this.fieldConditionsData = [condition];
    }
  },
  computed: {
    activeFilters() {
      if (this.fieldConditionsData?.length) {
        return this.fieldConditionsData.some(item => item.conditions.some(conditionArray => conditionArray.some(condition => condition?.refField)));
      }
      return false;
    },
  },
  watch: {
    activeFilters() {
      this.$emit('setFilterState', this.activeFilters);
    },
    secondBlueprint() {
      this.secondBlueprintKey += 1;
    },
    fieldConditionsData: {
      deep: true,
      handler(conditions) {
        if (
          !_.isEqual(conditions, [{ ...JSON.parse(JSON.stringify(DefaultFieldConditionsData)) }]) &&
          this.fieldConditionsBlueprint &&
          conditions.length
        ) {
          this.$emit('setFieldConditionsData', conditions);
          this.$emit('update:modelValue', conditions);
        }
      },
    },
  },
  methods: {
    removeCondition(conditions, key) {
      conditions.splice(key, 1);
    },
    removeGroup(group, key) {
      group.splice(key, 1);
    },
    /**
     * refresh widget and field manager
     * @param field
     * @returns {*}
     */
    returnField(field) {
      setTimeout(async () => {
        this.resetSelectedWidgetCondition = null;
        this.resetSelectedWidgetGroup = null;
      }, 50);
      return field;
    },
    selectFieldConditionTypeChange(value, condition, extractList) {
      const param = extractList.find(item => item.value === value);

      if (param) {
        condition.fieldType = param.type ?? 'string';
        condition.mainBlueprintId = this.mainBlueprint._id.toString();
      }
    },
    selectFieldConditionAction(fieldId, condition) {
      condition.value = null;
      const field = this.fieldConditionsBlueprint.fields.find(item => item._id === fieldId);
      condition.fieldType = fieldId === '_id' ? 'number' : (field?.structure?.elementStructure?.type ?? field?.structure?.type);
      condition.dateFormat = field?.structure?.elementStructure?.options?.format ?? field?.structure?.options?.format ?? 'DD-MM-YYYY';
    },
    /**
     * Onchange Reference field manager:
     * Check field type to clear value
     * set condition for selected field
     * @param condition
     * @param conditionKey
     * @param groupKey
     * @param referenceFieldKey // referenceField and refField
     */
    selectReferenceFieldConditionAction(condition, conditionKey, groupKey, referenceFieldKey) {
      this.resetSelectedWidgetCondition = conditionKey;
      this.resetSelectedWidgetGroup = groupKey;
      condition.fieldId = condition[referenceFieldKey].id;
      condition.mainBlueprintId = condition[referenceFieldKey].mainBlueprintId;
      const fieldType =
        condition[referenceFieldKey].id.toString() === '_id'
          ? 'number'
          : (condition[referenceFieldKey].structure?.elementStructure?.type ?? condition[referenceFieldKey]?.structure?.type);
      if (fieldType !== condition.fieldType && condition.valueType !== 'from_field') {
        condition.value = null;
      }
      condition.fieldType = fieldType;
      condition.dateFormat =
        condition[referenceFieldKey]?.structure?.elementStructure?.options?.format ??
        condition[referenceFieldKey]?.structure?.options?.format ??
        'DD-MM-YYYY';
    },
    removeRefField(condition, atribute) {
      delete condition[atribute];
    },
    /**
     * Add AND or condition
     * @param conditions
     * @param clause
     */
    addFieldConditions(conditions, clause) {
      if (clause === 'AND') {
        conditions.push({
          fieldId: undefined,
          fieldType: null,
          comparison: undefined,

          valueType: 'static_value',
          selectedPluginPath: undefined,
          referenceField: undefined,

          value: null,
          getFromInstance: null,
          mainBlueprintId: this.mainBlueprint._id,
          itIsThisYearDate: false,
          manuallyCurrentDate: false,
          manuallyDateDays: 0,
          manuallyDateDaysFromField: undefined,
          manuallyDateDaysCondition: 'static-value',

          manuallyDateMounts: 0,
          manuallyDateMountsFromField: undefined,
          manuallyDateMountsCondition: 'static-value',
          dateFormat: null,
        });
      } else {
        conditions.push([
          {
            fieldId: undefined,
            fieldType: null,
            comparison: undefined,

            valueType: 'static_value',
            selectedPluginPath: undefined,
            referenceField: undefined,

            value: null,
            getFromInstance: null,
            mainBlueprintId: this.mainBlueprint._id,
            itIsThisYearDate: false,
            manuallyCurrentDate: false,
            manuallyDateDays: 0,
            manuallyDateDaysFromField: undefined,
            manuallyDateDaysCondition: 'static-value',

            manuallyDateMounts: 0,
            manuallyDateMountsFromField: undefined,
            manuallyDateMountsCondition: 'static-value',
            dateFormat: null,
          },
        ]);
      }
    },
    addFieldConditionsGroup() {
      this.fieldConditionsData.push({
        ...JSON.parse(JSON.stringify(DefaultFieldConditionsData)),
      });
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    addNumbersToDate(value, condition) {
      condition.manuallyDateDays = value.manuallyDateDays;
      condition.manuallyDateDaysFromField = value.manuallyDateDaysFromField;
      condition.manuallyDateDaysCondition = value.manuallyDateDaysCondition;

      condition.manuallyDateMounts = value.manuallyDateMounts;
      condition.manuallyDateMountsFromField = value.manuallyDateMountsFromField;
      condition.manuallyDateMountsCondition = value.manuallyDateMountsCondition;
    },
  },
};
</script>

<style></style>
