import { pluginsList } from './views/List';
import { pluginBuilder } from './views/Builder';

const pluginsManagementRoutes = [
  {
    path: '/',
    name: 'PluginsList',
    component: pluginsList,
    meta: {
      menuTitle: 'Plugins',
    },
    props: true,
  },
  {
    path: '/plugin/:pluginPath',
    name: 'PluginBuilder',
    component: pluginBuilder,
  },
];

export default pluginsManagementRoutes;
