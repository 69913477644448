import { reactive } from 'vue';

import { pluginsManagementApi } from '../api';

const initialState = {
  pluginsList: [],
  plugin: {},
  structure: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getAll: () => state.pluginsList,
  getPlugin: () => state.plugin,
  getStructure: () => state.structure,
};

const Mutations = {
  SET_PLUGIN: plugin => {
    state.plugin = plugin;
  },
};

const Actions = {
  fetchPluginsList: async () => {
    state.pluginsList = await pluginsManagementApi.getAll();
  },
  fetchPlugin: async pluginPath => {
    state.structure = await pluginsManagementApi.getPluginStructure(pluginPath); // load from conf file
    state.plugin = await pluginsManagementApi.getPlugin(pluginPath); // load from mongo
  },
  savePlugin: async (pluginPath, plugin) => {
    if (!plugin?._id) {
      const newPlugin = await pluginsManagementApi.postPlugin({ plugin });
      Mutations.SET_PLUGIN(newPlugin);
    } else {
      const updatedPlugin = await pluginsManagementApi.patchPlugin({ plugin });
      Mutations.SET_PLUGIN(updatedPlugin);
    }
  },
};

export const PluginsGetters = Getters;
export const PluginsMutations = Mutations;
export const PluginsActions = Actions;
