<template>
  <div
    :class="{
      'menu-container': !depth,
      minimized: localMinimized,
      [`menu-${position}`]: !depth,
    }"
    v-if="list.length"
  >
    <div v-if="!depth" :style="`display: ${!isMobile ? 'flex' : 'block'}; padding-bottom: 10px; height: 42px`">
      <div style="width: 42px; padding-left: 13px" v-if="!depth && !isMobile">
        <MenuUnfoldOutlined v-if="localMinimized" @click="toggleMenu" />
        <MenuFoldOutlined v-else @click="toggleMenu" />
      </div>
      <div v-if="!localMinimized && foundChildren" style="padding-top: 4px">
        <a-input
          id="searchInput"
          allowClear
          :size="!isMobile ? 'small' : ''"
          placeholder="Cauta in meniu..."
          style="width: 100%"
          @change="searchInMenu"
        />
      </div>
    </div>
    <div class="main-manu-title" v-if="depth === 1 && mainMenu">
      {{ mainMenu }}
    </div>
    <template v-for="item in list" :key="item._id">
      <div
        class="item-section"
        v-if="
          ((activeSearch && item.children.some(m => m.show)) || !activeSearch) &&
          item.active &&
          item.showInMenu &&
          (item.subtenantId.includes(tenantData.subtenant._id) || item.allSubtenants)
        "
      >
        <template v-if="item.show || (!item.show && !depth)">
          <div class="item" @click="openMenu(item)" v-if="!item.templateId">
            <span class="menu-icon" v-if="item.icon" v-html="item.icon"></span>
            <span class="title">{{ item.title }}</span>
            <PlusOutlined class="menu-arrow" :class="{ SubMenuPlusMinus: depth }" v-if="item.children.length && !item.subMenuVisible" />
            <MinusOutlined class="menu-arrow" :class="{ SubMenuPlusMinus: depth }" v-if="item.children.length && item.subMenuVisible" />
          </div>
          <router-link
            v-else
            :to="{
              name: 'templateDataSystemBuilder',
              params: { menuItemId: position + item._id },
            }"
            custom
            v-slot="{ isActive, href, navigate }"
          >
            <div class="item" @click="openMenu(item)" :class="{ active: isActive }">
              <a :href="href" @click="handleClick($event, navigate)">
                <span class="menu-icon" v-if="item.icon" v-html="item.icon"></span>
                <span class="title">{{ item.title }}</span>
              </a>
              <PlusOutlined
                class="menu-arrow"
                :class="{ SubMenuPlusMinus: depth }"
                v-if="item.children.length && item.children.some(m => m.showInMenu) && !item.subMenuVisible"
              />
              <MinusOutlined
                class="menu-arrow"
                :class="{ SubMenuPlusMinus: depth }"
                v-if="item.children.length && item.children.some(m => m.showInMenu) && item.subMenuVisible"
              />
            </div>
          </router-link>
        </template>
        <RecursiveMenu
          v-if="item && item.active && item.children.length && item.children.some(m => m.showInMenu)"
          :depth="depth + 1"
          :position="position ?? 0"
          :mainMenu="item.title"
          class="item-menu-sub"
          :class="{ 'hide-menu': !item.subMenuVisible && !localMinimized }"
          :list="item.children ?? []"
          v-model:minimized="localMinimized"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';
import { TenantsGetters } from '@tenants/shared/tenants.store';

export default {
  name: 'recursive-menu',
  emits: ['searchInMenu'],
  props: {
    minimized: {
      type: Boolean,
      default: true,
    },
    mainMenu: {
      type: String,
    },
    position: {
      type: String,
    },
    depth: {
      type: Number,
      default: 0,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PlusOutlined,
    MinusOutlined,
  },
  data() {
    return {
      localMinimized: true,
      activeSearch: false,
      isMobile: false,
    };
  },
  created() {
    const minimized = localStorage.getItem('left-menu');
    this.localMinimized = minimized === 'minimized';

    this.isMobile = window.screen.width < 600;

    if (this.isMobile) {
      this.localMinimized = false;
    }
    this.addFieldsRecursive(this.list);
  },
  computed: {
    tenantData: TenantsGetters.getTenantAppSubtenant,
    foundChildren() {
      return this.list.some(item => item.children.length);
    },
  },
  watch: {
    minimized(value) {
      this.localMinimized = value;
    },
  },
  methods: {
    addFieldsRecursive(menuItems) {
      menuItems.forEach(item => {
        item.show = true;
        item.subMenuVisible = false;
        if (item.children && item.children.length > 0) {
          this.addFieldsRecursive(item.children);
        }
      });
    },
    closeAllMenus(menuItems) {
      menuItems.forEach(item => {
        item.show = true;
        item.subMenuVisible = false;
        if (item.children && item.children.length > 0) {
          this.closeAllMenus(item.children);
        }
      });
    },
    searchMenu(menuItems, searchTerm) {
      let found = false;
      menuItems.forEach(item => {
        if (item.title.toLowerCase().includes(searchTerm)) {
          item.show = true;
          found = true;
        } else {
          item.show = false;
        }
        if (item.children && item.children.length > 0) {
          const childFound = this.searchMenu(item.children, searchTerm);
          if (childFound) {
            item.subMenuVisible = true;
            found = true;
          } else {
            item.subMenuVisible = false;
          }
        }
      });
      return found;
    },
    searchInMenu(event) {
      const searchTerm = (event?.target?.value ?? '').toLowerCase();
      if (searchTerm === '') {
        this.closeAllMenus(this.list);
        this.activeSearch = false;
        return;
      }
      this.activeSearch = true;
      this.searchMenu(this.list, searchTerm);
    },
    openMenu(item) {
      item.subMenuVisible = !item.subMenuVisible;
    },
    handleClick(event, navigate) {
      event.preventDefault(); // Prevent default anchor behavior
      navigate(); // Navigate to the route
      this.goToTop(); // Additional custom logic
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    toggleMenu() {
      localStorage.setItem('left-menu', !this.localMinimized ? 'minimized' : 'open');
      this.localMinimized = !this.localMinimized;
    },
  },
};
</script>
<style lang="scss" scoped>
///*.item-section {
//  &:hover {
//    .item {
//      > .menu-arrow {
//        opacity: 1;
//      }
//    }
//  }
//}*/
//
//.item-menu-l .item {
//  border-right: 1px solid rgba(0, 0, 0, 0.1);
//}
//.item-menu-r .item {
//  text-align: right;
//  border-left: 1px solid rgba(0, 0, 0, 0.1);
//}
//.item-menu-c .item {
//  text-align: center;
//}
//

//.item-menu-c {
//  display: flex;
//  margin-bottom: 20px;
//  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//}
//
//.item-menu-l .item.active,
//.item-menu-r .item.active {
//  border-right: 1px solid #1890ff;
//}
//.item-menu-c .item.active {
//  border-bottom: 1px solid #1890ff;
//}

.ghost {
  opacity: 0.4;
  background: #ffffff;
  border-radius: 4px;
}
</style>
