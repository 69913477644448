<template>
  <a-row>
    <a-col v-for="(field, index) in fieldsList" :key="index" :span="24" style="margin-bottom: 10px">
      <a-card v-if="field.autoComplete">
        <div class="row">
          <div class="col-3">
            <a-checkbox v-model:checked="field.autoComplete.isEnabled" style="margin-left: 5px">
              <strong>{{ field.label }}</strong>
            </a-checkbox>
            <span>({{ field.type }})</span>
          </div>
          <div class="col-4">
            <ReferenceFieldManger
              style="width: 100%"
              v-model="field.autoComplete.triggerField"
              :field="returnField(field.autoComplete.triggerField)"
              :blueprints="blueprints"
              :selectedBlueprint="selectedBlueprint"
              :toFieldFromOtherBlueprint="false"
              :justReferenceFields="true"
              :autoFieldConditions="false"
              :mainBlueprint="selectedBlueprint"
              :mainBlueprintId="selectedBlueprint._id.toString()"
              :cardView="false"
              :otherBlueprint="false"
              :hideIndex="1"
              :addTextToFirstSelector="'Trigger from '"
              :noCondition="false"
              @updateField="updateReferenceField(field)"
            />
          </div>
          <div class="col-5" v-if="field.autoComplete.dataField !== 0 && field?.autoComplete?.triggerField">
            <template v-for="(refField, refIndex) of returnAutoFieldConditionsData(field.autoComplete.triggerField)" :key="refIndex">
              <div>
                <ReferenceFieldManger
                  style="width: 100%"
                  v-model="field.autoComplete.dataField"
                  :field="returnField(field.autoComplete.dataField)"
                  :blueprints="blueprints"
                  :selectedBlueprint="blueprints.find(b => b._id.toString() === refField.blueprintId.toString())"
                  :toFieldFromOtherBlueprint="false"
                  :justReferenceFields="false"
                  :autoFieldConditions="false"
                  :mainBlueprint="blueprints.find(b => b._id.toString() === refField.blueprintId.toString())"
                  :mainBlueprintId="refField.blueprintId.toString()"
                  :cardView="false"
                  :otherBlueprint="false"
                  :forceShowId="true"
                  :addTextToFirstSelector="'Get data from '"
                  :justReferencesAndFieldType="field.type"
                />
              </div>
            </template>
          </div>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import ReferenceFieldManger from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFieldManger.vue';
import _ from 'lodash';

export default {
  emits: ['update:modelValue'],
  components: { ReferenceFieldManger },
  props: ['input', 'template', 'blueprints', 'selectedBlueprint', 'listFields'],

  data() {
    return {
      loading: true,
      autocomplete: [{ field: null, active: false }],
      fieldsList: null,
      check: false,
    };
  },
  mounted() {
    this.fieldsList = _.cloneDeep(this.template.object.view.listFields);
  },
  methods: {
    returnField(field) {
      return field;
    },
    returnAutoFieldConditionsData(field) {
      const referenceBlueprintId = field.refIdPath.split('-')[1];
      return [{ blueprintId: referenceBlueprintId, type: field?.type ?? null }];
    },
    async updateReferenceField(modifiedField) {
      modifiedField.autoComplete.dataField = 0;
      setTimeout(() => {
        const field = this.fieldsList.find(f => f.id === modifiedField.id);
        field.autoComplete.dataField = null;
      }, 200);
    },
  },
  watch: {
    fieldsList: {
      deep: true,
      handler(fieldsList) {
        this.$emit('update:modelValue', fieldsList);
      },
    },
  },
};
</script>
