<template>
  <div class="mt-3">
    <a-row>
      <a-col span="24">
        <div class="mb-2"></div>
        <a-checkbox v-model:checked="dataProvider.dynamicData.active"
          >Does this dataflow require dynamic data? (having this option active will provide Mappers with 4 extra special fields: instance id,
          blueprint id and for manual Dataflows the user id and subtenant id)</a-checkbox
        >

        <div class="mt-2">
          <div>Which blueprint will this dataflow need data from?</div>
          <a-select
            v-model:value="dataProvider.dynamicData.blueprintId"
            id="dataflowBpSource"
            :options="blueprintOptions"
            :filter-option="filterOption"
            showSearch
            style="width: 400px"
          ></a-select>
        </div>

        <a-button class="mt-5" @click="saveAndGoToSources"><CaretRightOutlined /> Save & Go to SOURCES</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { CaretRightOutlined } from '@ant-design/icons-vue';
import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';

export default {
  props: ['selectedDataProvider', 'blueprints'],
  name: 'DataProviderInputBuilder',
  data() {
    return {
      loading: false,
      dataProvider: this.selectedDataProvider,
    };
  },

  computed: {
    blueprintOptions() {
      let bpOptions = [];
      if (this.blueprints) {
        bpOptions = this.blueprints.map(bp => {
          return {
            value: bp.id,
            label: bp.name,
          };
        });
      }
      return bpOptions;
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async saveAndGoToSources() {
      await DataProviderActions.editOneDataProvider(this.dataProvider._id, {
        dynamicData: { ...this.dataProvider.dynamicData },
      });
      await this.$router.push({
        name: 'DataProviderSources',
        params: { dataProviderId: this.dataProvider._id },
      });
    },
  },
  components: { CaretRightOutlined },
};
</script>
<style scoped></style>
