import { reactive } from 'vue';

import templateManagementApi from '@/apps/templateManagement/templateManagement.api';

const initialState = {
  templates: [],
  template: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getTemplates: () => {
    return state.templates;
  },
  getTemplate: async templateId => {
    if (state.template?._id === templateId) {
      return state.template;
    }
    return templateManagementApi.getOneTemplate(templateId);
  },
};

const Mutations = {
  SET_TEMPLATES: templates => {
    state.templates = templates;
  },
  SET_TEMPLATE: template => {
    state.template = template;
  },
};

const Actions = {
  fetchTemplates: async () => {
    const templates = await templateManagementApi.getAllTemplates();
    Mutations.SET_TEMPLATES(templates);
  },
  setTemplate(template) {
    Mutations.SET_TEMPLATE(template);
  },
};

export const TemplatesGetters = Getters;
export const TemplatesMutations = Mutations;
export const TemplatesActions = Actions;
