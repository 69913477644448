<template>
  <div>
    <div v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_sections">
        <div class="sidebar">
          <div style="padding: 20px">
            <div style="padding: 10px 0">
              <a-button-group>
                <a-button :type="importType === 'provider' ? 'primary' : 'default'" @click="importType = 'provider'">Provider</a-button>
                <a-button :type="importType === 'blueprint' ? 'primary' : 'default'" @click="importType = 'blueprint'" disabled> Blueprint </a-button>
                <a-button :type="importType === 'file' ? 'primary' : 'default'" @click="importType = 'file'">File</a-button>
              </a-button-group>
            </div>

            <div v-if="importType === 'provider'">
              <ProviderSidebar
                :providerSpecialFields="providerSpecialFields"
                :fieldMapperId="fieldMapperId"
                :selectedFieldMapper="selectedFieldMapper"
                :fieldMapperList="fieldMapperList"
                :dataProv="dataProv"
                :providerList="providerList"
                :outputProviderFields="outputProviderFields"
                @update:fieldMapperList="updateFieldMapperList"
                @selectProvider="selectProvider"
              />
            </div>

            <div v-if="importType === 'blueprint'">
              <BlueprintSidebar />
            </div>

            <div v-if="importType === 'file'">
              <FileSidebar
                :fileMapperList="fileMapperList"
                :selectedFileMapper="selectedFileMapper"
                :fieldMapperId="fieldMapperId"
                @selectFileMapper="selectFileMapper"
                @updateFileMapperList="updateFileMapperList"
              />
            </div>
          </div>
        </div>

        <div class="content-view">
          <div class="content-body">
            <div class="dataRow">
              <div class="cell">
                <div style="padding: 10px 0">
                  <a-button-group>
                    <a-button :type="destinationType === 'blueprint' ? 'primary' : 'default'" @click="destinationType = 'blueprint'">
                      Blueprint
                    </a-button>
                    <a-button :type="destinationType === 'endpoint' ? 'primary' : 'default'" @click="destinationType = 'endpoint'" disabled>
                      Endpoit
                    </a-button>
                    <a-button :type="destinationType === 'file' ? 'primary' : 'default'" @click="destinationType = 'file'" disabled> File </a-button>
                  </a-button-group>
                </div>

                <ProviderBlueprintDestination
                  :fieldMapperId="fieldMapperId"
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :specialOutputProviderFields="providerSpecialFields"
                  :destinations="selectedFieldMapper"
                  v-if="destinationType === 'blueprint' && importType === 'provider'"
                />
                <FileBlueprintDestination
                  :selectedFileMapper="selectedFileMapper"
                  v-if="destinationType === 'blueprint' && importType === 'file'"
                  @updateFileMapperList="updateFileMapperList"
                />
                <EndpointDestination
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :destinations="selectedFieldMapper"
                  @pushFieldMapper="pushFieldMapper"
                  @pushFieldMapperMappedFields="pushFieldMapperMappedFields"
                  v-if="destinationType === 'endpoint'"
                />
                <FileDestination
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :destinations="selectedFieldMapper"
                  v-if="destinationType === 'file'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataProviderActions, DataProviderMutations, DataProviderGetters } from '@dataProvider/shared/dataProvider.store';
import { FieldMapperActions, FieldMapperGetters } from '@fieldMapper/shared/fieldMapper.store';
import { FileMapperActions, FileMapperGetters } from '@fieldMapper/shared/fileMapper.store';
import { ProviderBlueprintDestination, FileBlueprintDestination, EndpointDestination, FileDestination } from './components/Destinations';
import { ProviderSidebar, BlueprintSidebar, FileSidebar } from './components/Sidebars';

const specialFields = [
  {
    _id: '_instanceId',
    originalId: '_instanceId',
    label: 'Instance ID',
  },
  {
    _id: '_blueprintId',
    originalId: '_blueprintId',
    label: 'Blueprint ID',
  },
  {
    _id: '_userId',
    originalId: '_userId',
    label: 'User ID',
  },
  {
    _id: '_subtenantId',
    originalId: '_subtenantId',
    label: 'Subtenant ID',
  },
  {
    _id: '_fileDownload',
    originalId: '_fileDownload',
    label: 'File',
  },
];

export default {
  data() {
    return {
      loading: true,

      destinationType: 'blueprint',
      importType: 'provider',

      providerSpecialFields: specialFields,
      fieldMapperId: this.$route.params.fieldMapperId ? this.$route.params.fieldMapperId : null,

      fileMapperId: null,
    };
  },
  watch: {
    $route(to) {
      this.fieldMapperId = to.params.fieldMapperId ? to.params.fieldMapperId : null;
    },
    'selectedFieldMapper.providerId': function (providerId) {
      DataProviderMutations.SET_JOINS_OUTPUT_FIELDS([]);
      if (providerId) {
        this.setProviderToMapper(providerId);
      }
    },
  },
  async mounted() {
    if (!this.fieldMapperList.length || !this.fileMapperList.length) {
      if (!this.fieldMapperList.length) {
        await FieldMapperActions.fetchFieldMapperList();
      }

      if (!this.fileMapperList.length) {
        await FileMapperActions.fetchFileMapperList();
      }

      this.loading = false;
    } else {
      this.loading = false;
    }

    DataProviderMutations.SET_JOINS_OUTPUT_FIELDS([]);
    await DataProviderActions.fetchDataProviderList();
    if (this.selectedFieldMapper.providerId) {
      await this.setProviderToMapper(this.selectedFieldMapper.providerId);
    }
  },

  computed: {
    fieldMapperList: FieldMapperGetters.getFieldMapperList,
    fileMapperList() {
      return FileMapperGetters.getFileMapperList().filter(fileMapper => fileMapper.fieldMapperId === this.fieldMapperId);
    },

    selectedFieldMapper() {
      if (this.fieldMapperList && this.fieldMapperList.length) {
        const findFieldMapper = this.fieldMapperList.find(f => f._id === this.fieldMapperId);
        if (findFieldMapper) {
          return {
            ...findFieldMapper,
            fieldMapper: findFieldMapper.fieldMapper,
          };
        }
        return {};
      }
      return {};
    },

    selectedFileMapper() {
      return this.fileMapperList && this.fileMapperList?.length
        ? (this.fileMapperList.find(f => f._id === this.fileMapperId) ?? this.fileMapperList[0])
        : {};
    },

    dataProv: DataProviderGetters.getDataProvider,
    providerList: DataProviderGetters.getDataProviderList,
    outputProviderFields: DataProviderGetters.getJoinOutputFields,
  },
  methods: {
    updateFieldMapperList(updatedList) {
      this.fieldMapperList = updatedList;
    },
    pushFieldMapper(data) {
      this.fieldMapperList.push(data);
    },
    pushFieldMapperMappedFields(data) {
      this.fieldMapperList[0].mappedFields.push(data);
    },
    async setProviderToMapper(providerId) {
      await DataProviderActions.fetchOneDataProvider(providerId);
      DataProviderActions.calculateJoinsFields();
    },
    async selectProvider(key) {
      await FieldMapperActions.editOneFieldMapper(this.fieldMapperId, {
        providerId: key,
      });
    },
    selectFileMapper(fileMapperId) {
      this.fileMapperId = fileMapperId;
    },
    async updateFileMapperList() {
      await FileMapperActions.fetchFileMapperList();
      this.fileMapperList = FileMapperGetters.getFileMapperList();
    },
  },
  components: {
    FileBlueprintDestination,
    ProviderBlueprintDestination,
    FileDestination,
    EndpointDestination,
    ProviderSidebar,
    BlueprintSidebar,
    FileSidebar,
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  width: 50%;
  display: table-cell;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}
.center {
  text-align: center;
}

.dnd-drag.disabled .saas_card {
  opacity: 0.5;
  background-color: #ddd;
}
</style>
