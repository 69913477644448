import axios from 'axios';

const getOneBase64 = async ({ appId, file }) => {
  const response = await axios.get(
    `${process.env.VUE_APP_BASE_URL}/public/data-system/fileBase64/${appId}/${file.type}/${file.uploadedName}/${file.name}`
  );
  return response.data;
};
export default {
  getOneBase64,
};
