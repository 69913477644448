<template>
  <a-drawer title="Form Settings" width="500px" :open="visible" :destroyOnClose="true">
    <div>
      <a-checkbox v-model:checked="isLinkSharingEnabled">
        Allow
        <span style="font-weight: 500">Link Sharing</span>
      </a-checkbox>
      <p>
        This will create a
        <span style="font-weight: 500">Public</span>
        link for each Subtenant
      </p>
    </div>

    <div v-if="blueprint?.resetFieldValuesOnHide !== null && blueprint?.resetFieldValuesOnHide !== undefined">
      <a-checkbox v-model:checked="blueprint.resetFieldValuesOnHide" @change="saveBlueprint"> Reset field values on hide </a-checkbox>
      <p>This will reset the value of a form field when the field is hidden as a result of it's visibility conditions.</p>
    </div>
  </a-drawer>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import { message } from 'ant-design-vue';
import { FormBuilderActions } from '../../formBuilder.store';

export default {
  props: ['visible', 'blueprintId'],
  data() {
    return {
      isLinkSharingEnabled: false,
      addons: {
        useRecaptcha: false,
      },
      blueprint: null,
    };
  },
  async created() {
    await this.fetchBlueprint();
  },
  watch: {
    isLinkSharingEnabled() {
      if (this.blueprint.linkSharing.isEnabled !== this.isLinkSharingEnabled) {
        this.updateLinkSharing();
      }
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    async fetchBlueprint() {
      const { blueprint } = await blueprintApi.getOne(this.blueprintId);
      this.blueprint = blueprint;
      this.isLinkSharingEnabled = this.blueprint.linkSharing.isEnabled;
      if (!this.blueprint?.resetFieldValuesOnHide) {
        this.blueprint.resetFieldValuesOnHide = false;
      }
    },
    async updateLinkSharing() {
      const updatedLinkSharing = await blueprintApi.patchLinkSharing(this.blueprintId, this.isLinkSharingEnabled);
      this.blueprint.linkSharing = updatedLinkSharing;
      message.success('Saved');
    },
    async saveBlueprint() {
      await FormBuilderActions.updateBlueprint({
        ...this.blueprint,
      });
    },
  },
};
</script>

<style></style>
