<template>
  <a-modal v-model:open="modalView" title="Configurare setari element" :width="1000" @cancel="close">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Element settings">
        <div>
          <a-row>
            <a-col :span="12" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showTitle"> Show title </a-checkbox>
              <div></div>
              Custom title<br />
              <a-input v-model:value="localTemplate.object.view.default.customTitle" :placeholder="selectedBlueprint.name" />
            </a-col>
          </a-row>
        </div>

        <hr />

        <div v-if="localTemplate.object.view.type === 'list'">
          <a-row class="mb-10">
            <a-col :span="12">
              <a-checkbox v-model:checked="localTemplate.object.clickItem.open"> Open on click? </a-checkbox>
            </a-col>

            <a-col :span="12">
              <a-checkbox v-model:checked="localTemplate.object.view.loadMore"> Load more items </a-checkbox>
            </a-col>
          </a-row>

          <a-row class="mb-10">
            <a-col :span="12" style="padding-right: 20px" class="mb-10">
              Open target:<br />
              <a-select v-model:value="localTemplate.object.clickItem.openTarget" style="width: 100%">
                <a-select-option value="self"> Self </a-select-option>
                <a-select-option value="popup"> Popup </a-select-option>
                <a-select-option value="template"> Template </a-select-option>
              </a-select>
            </a-col>

            <a-col :span="12" class="mb-10">
              Open item in popup:<br />
              <a-select v-model:value="localTemplate.object.clickItem.openItemInPopup" style="width: 100%">
                <a-select-option value="otherPopup"> otherPopup </a-select-option>
                <a-select-option value="stayInPopup"> stayInPopup </a-select-option>
              </a-select>
            </a-col>

            <a-col :span="6" style="padding-right: 20px" class="mb-10">
              Template to open:<br />
              <a-select
                v-model:value="localTemplate.object.clickItem.templateId"
                allowClear
                :options="templateOptions"
                style="width: 100%"
                :filter-option="filterOption"
                show-search
              ></a-select>
            </a-col>
            <a-col :span="6" style="padding-right: 20px" class="mb-10">
              Template base id:<br />
              <a-select v-model:value="localTemplate.object.clickItem.passFieldId" :options="templateBaseIdOptions" style="width: 100%"></a-select>
            </a-col>

            <a-col :span="12" class="mb-10">
              Items per page<br />
              <a-input v-model:value="localTemplate.object.view.itemsPerPage" />
            </a-col>
          </a-row>

          <hr />
        </div>

        <div style="margin-top: 20px">
          <a-row class="mb-4">
            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showAddNewButton"> showAddNewButton </a-checkbox>
              <div></div>
              addNewButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.addNewButtonLabel" placeholder="New button label" />
            </a-col>

            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showEditButton"> showEditButton </a-checkbox>
              <div></div>
              editButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.editButtonLabel" placeholder="Edit button label" />
            </a-col>

            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showDeleteButton"> showDeleteButton </a-checkbox>
              <div></div>
              deleteButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.deleteButtonLabel" placeholder="Delete button label" />
            </a-col>
            <a-col :span="6">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showMassEmail"> showMassEmail </a-checkbox>
              <div></div>
              massEmailButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.massEmailButtonLabel" placeholder="Mass e-mail button label" />
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              <a-row>
                <a-col :span="24" v-if="localTemplate.object.view.default">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.showDefaultCreateForm"> showCreateFormAsDefault </a-checkbox>
                </a-col>
                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.smsFromAddNew"> Enable SMS sending from ADD/EDIT form </a-checkbox>
                </a-col>
                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.smsFromList"> Enable SMS sending from list actions </a-checkbox>
                </a-col>
                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.showSearchTable"> showSearchTable </a-checkbox>
                </a-col>
                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.showDownloadXLSX"> showDownloadXLSX </a-checkbox>
                </a-col>
                <a-col :span="24" v-if="localTemplate.object.view.default.showDownloadXLSX">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.moveTopDownloadXLSX"> moveTopDownloadXLSX </a-checkbox>
                </a-col>
                <!--              <a-col :span="24">
                                  <a-checkbox v-model:checked="template.object.view.default.showDownloadPDF">
                                    showDownloadPDF
                                  </a-checkbox>
                                </a-col>-->

                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.simpleListView"> simpleListView </a-checkbox>
                </a-col>

                <a-col :span="24" v-if="localTemplate.object.view.default.simpleListView">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.rowsNumbering"> rowsNumbering </a-checkbox>
                </a-col>

                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.showAutoData"> showAutoData </a-checkbox>
                </a-col>

                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.convertOwnerIdToOwnerDetails">
                    convertOwnerIdToOwnerDetails
                  </a-checkbox>
                </a-col>

                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.updateListOnAnyCRUD"> updateListOnAnyCRUD </a-checkbox>
                </a-col>

                <a-col :span="24" class="mt-1" style="padding-right: 20px">
                  updateListOnBlueprintIdCRUD
                  <a-select
                    v-model:value="localTemplate.object.view.default.updateListOnBlueprintIdCRUD"
                    mode="multiple"
                    :options="blueprintOptions"
                    style="width: 100%"
                    :allowClear="true"
                  ></a-select>
                </a-col>
                <a-col :span="24" class="mt-1" style="padding-right: 20px">
                  saveMessage
                  <a-input v-model:value="localTemplate.object.view.default.saveMessage" :placeholder="'Save message (text/html)'" />
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="8">
              <a-row>
                <a-col :span="24">
                  <a-checkbox v-model:checked="localTemplate.object.view.default.replaceDiacritics">
                    Fara diacritice
                    <help-icon-popover
                      title="Fara diacritice"
                      content="Caracterele speciale (diacritice), din orice limba, vor fi inlocuite cu literele normale din lb. engleza (din lista generata pt item tip lista si din tokenurile folosite in template before si template after)."
                    />
                  </a-checkbox>
                  <!--                  <div>-->
                  <!--                    <a-checkbox-->
                  <!--                      v-model:checked="-->
                  <!--                        localTemplate.object.view.default.closeAllPopupsOnSave-->
                  <!--                      "-->
                  <!--                    >-->
                  <!--                      Close all pop-ups on Save-->
                  <!--                      <help-icon-popover-->
                  <!--                        title="Close all pop-ups on Save"-->
                  <!--                        content="If it is checked, after saving a pop-up it will close all open pop-ups."-->
                  <!--                      />-->
                  <!--                    </a-checkbox>-->
                  <!--                  </div>-->
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>

        <div style="margin-top: 20px">
          <a-row>
            <a-col :span="24" class="mb-3">
              <a-button @click="tokenModalVisible = true" class="mb-2">Show tokens</a-button>
              <TokensModal
                :blueprint-id="selectedBlueprint._id"
                :visible="tokenModalVisible"
                :ownerDetailsExist="template.object.view.default.convertOwnerIdToOwnerDetails"
                @close="tokenModalVisible = false"
              ></TokensModal>
              <div class="mb-3">
                Html template before<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtml"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '500px' }"
                />
              </div>
              <div>
                Html template after<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtmlAfter"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '400px' }"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Content HTML settings">
        <a-row v-if="template.object.view?.contentHtmlSettings">
          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.idToLabel">
              idToLabel {{ template.object.view.contentHtmlSettings.idToLabel }}
            </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.fieldReferenceData"> fieldReferenceData </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.excludeFieldMultipleReferenceData">
              excludeFieldMultipleReferenceData
            </a-checkbox>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>

    <template #footer>
      <a-button key="submit" type="primary" @click="close"> Ok </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import TokensModal from '@/core/components/TokensModal.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';

export default {
  name: 'ElementSettings',
  props: ['selectedBlueprint', 'template', 'templates', 'blueprints', 'modalSetariElementVisible'],
  emits: ['update:modalSetariElementVisible'],
  components: {
    Codemirror,
    TokensModal,
  },
  data() {
    return {
      modalView: this.modalSetariElementVisible,
      tokenModalVisible: false,
      localTemplate: this.template ?? null,
      extensions: null,
    };
  },
  mounted() {
    this.localTemplate = this.template ?? null;
    const extensions = [javascript(), oneDark];
    this.extensions = extensions;
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:modalSetariElementVisible', false);
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
  computed: {
    templateOptions() {
      const options = [];
      if (this.templates && Array.isArray(this.templates)) {
        this.templates.forEach(template => {
          options.push({
            value: template._id,
            label: template.name + (template.description && template.description !== '' ? ` (${template.description})` : ''),
          });
        });
      }
      return options;
    },
    templateBaseIdOptions() {
      const options = [
        {
          label: 'Self',
          value: null,
        },
      ];

      this.selectedBlueprint.fields.forEach(f => {
        if (f.structure.type === 'reference') {
          options.push({
            label: f.label,
            value: f._id,
          });
        }
      });

      return options;
    },
    blueprintOptions() {
      return this.blueprints.map(bp => {
        return {
          value: bp.id,
          label: bp.name,
        };
      });
    },
  },
};
</script>
