<template>
  <div>
    <a-dropdown-button @click="onClickAccount" style="margin-top: 5px">
      {{ user.firstName }}
      <template #overlay>
        <a-menu style="width: 200px">
          <a-menu-item key="1" @click="onClickAccount">
            <UserOutlined />
            <span>Account</span>
          </a-menu-item>

          <a-menu-divider />
          <router-link :to="{ name: 'TenantsList' }" custom v-slot="{ navigate }">
            <a-menu-item key="2" @click="navigate">
              <AppstoreOutlined />
              <span>Tenants</span>
            </a-menu-item>
          </router-link>

          <router-link :to="{ name: 'UserFeedback' }" custom v-slot="{ navigate }">
            <a-menu-item v-if="user?.isSuperAdmin" key="4" @click="navigate">
              <AppstoreOutlined />
              <span>User Feedback</span>
            </a-menu-item>
          </router-link>

          <a-menu-divider />

          <a-menu-item key="3" @click="onClickLogout">
            <LogoutOutlined />
            Logout
          </a-menu-item>
        </a-menu>
      </template>
      <UserOutlined />
    </a-dropdown-button>
  </div>
</template>

<script>
import { UserActions } from '@userManagement/user.store';
import { UserOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';
import router from '@/router';

export default {
  name: 'AccountComponent',
  components: {
    UserOutlined,
    AppstoreOutlined,
    LogoutOutlined,
  },
  props: ['user', 'appUrl', 'subtenantUrl'],

  data() {
    return {};
  },
  methods: {
    async onClickLogout() {
      await UserActions.logout();
      // window.location.href = '/';
    },
    onClickAccount() {
      const tenantSlug = SlugGetters.getTenantSlug();
      const appSlug = SlugGetters.getAppSlug();
      const subtenantSlug = SlugGetters.getSubtenantSlug();

      router.push({
        name: 'Account',
        params: { tenantSlug, appSlug, subtenantSlug },
      });
    },
  },
};
</script>

<style scoped></style>
